import { getLocalizedString } from "@icarius-localization/strings";
import * as pages from "../pages";
import { roles } from "@icarius-utils/constants";
import {
  Extensibility,
  Settings,
  Publications,
  OrgChartIcon,
  TalentAcquisitionIcon,
  CalculationsManagementIcon,
  GroupsIcon,
  PersonalSheet,
  DocumentIcon,
  CoinsIcon,
  ShieldAlert,
  PersonalIcon,
  RequestsIcon,
  TimeManagementIcon,
  ManagementIcon,
  EngagementIcon,
  PerformanceManagementIcon,
  PencilIcon,
} from "@icarius-icons";
import paths from "@icarius-localization/paths";

//Title: Nombre en el Drawer

const defaultItems = { // son los items que salen en el principio del drawer
  directory: {
    roles: [roles.employer, roles.manager, roles.collaborator],
    string: "directory",
    component: pages.Directory,
    path: paths.directory,
    icon: DocumentIcon(),
  },
  alerts: {
    roles: [roles.employer, roles.manager, roles.collaborator],
    string: "alerts",
    component: pages.Alerts,
    path: paths.alerts,
    icon: ShieldAlert(),
  },
  digitalDocuments: {
    roles: [roles.employer, roles.manager, roles.collaborator],
    string: "digitalDocuments",
    component: pages.Home,
    path: paths.digitalDocuments,
    icon: ShieldAlert(),
  },
  myPersonalData: {
    roles: [roles.employer, roles.manager, roles.collaborator],
    string: "myPersonalData",
    component: pages.MyPersonalData,
    path: paths.myPersonalData,
    icon: PersonalSheet(),
  },
  myGroups: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "myGroupsTitle",
    component: pages.MyGroups,
    path: paths.myGroups,
    icon: GroupsIcon,
  },
}

const groupParents = { // son los desplegables de la segunda seccion del drawer
  settings: {
    isGroup: true,
    roles: [roles.employer],
    string: "settings",
    icon: Settings(),
    path: paths.settings,
  },
  extensibility: {
    isGroup: true,
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "extensibility",
    icon: Extensibility(),
    path: paths.extensibility,
  },
  publications: {
    isGroup: true,
    roles: [roles.employer],
    string: "publications",
    icon: Publications(),
    path: paths.publications,
  },
  positions: {
    isGroup: true,
    roles: [roles.employer, roles.manager, roles.collaborator],
    string: "cargosMenu",
    icon: OrgChartIcon(),
    path: paths.positions,
  },
  talentAcquisition: {
    isGroup: true,
    roles: [roles.employer, roles.collaborator],
    string: "talentAcquisition",
    icon: TalentAcquisitionIcon(),
    path: paths.talentAcquisition,
  },
  personal: {
    isGroup: true,
    roles: [roles.employer, roles.manager, roles.collaborator],
    string: "Personal",
    icon: PersonalIcon(),
    path: paths.personal,
  },
  performanceManagement: {
    isGroup: true,
    roles: [roles.employer, roles.manager, roles.collaborator],
    string: "performanceManagement",
    icon: PerformanceManagementIcon(),
    path: paths.performanceManagement,
  },
  remunerations: {
    isGroup: true,
    roles: [roles.employer, roles.manager, roles.collaborator],
    string: "Remunerations",
    icon: CoinsIcon(),
    path: paths.remunerations,
  },
  requestsMenu: {
    isGroup: true,
    roles: [roles.manager, roles.employer, roles.collaborator],
    string: "requestsTitle",
    path: paths.requests,
    icon: RequestsIcon(),
  },
  digitalSignature: {
    isGroup: true,
    roles: [roles.manager, roles.employer],
    string: "digitalSignature",
    icon: PencilIcon(),
    path: paths.digitalSignature,
  },
  timeManagement: {
    isGroup: true,
    roles: [roles.employer, roles.manager, roles.collaborator],
    string: "timeManagement",
    icon: TimeManagementIcon(),
    path: paths.timeManagement,
  },
  engagement: {
    isGroup: true,
    roles: [roles.employer],
    string: "engagement",
    icon: EngagementIcon(),
    path: paths.engagement,
  },
  management: {
    isGroup: true,
    roles: [roles.employer, roles.manager, roles.collaborator],
    string: "managementNav",
    icon: ManagementIcon(),
    path: paths.management,
  },
  calculationManagement: {
    isGroup: true,
    roles: [roles.employer],
    string: "calculationManagement",
    icon: CalculationsManagementIcon(),
    path: paths.calculationManagement,
  },
}

const groupChildren = { // son los items de los desplegables de la segunda seccion del drawer
  assistanceAnalysis: {
    roles: [roles.manager, roles.employer],
    string: "assistanceAnalysis",
    component: pages.AssistanceAnalysis,
    path: paths.assistanceAnalysis,
  },
  alertsSettingsScreenSelector: {
    roles: [roles.employer],
    string: "alertsSettings",
    component: pages.AlertsSettingsScreenSelector,
    path: paths.alertsSettings,
  },
  alertsTemplates: {
    roles: [roles.employer],
    string: "alertsTemplates",
    component: pages.AlertsTemplates,
    path: paths.alertsTemplates,
  },
  userAlertsSettings: {
    roles: [roles.employer],
    string: "userAlertsSettings",
    component: pages.AlertsSettings,
    path: paths.userAlertsSettings,
  },
  systemAlertsSettings: {
    roles: [roles.employer],
    string: "systemAlertsSettings",
    component: pages.AlertsSettings,
    path: paths.systemAlertsSettings,
  },
  kpiDefinition: {
    roles: [roles.employer],
    string: "kpiDefinition",
    component: pages.KPIdefinition,
    path: paths.kpiDefinition,
  },
  geoQueryDefinition: {
    roles: [roles.employer],
    string: "geoQuery",
    component: pages.GeoQueryDefinition,
    path: paths.geoQuery,
  },
  queryDefinition: {
    roles: [roles.employer],
    string: "queryDefinition",
    component: pages.QueryDefinition,
    path: paths.queryDefinition,
  },
  managementDefinition: {
    roles: [roles.employer],
    string: "managementDefinition",
    component: pages.ManagementDefinition,
    path: paths.managementDefinition,
  },
  transactionLog: {
    roles: [roles.employer],
    string: "transactionLog",
    component: pages.TransactionLog,
    path: paths.transactionLog,
  },
  generalValues: {
    roles: [roles.employer],
    string: "generalValues",
    component: pages.GeneralValues,
    path: paths.generalValues,
  },
  conceptStructure: {
    roles: [roles.employer],
    string: "conceptStructure",
    component: pages.ConceptStructure,
    path: paths.conceptStructure,
  },
  conceptsSet: {
    roles: [roles.employer],
    string: "conceptsSet",
    component: pages.ConceptsSet,
    path: paths.conceptsSet,
  },
  extraHoursAuthorization: {
    roles: [roles.employer, roles.manager],
    string: "extraHoursAuthorization",
    component: pages.ExtraHoursAuthorization,
    path: paths.extraHoursAuthorization,
  },
  extraHoursTable: {
    roles: [roles.employer],
    string: "extraHoursTable",
    component: pages.ExtraHoursTable,
    path: paths.extraHoursTable,
  },
  utils: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "utils",
    component: pages.Utils,
    path: paths.utils,
  },
  holidayTable: {
    roles: [roles.employer],
    string: "holidayTable",
    component: pages.HolidayTable,
    path: paths.holidayTable,
  },
  festivitiesTable: {
    roles: [roles.employer],
    string: "festivitiesTable",
    component: pages.FestivitiesTable,
    path: paths.festivitiesTable,
  },
  benefitsCenter: {
    roles: [roles.employer],
    string: "benefitsCenter",
    component: pages.BenefitsCenter,
    path: paths.benefitsCenter,
  },
  projects: {
    roles: [roles.employer],
    string: "projects",
    component: pages.Projects,
    path: paths.projects,
  },
  conceptGroups: {
    roles: [roles.employer],
    string: "conceptGroups",
    component: pages.ConceptGroups,
    path: paths.conceptGroups,
  },
  positionsClassificationSettings: {
    roles: [roles.employer],
    string: "positionsClassification",
    component: pages.PositionsClassificationSettings,
    path: paths.positionsClassification,
  },
  laborUnions: {
    roles: [roles.employer],
    string: "laborUnions",
    component: pages.LaborUnions,
    path: paths.laborUnions,
  },
  assetsDiscountsEntryByInstallments: {
    roles: [roles.employer],
    string: "assetsDiscountsEntryByInstallments",
    component: pages.AssetsDiscountsEntryByInstallments,
    path: paths.entryInInstallments,
  },
  paymentTypes: {
    roles: [roles.employer],
    string: "paymentTypes",
    component: pages.PaymentTypes,
    path: paths.paymentTypes,
  },
  digitalWarehouseFileTypes: {
    roles: [roles.employer],
    string: "digitalWarehouseFileTypes",
    component: pages.DigitalWarehouseFileTypes,
    path: paths.digitalWarehouseFileTypes,
  },
  ratingsAndGeneralValues: {
    roles: [roles.employer],
    string: "ratingsAndGeneralValues",
    component: pages.RatingsAndGeneralValues,
    path: paths.ratingsAndGeneralValues,
  },
  familyAsignations: {
    roles: [roles.employer],
    string: "familyAsignations",
    component: pages.FamilyAsignations,
    path: paths.familyAsignations,
  },
  ratings: {
    roles: [roles.employer],
    string: "ratings",
    component: pages.Ratings,
    path: paths.ratings,
  },
  taxTable: {
    roles: [roles.employer],
    string: "taxTable",
    component: pages.TaxTable,
    path: paths.taxTable,
  },
  affiliationInstitutionDefinition: {
    roles: [roles.employer],
    string: "affiliationInstitutionDefinition",
    component: pages.AffiliationInstitutionDefinition,
    path: paths.affiliationInstitutionDefinition,
  },
  retirementDefinition: {
    roles: [roles.employer],
    string: "retirementDefinition",
    component: pages.RetirementDefinition,
    path: paths.retirementDefinition,
  },
  healthDefinition: {
    roles: [roles.employer],
    string: "healthDefinition",
    component: pages.HealthDefinition,
    path: paths.healthDefinition,
  },
  digitalWarehouse: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "digitalWarehouse",
    component: pages.DigitalWarehouse,
    path: paths.digitalWarehouse,
  },
  trainingData: {
    roles: [roles.employer],
    string: "trainingData",
    component: pages.TrainingData,
    path: paths.trainingData,
  },
  schooling: {
    roles: [roles.employer],
    string: "schooling",
    component: pages.Schooling,
    path: paths.schooling,
  },
  studies: {
    roles: [roles.employer],
    string: "studies",
    component: pages.Studies,
    path: paths.studies,
  },
  specializations: {
    roles: [roles.employer],
    string: "specializations",
    component: pages.Specializations,
    path: paths.specializations,
  },
  languages: {
    roles: [roles.employer],
    string: "languages",
    component: pages.Languages,
    path: paths.languages,
  },
  accumulatorSettings: {
    roles: [roles.employer],
    string: "accumulatorSettings",
    component: pages.AccumulatorSettings,
    path: paths.accumulatorSettings,
  },
  payrollTypesSettings: {
    roles: [roles.employer],
    string: "payrollTypesSettings",
    component: pages.PayrollTypesSettings,
    path: paths.payrollTypesSettings,
  },
  calculationReferences: {
    roles: [roles.employer],
    string: "calculationReferences",
    component: pages.CalculationReferences,
    path: paths.calculationReferences,
  },
  conceptsFormulasFunctionsScreenSelector: {
    roles: [roles.employer],
    string: "conceptsAndFormulasScreenSelector",
    component: pages.ConceptsFormulasFunctionsScreenSelector,
    path: paths.conceptsAndFormulasScreenSelector,
  },
  conceptsFormulas: {
    roles: [roles.employer],
    string: "conceptsAndFormulas",
    component: pages.ConceptsFormulasFunctions,
    path: paths.conceptsAndFormulas,
  },
  calculationsFunctions: {
    roles: [roles.employer],
    string: "calculationsFunctions",
    component: pages.ConceptsFormulasFunctions,
    path: paths.calculationsFunctions,
  },
  assetsAndDiscountsScreenSelector: {
    roles: [roles.employer],
    string: "assetsAndDiscountsScreenSelector",
    component: pages.AssetsAndDiscountsScreenSelector,
    path: paths.assetsAndDiscountsScreenSelector,
  },
  accountingImputations: {
    roles: [roles.employer],
    string: "accountingImputations",
    component: pages.AccountingImputations,
    path: paths.accountingImputations,
  },
  historicAccumulators: {
    roles: [roles.employer],
    string: "historicAccumulators",
    component: pages.HistoricAccumulators,
    path: paths.historicAccumulators,
  },
  assetsAndDiscountsCreation: {
    roles: [roles.employer],
    string: "assetsAndDiscountsCreation",
    component: pages.AssetsAndDiscountsCreation,
    path: paths.createAssetsDiscounts,
  },
  positionEditor: {
    roles: [roles.employer],
    string: "positionEditor",
    component: pages.PositionEditor,
    path: "/positionEditor",
  },
  orgChart: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "orgChart",
    component: pages.Orgchart,
    path: "/orgChart",
  },
  organizationStructure: {
    roles: [roles.employer],
    string: "organizationStructure",
    component: pages.OrganizationStructure,
    path: "/organizationStructure",
  },
  geographicalStructure: {
    roles: [roles.employer],
    string: "geographicalStructure",
    component: pages.GeographicalStructure,
    path: "/geographicalStructure",
  },
  requestsSettings: {
    roles: [roles.employer],
    string: "requestsSettings",
    component: pages.RequestsSettings,
    path: paths.requestsSettings,
  },
  advancesSettings: {
    roles: [roles.employer],
    string: "advancesSettings",
    component: pages.AdvancesSettings,
    path: paths.advancesSettings,
  },
  permissionsSettings: {
    roles: [roles.employer],
    string: "permissionsSettings",
    component: pages.PermissionsSettings,
    path: paths.permissionsSettings,
  },
  centersSettings: {
    roles: [roles.employer],
    string: "centersSettings",
    component: pages.CentersSettings,
    path: paths.centersSettings,
  },
  familySettings: {
    roles: [roles.employer],
    string: "familySettings",
    component: pages.FamilySettings,
    path: paths.familySettings,
  },
  functionsSettings: {
    roles: [roles.employer],
    string: "functionsSettings",
    component: pages.FunctionsSettings,
    path: paths.functionsSettings,
  },
  contractTypesSettings: {
    roles: [roles.employer],
    string: "contractTypesSettings",
    component: pages.ContractTypesSettings,
    path: paths.contractTypesSettings,
  },
  actions: {
    roles: [roles.employer, roles.collaborator, roles.manager],
    string: "actions",
    component: pages.Actions,
    path: paths.actions,
  },
  entryExitTypesScreenSelector: {
    roles: [roles.employer],
    string: "entryExitTypes",
    component: pages.EntryExitTypesScreenSelector,
    path: paths.entryExitTypes,
  },
  entryTypes: {
    roles: [roles.employer],
    string: "entryTypes",
    component: pages.EntryExitTypes,
    path: paths.entryTypes,
  },
  exitTypes: {
    roles: [roles.employer],
    string: "exitTypes",
    component: pages.EntryExitTypes,
    path: paths.exitTypes,
  },
  thirdPartySettings: {
    roles: [roles.employer],
    string: "thirdPartySettings",
    component: pages.ThirdPartySettings,
    path: paths.thirdPartySettings,
  },
  specialProcessDefinition: {
    roles: [roles.employer],
    string: "specialProcessDefinition",
    component: pages.SpecialProcessDefinition,
    path: paths.specialProcessDefinition,
  },
  contractorSettings: {
    roles: [roles.employer],
    string: "contractorSettings",
    component: pages.ContractorSettings,
    path: paths.contractorSettings,
  },
  thirdPartyClassification: {
    roles: [roles.employer],
    string: "thirdPartyClassification",
    component: pages.ThirdPartyClassification,
    path: paths.thirdPartyClassification,
  },
  thirdParty: {
    roles: [roles.employer],
    string: "thirdParty",
    component: pages.ThirdParty,
    path: paths.thirdParty,
  },
  functionaryTypesSettings: {
    roles: [roles.employer],
    string: "functionaryTypesSettings",
    component: pages.FunctionaryTypesSettings,
    path: paths.functionaryTypesSettings,
  },
  issueTypesSettingsScreenSelector: {
    roles: [roles.employer],
    string: "issueTypesSettings",
    component: pages.IssueTypesSettingsScreenSelector,
    path: paths.issueTypesSettings,
  },
  absenceTypes: {
    roles: [roles.employer],
    string: "absenceTypes",
    component: pages.IssueTypesSettings,
    path: paths.absenceTypes,
  },
  permissionTypes: {
    roles: [roles.employer],
    string: "permissionTypes",
    component: pages.IssueTypesSettings,
    path: paths.permissionTypes,
  },
  licenceTypes: {
    roles: [roles.employer],
    string: "licenceTypes",
    component: pages.IssueTypesSettings,
    path: paths.licenceTypes,
  },
  allergiesExamsDiseasesScreenSelector: {
    roles: [roles.employer],
    string: "allergiesExamsDiseases",
    component: pages.AllergiesExamsDiseasesScreenSelector,
    path: paths.allergiesExamsDiseases,
  },
  allergiesSettings: {
    roles: [roles.employer],
    string: "allergiesSettings",
    component: pages.AllergiesExamsDiseases,
    path: paths.allergiesSettings,
  },
  examsSettings: {
    roles: [roles.employer],
    string: "examsSettings",
    component: pages.AllergiesExamsDiseases,
    path: paths.examsSettings,
  },
  diseasesSettings: {
    roles: [roles.employer],
    string: "diseasesSettings",
    component: pages.AllergiesExamsDiseases,
    path: paths.diseasesSettings,
  },
  organizationalDefinitionsScreenSelector: {
    roles: [roles.employer],
    string: "organizationalDefinitions",
    component: pages.OrganizationalDefinitionsScreenSelector,
    path: paths.organizationalDefinitions,
  },
  categoriesOrganizationalDefinitions: {
    roles: [roles.employer],
    string: "categoriesSettings",
    component: pages.OrganizationalDefinitions,
    path: paths.categoriesSettings,
  },
  specialtiesOrganizationalDefinitions: {
    roles: [roles.employer],
    string: "specialtiesSettings",
    component: pages.OrganizationalDefinitions,
    path: paths.specialtiesSettings,
  },
  locationSettingsBox: {
    roles: [roles.employer],
    string: "locationSettingsBox",
    component: pages.LocationSettingsBox,
    path: paths.locationSettingsBox,
  },
  locationSettings: {
    roles: [roles.employer],
    string: "locationSettings",
    component: pages.LocationSettings,
    path: paths.locationSettings,
  },
  worksAndTasksSettings: {
    roles: [roles.employer],
    string: "worksAndTasksSettings",
    component: pages.WorksAndTasksSettings,
    path: paths.worksAndTasksSettings,
  },
  divisionsSettings: {
    roles: [roles.employer],
    string: "divisionsSettings",
    component: pages.DivisionsSettings,
    path: paths.divisionsSettings,
  },
  geoanalysis: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "geoanalysisTitle",
    component: pages.Geoanalysis,
    path: paths.geoanalysis,
  },
  benefitsDefinitions: {
    roles: [roles.employer],
    string: "benefitsSettingsBox",
    component: pages.BenefitsSettingsBox,
    path: paths.benefitsDefinitions,
  },
  digitalFolderAnalysis: {
    roles: [roles.employer],
    string: "digitalFolderAnalysisTitle",
    component: pages.DigitalFolderAnalysis,
    path: paths.digitalFolderAnalysis,
  },
  currentProcesses: {
    roles: [roles.employer],
    string: "currentProcessesTitle",
    component: pages.CurrentProcesses,
    path: paths.currentProcesses,
  },
  specialProcessExecution: {
    roles: [roles.employer],
    string: "specialProcessExecution",
    component: pages.SpecialProcessExecution,
    path: paths.specialProcessExecution,
  },
  historicalProcesses: {
    roles: [roles.employer],
    string: "historicalProcessesTitle",
    component: pages.HistoricalProcesses,
    path: paths.historicalProcesses,
  },
  rebuildAccumulators: {
    roles: [roles.employer],
    string: "rebuildAccumulators",
    component: pages.RebuildAccumulators,
    path: paths.rebuildAccumulators,
  },
  annotationsAnalysis: {
    roles: [roles.manager, roles.employer],
    string: "annotationsAnalysisTitle",
    component: pages.AnnotationsAnalysis,
    path: paths.annotationsAnalysis,
  },
  documentPublication: {
    roles: [roles.employer],
    string: "documentPublication",
    component: pages.DocumentPublication,
    path: paths.documentPublication,
  },
  documentTemplates: {
    roles: [roles.employer],
    string: "documentTemplates",
    component: pages.DocumentTemplates,
    path: paths.documentTemplates,
  },
  consentTypes: {
    roles: [roles.employer],
    string: "consentTypes",
    component: pages.ConsentTypes,
    path: paths.consentTypes,
  },
  consentPublication: {
    roles: [roles.employer],
    string: "consentPublication",
    component: pages.ConsentPublication,
    path: paths.consentPublication,
  },
  publicationSettings: {
    roles: [roles.employer],
    string: "publicationSettings",
    component: pages.PublicationSettings,
    path: paths.publicationSettings,
  },
  documentsScreenSelector: {
    roles: [roles.employer],
    string: "documentsScreenSelector",
    component: pages.DocumentsScreenSelector,
    path: paths.documentsScreenSelector,
  },
  consentsScreenSelector: {
    roles: [roles.employer],
    string: "consentsScreenSelector",
    component: pages.ConsentsScreenSelector,
    path: paths.consentsScreenSelector,
  },
  pollSettings: {
    roles: [roles.employer],
    string: "pollSettings",
    component: pages.PollSettings,
    path: paths.pollSettings,
  },
  generalSettings: {
    roles: [roles.employer],
    string: "generalSettings",
    component: pages.GeneralSettings,
    path: paths.generalSettings,
  },
  digitalFolderSettings: {
    roles: [roles.employer],
    string: "digitalFolderSettingsTitle",
    component: pages.DigitalFolderSettings,
    path: paths.digitalFolderSettings,
  },
  accessSettings: {
    roles: [roles.employer],
    string: "accessSettingsTitle",
    component: pages.AccessSettings,
    path: paths.accessSettings,
  },
  externalDocuments: {
    roles: [roles.employer],
    string: "externalDocuments",
    component: pages.ExternalDocuments,
    path: paths.externalDocuments,
  },
  signers: {
    roles: [roles.employer],
    string: "DigitalSignatureTitle",
    component: pages.Signers,
    path: paths.signers,
  },
  requestedDocuments: {
    roles: [roles.employer],
    string: "requestedDocuments",
    component: pages.RequestedDocuments,
    path: paths.requestedDocuments,
  },
  signedDocuments: {
    roles: [roles.manager, roles.employer],
    string: "signedDocuments",
    component: pages.SignedDocuments,
    path: paths.signedDocuments,
  },
  requests: {
    roles: [roles.employer],
    string: "requestsControlTitle",
    component: pages.Requests,
    path: paths.requestControl,
  },
  delegations: {
    roles: [roles.manager, roles.employer],
    string: "delegationsTitle",
    component: pages.DelegateAttentions,
    path: paths.delegations,
  },
  myKpiIndicators: {
    roles: [roles.manager, roles.collaborator],
    string: "myKpiIndicators",
    component: pages.KpiIndicators,
    path: paths.kpiIndicators,
  },
  certificate: {
    roles: [roles.employer, roles.manager],
    string: "certificateTitle",
    component: pages.Certificates,
    path: paths.certificates,
  },
  digitalCertificates: {
    roles: [roles.employer, roles.manager],
    string: "digitalCertificates",
    component: pages.Certificates,
    path: paths.digitalCertificates,
  },
  myCertificates: {
    roles: [roles.collaborator],
    string: "myCertificateTitle",
    component: pages.Home,
    path: paths.myCertificates,
  },
  pollScreenSelector: {
    roles: [roles.employer],
    string: "pollScreenSelector",
    component: pages.PollScreenSelector,
    path: paths.pollScreenSelector,
  },
  recognitionScreenSelector: {
    roles: [roles.employer],
    string: "recognitionScreenSelector",
    component: pages.RecognitionScreenSelector,
    path: paths.recognitionScreenSelector,
  },
  recognitionAnalysis: {
    roles: [roles.employer],
    string: "recognitionAnalysis",
    component: pages.RecognitionAnalysis,
    path: paths.recognitionAnalysis,
  },
  nineBoxClassification: {
    roles: [roles.employer],
    string: "nineBoxClassification",
    component: pages.NineBoxClassification,
    path: paths.nineBoxClassification,
  },
  pollReview: {
    roles: [roles.employer],
    string: "pollReview",
    component: pages.PollReview,
    path: paths.pollReview,
  },
  pollComparison: {
    roles: [roles.employer],
    string: "pollComparison",
    component: pages.PollComparison,
    path: paths.pollComparison,
  },
  myVacations: {
    roles: [roles.collaborator],
    string: "myVacationsTitle",
    path: paths.myVacations,
    component: pages.Home,
  },
  vacations: {
    roles: [roles.manager, roles.employer],
    string: "vacationsTitle",
    path: paths.vacations,
    component: pages.Vacations,
  },
  performanceForms: {
    roles: [roles.employer],
    string: "performanceForms",
    path: paths.performanceForms,
    component: pages.PerformanceForms,
  },
  talentClassification: {
    roles: [roles.employer],
    string: "talentClassification",
    path: paths.talentClassification,
    component: pages.NineBoxScreenSelector,
  },
  performanceEvaluations: {
    roles: [roles.employer, roles.collaborator, roles.manager],
    string: "performanceEvaluations",
    path: paths.performanceEvaluations,
    component: pages.PerformanceEvaluations,
  },
  performanceAnalytics: {
    roles: [roles.employer, roles.manager],
    string: "performanceAnalytics",
    path: paths.performanceAnalytics,
    component: pages.PerformanceAnalyticsScreenSelector,
  },
  performanceAnalyticsHistoric: {
    roles: [roles.employer],
    string: "performanceAnalyticsHistoric",
    path: paths.performanceAnalyticsHistoric,
    component: pages.PerformanceAnalyticsHistoric,
  },
  performanceAnalyticsEvaluations: {
    roles: [roles.employer, roles.manager],
    string: "performanceAnalyticsEvaluations",
    path: paths.performanceAnalyticsEvaluations,
    component: pages.PerformanceAnalyticsEvaluations,
  },
  performanceAnalyticsTalent: {
    roles: [roles.employer, roles.manager],
    string: "performanceAnalyticsTalent",
    path: paths.performanceAnalyticsTalent,
    component: pages.PerformanceAnalyticsTalent,
  },
  performanceEvaluationsCalibration: {
    roles: [roles.employer],
    string: "performanceEvaluationsCalibration",
    path: paths.performanceEvaluationsCalibration,
    component: pages.PerformanceEvaluationsCalibration,
  },
  performanceSummaryAndResults: {
    roles: [roles.employer, roles.collaborator, roles.manager],
    string: "performanceSummaryAndResults",
    path: paths.performanceSummaryAndResults,
    component: pages.PerformanceSummaryAndResults,
  },
  onboardingTracking: {
    roles: [roles.employer, roles.collaborator, roles.manager],
    string: "onboardingTracking",
    path: paths.onboardingTracking,
    component: pages.OnboardingTracking,
  },
  myOnboardingTracking: {
    roles: [roles.collaborator],
    string: "myOnboardingTracking",
    path: paths.myOnboardingTracking,
    component: pages.MyOnboardingTracking,
  },
  onboardingProcesses: {
    roles: [roles.employer],
    string: "onboardingProcesses",
    path: paths.onboardingProcesses,
    component: pages.OnboardingProcesses,
  },
  onboardingProcessesAssignment: {
    roles: [roles.employer],
    string: "onboardingProcessesAssignment",
    path: paths.onboardingProcessesAssignment,
    component: pages.OnboardingProcessesAssignment,
  },
  evaluationProcessDefinition: {
    roles: [roles.employer],
    string: "evaluationProcessDefinition",
    path: paths.evaluationProcessDefinition,
    component: pages.EvaluationProcessDefinition,
  },
  evaluationProcessDefinitionAssignment: {
    roles: [roles.employer],
    string: "evaluationProcessDefinitionAssignment",
    component: pages.EvaluationProcessDefinitionAssignment,
    path: paths.evaluationProcessDefinitionAssignment,
  },
  performanceDefinition: {
    roles: [roles.employer],
    string: "performanceDefinition",
    path: paths.performanceDefinition,
    component: pages.PerformanceDefinition,
  },
  loans: {
    roles: [roles.collaborator, roles.employer],
    string: "loansTitle",
    component: pages.Loans,
    path: paths.loans,
  },
  receipts: {
    roles: [roles.manager, roles.employer],
    string: "receiptsTitle",
    component: pages.Receipts,
    path: paths.receipts,
  },
  digitalReceipts: {
    roles: [roles.manager, roles.employer],
    string: "digitalReceipts",
    component: pages.Receipts,
    path: paths.digitalReceipts,
  },
  myReceipts: {
    roles: [roles.collaborator],
    string: "myReceiptsTitle",
    component: pages.Home,
    path: paths.myReceipts,
  },
  myHistoricalReceipts: {
    roles: [roles.collaborator],
    string: "myHistoricalReceiptsTitle",
    component: pages.Home,
    path: paths.myHistoricalReceipts,
    requireAuth: false,
  },
  myRequests: {
    roles: [roles.collaborator],
    string: "myRequests",
    component: pages.MyRequests,
    path: paths.myRequests,
  },
  myRequestsManager: {
    roles: [roles.manager],
    string: "myRequestsManager",
    component: pages.MyRequestsManager,
    path: paths.myRequestsManager,
  },
  totemManagement: {
    roles: [roles.manager, roles.employer],
    string: "totemManagementTitle",
    component: pages.TotemMagement,
    path: paths.totems,
  },
  myAssistance: {
    roles: [roles.collaborator],
    string: "myAssistanceTitle",
    component: pages.MyAssistance,
    path: paths.myAssistance,
  },
  myPeopleSchedule: {
    roles: [roles.manager, roles.employer],
    string: "myPeopleSchedule",
    component: pages.MyPeopleSchedule,
    path: paths.myPeopleSchedule,
  },
  mySchedule: {
    roles: [roles.collaborator],
    string: "mySchedule",
    component: pages.MySchedule,
    path: paths.mySchedule,
  },
  assistance: {
    roles: [roles.manager, roles.employer],
    string: "assistanceTitle",
    component: pages.Assistance,
    path: paths.assistance,
  },
  query: {
    roles: [roles.employer],
    string: "query",
    component: pages.Queries,
    path: paths.queries,
  },
  myPeople: {
    roles: [roles.manager, roles.employer],
    string: "myPeopleTitle",
    component: pages.MyPeople,
    path: paths.myPeople,
  },
  employeeMaster: {
    roles: [roles.employer],
    string: "employeeMaster",
    component: pages.MyPeople,
    path: paths.employeeMaster,
  },
  issueSummary: {
    roles: [roles.manager, roles.employer],
    string: "issueSummary",
    component: pages.IssueSummary,
    path: paths.issueSummary,
  },
  issueLicences: {
    roles: [roles.employer],
    string: "issueLicences",
    component: pages.IssueLicences,
    path: paths.issueLicences,
  },
  issuePermissions: {
    roles: [roles.manager, roles.employer],
    string: "issuePermissions",
    component: pages.IssuePermissions,
    path: paths.issuePermissions,
  },
  issueAbsences: {
    roles: [roles.manager, roles.employer],
    string: "issueAbsences",
    component: pages.IssueAbsences,
    path: paths.issueAbsences,
  },
  issueVacations: {
    roles: [roles.manager, roles.employer],
    string: "issueVacations",
    component: pages.IssueVacations,
    path: paths.issueVacations,
  },
  issueManagementScreenSelector: {
    roles: [roles.manager, roles.employer],
    string: "issueManagementScreenSelector",
    component: pages.IssueManagementScreenSelector,
    path: paths.issueManagementScreenSelector,
  },
  entryPerConcept: {
    roles: [roles.employer],
    string: "entryPerConcept",
    component: pages.AssetsDiscountsEntryPerConcept,
    path: paths.entryPerConcept,
  },
  dataCapture: {
    roles: [roles.employer],
    string: "dataCapture",
    component: pages.AssetsDiscountsEntryByAPI,
    path: paths.dataCapture,
  },
  massEntry: {
    roles: [roles.employer],
    string: "massEntry",
    component: pages.AssetsDiscountsMassiveEntry,
    path: paths.massEntry,
  },
  entryPerSociety: {
    roles: [roles.employer],
    string: "entryPerSociety",
    component: pages.AssetsDiscountsEntryPerSociety,
    path: paths.entryPerSociety,
  },
  myApprovedConsents: {
    roles: [roles.collaborator],
    string: "myApprovedConsents",
    component: pages.MyApprovedConsents,
    path: paths.myApprovedConsents,
  },
  assetsDiscountsSummary: {
    roles: [roles.employer],
    string: "assetsDiscountsSummary",
    component: pages.AssetsDiscountsSummary,
    path: paths.assetsDiscountsSummary,
  },
  attendanceReport: {
    roles: [roles.employer, roles.manager],
    string: "attendanceReport",
    component: pages.AttendanceReport,
    path: paths.attendanceReport,
  },
  entryPerConceptSet: {
    roles: [roles.employer],
    string: "entryPerConceptSet",
    component: pages.AssetsDiscountsEntryPerConceptSet,
    path: paths.entryPerConceptSet,
  },
  processScheduling: {
    roles: [roles.employer],
    string: "processScheduling",
    component: pages.ProcessScheduling,
    path: paths.processScheduling,
  },
  entryByImport: {
    roles: [roles.employer],
    string: "entryByImport",
    component: pages.AssetsDiscountsEntryByImport,
    path: paths.entryByImport,
  },
  kpiIndicators: {
    roles: [roles.employer],
    string: "kpiIndicators",
    component: pages.KpiIndicators,
    path: paths.kpiIndicators,
  },
  typesOfBenefits: {
    roles: [roles.employer],
    string: "typesOfBenefits",
    component: pages.TypesOfBenefits,
    path: paths.typesOfBenefits,
  },
  recognitionPlans: {
    roles: [roles.employer],
    string: "recognitionPlans",
    component: pages.RecognitionPlans,
    path: paths.recognitionPlans,
  },
  recognitionPlansAssignment: {
    roles: [roles.employer],
    string: "recognitionPlansAssignment",
    component: pages.RecognitionPlansAssignment,
    path: paths.recognitionPlansAssignment,
  },
  benefitsForPlans: {
    roles: [roles.employer],
    string: "benefitsForPlans",
    component: pages.BenefitsForPlans,
    path: paths.benefitsForPlans,
  },
  organizationalBenefits: {
    roles: [roles.employer],
    string: "organizationalBenefits",
    component: pages.OrganizationalBenefits,
    path: paths.organizationalBenefits,
  },
  feedbackPurpose: {
    roles: [roles.employer],
    string: "feedbackPurpose",
    component: pages.FeedbackPurpose,
    path: paths.feedbackPurpose,
  },
  groupClassification: {
    roles: [roles.employer],
    string: "groupClassification",
    component: pages.GroupClassification,
    path: paths.groupClassification,
  },
  actionTypes: {
    roles: [roles.employer],
    string: "actionTypes",
    component: pages.ActionTypes,
    path: paths.actionTypes,
  },
  nineBoxSettings: {
    roles: [roles.employer],
    string: "nineBoxSettings",
    component: pages.NineBoxSettings,
    path: paths.nineBoxSettings,
  },
  reasonForPointAwarding: {
    roles: [roles.employer],
    string: "reasonForPointAwarding",
    component: pages.ReasonForPointAwarding,
    path: paths.reasonForPointAwarding,
  },
  myQueries: {
    roles: [roles.collaborator, roles.manager],
    string: "myQueries",
    component: pages.Queries,
    path: paths.myQueries,
  },
  myPending: {
    roles: [roles.manager],
    string: "myPending",
    component: pages.MyPending,
    path: paths.myPending,
  },
  myPendingEmployer: {
    roles: [roles.employer],
    string: "myPendingEmployer",
    component: pages.MyPendingEmployer,
    path: paths.myPendingEmployer,
  },
  timeDefinition: {
    roles: [roles.employer],
    string: "timeDefinition",
    component: pages.TimeDefinition,
    path: paths.timeDefinition,
  },
  turnDefinition: {
    roles: [roles.employer],
    string: "turnDefinition",
    component: pages.TurnDefinition,
    path: paths.turnDefinition,
  },
  schedulesDefinition: {
    roles: [roles.employer],
    string: "schedulesDefinition",
    component: pages.SchedulesDefinition,
    path: paths.schedulesDefinition,
  },
  calendarDefinition: {
    roles: [roles.employer],
    string: "calendarDefinition",
    component: pages.CalendarDefinition,
    path: paths.calendarDefinition,
  },
  schedulesProgramming: {
    roles: [roles.employer],
    string: "schedulesProgramming",
    component: pages.SchedulesProgramming,
    path: paths.schedulesProgramming,
  },
  myAnnexes: {
    roles: [roles.collaborator],
    string: "myAnnexesTitle",
    component: pages.MyAnnexes,
    path: paths.myDigitalFolder,
  },
  myAnnotations: {
    roles: [roles.collaborator],
    string: "myAnnotationsTitle",
    component: pages.MyAnnotations,
    path: paths.myAnnotations,
  },
  myBenefits: {
    roles: [roles.collaborator],
    string: "myBenefits",
    component: pages.MyBenefits,
    path: paths.myBenefits,
  },
  Home: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "mainTitle",
    component: pages.Home,
    path: paths.home,
  },
  notes: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "notes",
    component: pages.Notes,
    path: paths.notes,
  },
  myDesktop: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "myDesktop",
    component: pages.MyDesktop,
    path: paths.myDesktop,
  },
  feed: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "feed",
    component: pages.Feed,
    path: paths.feed,
  },
  feedback: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "feedback",
    component: pages.Feedback,
    path: paths.feedback,
  },
  communicationChannel: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "communicationChannel",
    component: pages.CommunicationChannel,
    path: paths.communicationChannel,
  },
  communicationClassification: {
    roles: [roles.employer],
    string: "communicationClassification",
    component: pages.CommunicationClassification,
    path: paths.communicationClassification,
  },
  requestDocuments: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "requestDocuments",
    component: pages.Home,
    path: paths.requestDocuments,
  },
  extensibilityScreenSelector: {
    roles: [roles.employer],
    string: "extensibilityScreenSelector",
    component: pages.ExtensibilityScreenSelector,
    path: paths.extensibilityScreenSelector,
  },
  userTablesDefinition: {
    roles: [roles.employer],
    string: "userTablesDefinition",
    component: pages.SystemAndUserTablesDefinition,
    path: paths.userTablesDefinition,
  },
  systemTablesDefinition: {
    roles: [roles.employer],
    string: "systemTablesDefinition",
    component: pages.SystemAndUserTablesDefinition,
    path: paths.systemTablesDefinition,
  },
  relatedTablesDefinition: {
    roles: [roles.employer],
    string: "relatedTablesDefinition",
    component: pages.RelatedTablesDefinition,
    path: paths.relatedTablesDefinition,
  },
  userTables: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "userTables",
    component: pages.SystemAndUserTables,
    path: paths.userTables,
  },
  systemTables: {
    roles: [roles.collaborator, roles.manager, roles.employer],
    string: "systemTables",
    component: pages.SystemAndUserTables,
    path: paths.systemTables,
  },
  catalogs: {
    roles: [roles.employer],
    string: "catalogs",
    component: pages.Catalogs,
    path: paths.catalogs,
  },
  userMenus: {
    roles: [roles.employer],
    string: "userMenus",
    component: pages.UserMenus,
    path: paths.userMenus,
  },
  socialBlacklist: {
    roles: [roles.employer],
    string: "socialBlacklist",
    component: pages.SocialBlacklist,
    path: paths.socialBlacklist,
  },
  typesOfObjectives: {
    roles: [roles.employer],
    string: "typesOfObjectives",
    component: pages.TypesOfObjectives,
    path: paths.typesOfObjectives,
  },
  typesOfScore: {
    roles: [roles.employer],
    string: "typesOfScore",
    component: pages.TypesOfScore,
    path: paths.typesOfScore,
  },
  ratingScales: {
    roles: [roles.employer],
    string: "ratingScales",
    component: pages.RatingScales,
    path: paths.ratingScales,
  },
  positionGroupsForEvaluations: {
    roles: [roles.employer],
    string: "positionGroupsForEvaluations",
    component: pages.PositionGroupsForEvaluations,
    path: paths.positionGroupsForEvaluations,
  },
  periodsForObjectives: {
    roles: [roles.employer],
    string: "periodsForObjectives",
    component: pages.PeriodsForObjectives,
    path: paths.periodsForObjectives,
  },
  typesOfSkillsSpecific: {
    roles: [roles.employer],
    string: "typesOfSkillsSpecific",
    component: pages.TypesOfSkillsSpecific,
    path: paths.typesOfSkillsSpecific,
  },
  typesOfSkillsTransversal: {
    roles: [roles.employer],
    string: "typesOfSkillsTransversal",
    component: pages.TypesOfSkillsTransversal,
    path: paths.typesOfSkillsTransversal,
  },
  goalsAndObjectives: {
    roles: [roles.manager, roles.employer],
    string: "goalsAndObjectivesTitle",
    component: pages.GoalsAndObjectives,
    path: paths.goalsAndObjectives,
  },
  myGoalsAndObjectives: {
    roles: [roles.collaborator],
    string: "myGoalsAndObjectivesTitle",
    component: pages.GoalsAndObjectives,
    path: paths.goalsAndObjectives,
  },
  talentAcquisitionSettings: {
    roles: [roles.employer],
    string: "talentAcquisitionSettings",
    component: pages.TalentAcquisitionSettings,
    path: paths.talentAcquisitionSettings,
  },
  onboardingScreenSelector: {
    roles: [roles.collaborator, roles.employer],
    string: "onboardingScreenSelector",
    component: pages.OnboardingScreenSelector,
    path: paths.onboardingScreenSelector,
  },
  classificationApplicantsTypes: {
    roles: [roles.employer],
    string: "classificationApplicantsTypes",
    component: pages.ClassificationApplicantsTypes,
    path: paths.classificationApplicantsTypes,
  },
  typesOfPublicationChannels: {
    roles: [roles.employer],
    string: "typesOfPublicationChannels",
    component: pages.TypesOfPublicationChannels,
    path: paths.typesOfPublicationChannels,
  },
  typesOfPublicationFormats: {
    roles: [roles.employer],
    string: "typesOfPublicationFormats",
    component: pages.TypesOfPublicationFormats,
    path: paths.typesOfPublicationFormats,
  },
  typesOfOnboardingActions: {
    roles: [roles.employer],
    string: "typesOfOnboardingActions",
    component: pages.TypesOfOnboardingActions,
    path: paths.typesOfOnboardingActions,
  },
  onboardingProfiles: {
    roles: [roles.employer],
    string: "onboardingProfiles",
    path: paths.onboardingProfiles,
    component: pages.OnboardingProfiles,
  },
  onboardingMaterials: {
    roles: [roles.employer],
    string: "onboardingMaterials",
    path: paths.onboardingMaterials,
    component: pages.OnboardingMaterials,
  },
}

const allRoutes = { // todas las rutas listadas para el switch de react router
  ...defaultItems,
  ...groupParents,
  ...groupChildren,
};

const navRoutes = [ // todas las rutas de la segunda seccion del drawer, asociando los hijos a los padres
  {
    parent: groupParents.settings,
    children:
      [
        groupChildren.generalSettings,
        groupChildren.accessSettings,
        groupChildren.locationSettingsBox,
        groupChildren.entryExitTypesScreenSelector,
        groupChildren.thirdPartySettings,
        groupChildren.issueTypesSettingsScreenSelector,
        groupChildren.trainingData,
        groupChildren.allergiesExamsDiseasesScreenSelector,
        groupChildren.organizationalDefinitionsScreenSelector,
        groupChildren.affiliationInstitutionDefinition,
        groupChildren.digitalFolderSettings,
        groupChildren.requestsSettings,
        groupChildren.alertsSettingsScreenSelector,
      ]
  },
  {
    parent: groupParents.extensibility,
    children:
      [
        groupChildren.extensibilityScreenSelector,
        groupChildren.userTables,
        groupChildren.systemTables,
      ]
  },
  {
    parent: groupParents.publications,
    children:
      [
        groupChildren.centersSettings,
        groupChildren.publicationSettings,
        groupChildren.documentsScreenSelector,
        groupChildren.consentsScreenSelector,
        groupChildren.socialBlacklist,
      ]
  },
  {
    parent: groupParents.positions,
    children:
      [
        groupChildren.orgChart,
        groupChildren.positionEditor,
        groupChildren.organizationStructure,
      ]
  },
  {
    parent: groupParents.talentAcquisition,
    children:
      [
        groupChildren.talentAcquisitionSettings,
        groupChildren.onboardingScreenSelector,
      ],
  },
  {
    parent: groupParents.personal,
    children:
      [
        groupChildren.myPeople,
        groupChildren.employeeMaster,
        groupChildren.issueManagementScreenSelector,
        groupChildren.myAnnotations,
        groupChildren.myAnnexes,
        groupChildren.myBenefits,
        groupChildren.certificate,
        groupChildren.digitalCertificates,
        groupChildren.myCertificates,
        groupChildren.vacations,
        groupChildren.myVacations,
        groupChildren.loans,
        groupChildren.annotationsAnalysis,
        groupChildren.digitalFolderAnalysis,
        groupChildren.myApprovedConsents,
      ],
  },
  {
    parent: groupParents.requestsMenu,
    children:
      [
        groupChildren.requests,
        groupChildren.myRequests,
        groupChildren.myRequestsManager,
        groupChildren.myPending,
        groupChildren.myPendingEmployer,
        groupChildren.delegations,
        groupChildren.requestDocuments,
        groupChildren.requestedDocuments,
      ],
  },
  {
    parent: groupParents.digitalSignature,
    children:
      [
        groupChildren.signers,
        groupChildren.signedDocuments,
        groupChildren.externalDocuments,
      ],
  },
  {
    parent: groupParents.timeManagement,
    children:
      [
        groupChildren.timeDefinition,
        groupChildren.myPeopleSchedule,
        groupChildren.mySchedule,
        groupChildren.assistance,
        groupChildren.myAssistance,
        groupChildren.attendanceReport,
        groupChildren.extraHoursAuthorization,
        groupChildren.assistanceAnalysis,
      ],
  },
  {
    parent: groupParents.remunerations,
    children:
      [
        groupChildren.conceptsFormulasFunctionsScreenSelector,
        groupChildren.ratingsAndGeneralValues,
        groupChildren.assetsAndDiscountsScreenSelector,
        groupChildren.accountingImputations,
        groupChildren.historicAccumulators,
        groupChildren.receipts,
        groupChildren.digitalReceipts,
        groupChildren.myReceipts,
        groupChildren.myHistoricalReceipts,
      ],
  },
  {
    parent: groupParents.calculationManagement,
    children:
      [
        groupChildren.currentProcesses,
        groupChildren.processScheduling,
        groupChildren.specialProcessExecution,
        groupChildren.historicalProcesses,
        groupChildren.rebuildAccumulators,
      ],
  },
  {
    parent: groupParents.performanceManagement,
    children:
      [
        groupChildren.performanceDefinition,
        groupChildren.goalsAndObjectives,
        groupChildren.myGoalsAndObjectives,
        groupChildren.evaluationProcessDefinition,
        groupChildren.performanceEvaluations,
        groupChildren.performanceSummaryAndResults,
        groupChildren.performanceEvaluationsCalibration,
        groupChildren.talentClassification,
        groupChildren.performanceAnalytics,
      ],
  },
  {
    parent: groupParents.engagement,
    children:
      [
        groupChildren.benefitsDefinitions,
        groupChildren.pollScreenSelector,
        groupChildren.recognitionScreenSelector,
      ],
  },
  {
    parent: groupParents.management,
    children:
      [
        groupChildren.transactionLog,
        groupChildren.managementDefinition,
        groupChildren.query,
        groupChildren.myQueries,
        groupChildren.kpiIndicators,
        groupChildren.myKpiIndicators,
        groupChildren.geoanalysis,
      ],
  },
];

const getRoutesArrayWithTitle = (routeArray) => routeArray.map((route) => ({ ...route, title: getLocalizedString(route.string) }));

const filterByRolAndAvailableMenus = (userRole, routeArray, availableMenus) => {
  return routeArray.filter((route) => {
    if (availableMenus && !availableMenus.includes(route.path.replace("/", ""))) return false;
    return route.roles.includes(userRole);
  });
};

export const getAllRoutes = (userRole, availableMenus) => {
  return getRoutesArrayWithTitle(
    filterByRolAndAvailableMenus(userRole, Object.values(allRoutes), availableMenus)
  );
}

export const getNavRoutes = (userRole, availableMenus) => {
  const filteredNavRoutes = [];

  navRoutes.forEach((group) => {
    const filteredChildren = filterByRolAndAvailableMenus(userRole, group.children, availableMenus);
    if (filteredChildren.length > 0) {
      filteredNavRoutes.push({
        parent: { ...group.parent, title: getLocalizedString(group.parent.string) },
        children: getRoutesArrayWithTitle(filteredChildren),
      })
    }
  })

  return filteredNavRoutes;
};

export const getPageNameFromPath = (path) => {
  const string = Object.values(allRoutes).find(route => route.path === path)?.string;
  if (string) return getLocalizedString(string);
  return '';
}