import { zeroPad } from "@icarius-utils/format";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  isValidDateObject,
  tryGetDateFromString,
  monthToComparableNumber,
} from "./utils";

export const dateClassRule = {
  // devuelve true para el id isFecha que se usa en el excelStyles
  isFecha: function (params) {
    return typeof params.value === "string";
  },
};

// comparators
export const dateComparator = (date1, date2) => {
  let date1Number = monthToComparableNumber(date1);
  let date2Number = monthToComparableNumber(date2);

  if (date1Number === null && date2Number === null) return 0;
  if (date1Number === null) return -1;
  if (date2Number === null) return 1;
  return date1Number - date2Number;
};

export const periodComparator = (date1, date2) => {
  let date1Number = Date.parse(date1) || null
  let date2Number = Date.parse(date2) || null

  if (date1Number === null && date2Number === null) return 0;
  if (date1Number === null) return -1;
  if (date2Number === null) return 1;
  return date1Number - date2Number;
};

// formatters
export const dateFormatter = ({ value }) => {
  if (!value || typeof value !== "string") return null;

  let dateParts = value.split("-");
  let day = Number(dateParts[2]);
  let month = Number(dateParts[1]);
  let year = Number(dateParts[0]);

  //Compruebo que efectivamente tengo dias
  if (!day || !month || !year) return null;
  return zeroPad(day, 2) + "/" + zeroPad(month, 2) + "/" + year;
};

export const periodFormatter = ({ value }) => {
  if (!value || typeof value !== "string") return null;

  let dateParts = value.split("/");
  let month, year;

  if (dateParts[0].length === 4) { // el primer elemento es el año
    year = Number(dateParts[0]);
    month = Number(dateParts[1]);
  } else { // Asumo que el año es el ultimo elemento del array
    year = Number(dateParts[1]);
    month = Number(dateParts[0]);
  }

  if (!month || !year) return null;
  return year + "/" + zeroPad(month, 2);
};

export const timeFormatter = ({ value }) => {
  if (!value || typeof value !== "string") return null;
  if (String(value).length <= 8) return value;

  let newDate = new Date(value);
  newDate.setUTCHours(newDate.getUTCHours() + 24);

  const oldDate = new Date("1900-01-01T00:00:00Z");
  const diffMilliseconds = newDate - oldDate;
  const diffSeconds = diffMilliseconds / 1000;
  const diffMinutes = diffSeconds / 60;
  const hours = Math.floor(diffMinutes / 60);
  const minutes = diffMinutes % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
};

// getters
export const dateValueGetter = (params) => {
  if (typeof params.data === "undefined") return '';

  let value = params.data[params.column.colId];
  if ((value && value.indexOf("/") < 0) || !value) return value;

  let dateParts = value.split("/");
  let day = Number(dateParts[0]);
  let month = Number(dateParts[1]);
  let year = Number(dateParts[2]);

  if (!day || !month || !year) return null;
  return year + "-" + zeroPad(month, 2) + "-" + zeroPad(day, 2);
};

export const timeValueGetter = (params) => {
  if (typeof params.data === "undefined") return '';

  const value = params.data[params.column.colId];
  if (!(String(value).length > 4 && value?.includes(":"))) return '';

  const dateTime = value.split(":");
  const hours = dateTime[0];
  const minutes = dateTime[1];

  if (hours > 23) {
    let dateObject = new Date("1900-01-01T00:00:00Z");
    dateObject.setUTCHours(hours);
    dateObject.setUTCMinutes(minutes);
    dateObject.setUTCHours(dateObject.getUTCHours() - 24);
    return dateObject.toISOString();
  } else {
    let dateObject = new Date();
    dateObject.setUTCHours(hours);
    dateObject.setUTCMinutes(minutes);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
  }
};

export const periodValueGetter = (params) => {
  if (typeof params.data === "undefined") return '';

  let value = params.data[params.column.colId];
  if ((value && value.indexOf("/") < 0) || !value) return value;

  let dateParts = value.split("/");
  let month, year;
  if (dateParts[0].length === 4) {
    // el primer elemento es el año
    year = Number(dateParts[0]);
    month = Number(dateParts[1]);
  } else {
    // El primer elemento del array no tiene 4 caracteres. Asumo que el año es el ultimo elemento del array
    year = Number(dateParts[1]);
    month = Number(dateParts[0]);
  }

  if (!month || !year) return '';
  return year + "/" + zeroPad(month, 2);
};

// filterParams
const getPeriodFromString = (str) => {
  if (str === null) return false;

  // Obtengo la fecha y la intento separar en un array de tres partes, usando / como caracter separador
  let dateParts = str.split("/");

  // En base al array resultante, intento instanciar un Date
  let year;
  let month;
  // Compruebo si el primer elemento del array tiene exactamente 4 caracteres de largo
  if (dateParts[0].length === 4) {
    // Los tiene! Quiere decir que el primer elemento es el año
    month = Number(dateParts[1]) - 1;
    year = Number(dateParts[0]);
  } else {
    // El primer elemento del array no tiene 4 caracteres. Asumo que el año es el ultimo elemento del array
    month = Number(dateParts[0]) - 1;
    year = Number(dateParts[1]);
  }

  return new Date(year, month);
};

export const dateFilterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    let dateAsString = cellValue;
    if (dateAsString === null) return -1;

    let cellDate = new Date(dateAsString);
    if (filterLocalDateAtMidnight.getTime) {
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    } else {
      return 0;
    }
  },
  filterOptions: [
    {
      displayKey: "iguales",
      displayName: getLocalizedString("equal"),
      test: function (filterValue, cellValue) {
        let cellValueDate = tryGetDateFromString(cellValue);
        let filterValueDate = tryGetDateFromString(filterValue);

        if (isValidDateObject(cellValueDate) && isValidDateObject(filterValueDate)) {
          return (
            tryGetDateFromString(cellValue).getTime() ===
            tryGetDateFromString(filterValue).getTime()
          );
        }
        return false;
      },
    },
    {
      displayKey: "desiguales",
      displayName: getLocalizedString("notEqual"),
      test: function (filterValue, cellValue) {
        let cellValueDate = tryGetDateFromString(cellValue);
        let filterValueDate = tryGetDateFromString(filterValue);

        if (isValidDateObject(cellValueDate) && isValidDateObject(filterValueDate)) {
          return (
            tryGetDateFromString(cellValue).getTime() !==
            tryGetDateFromString(filterValue).getTime()
          );
        }
        return false;
      },
    },
    {
      displayKey: "contiene",
      displayName: getLocalizedString("contains"),
      test: function (filterValue, cellValue) {
        return cellValue.includes(filterValue);
      },
    },
    {
      displayKey: "nocontiene",
      displayName: getLocalizedString("notContains"),
      test: function (filterValue, cellValue) {
        return !cellValue.includes(filterValue);
      },
    },
    {
      displayKey: "mayorQue",
      displayName: getLocalizedString("moreOrEqualThan"),
      test: function (filterValue, cellValue) {
        let cellValueDate = tryGetDateFromString(cellValue);
        let filterValueDate = tryGetDateFromString(filterValue);

        if (isValidDateObject(cellValueDate) && isValidDateObject(filterValueDate)) {
          return tryGetDateFromString(cellValue) >= tryGetDateFromString(filterValue);
        }
        return false;
      },
    },
    {
      displayKey: "menorQue",
      displayName: getLocalizedString("lessOrEqualThan"),
      test: function (filterValue, cellValue) {
        let cellValueDate = tryGetDateFromString(cellValue);
        let filterValueDate = tryGetDateFromString(filterValue);

        if (isValidDateObject(cellValueDate) && isValidDateObject(filterValueDate)) {
          return tryGetDateFromString(cellValue) <= tryGetDateFromString(filterValue);
        }
        return false;
      },
    },
    {
      displayKey: "fromYear",
      displayName: getLocalizedString("year"),
      test: function (filterValue, cellValue) {
        let cellValueDate = tryGetDateFromString(cellValue);

        if (isValidDateObject(cellValueDate)) {
          return tryGetDateFromString(cellValue).getFullYear() === Number(filterValue);
        }
        return false;
      },
    },
    {
      displayKey: "fromMonth",
      displayName: getLocalizedString("month"),
      test: function (filterValue, cellValue) {
        let cellValueDate = tryGetDateFromString(cellValue);

        if (isValidDateObject(cellValueDate)) {
          return Number(tryGetDateFromString(cellValue).getMonth()) === Number(filterValue) - 1;
        }
        return false;
      },
    },
  ],
}

export const periodFilterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    let dateAsString = cellValue;
    if (dateAsString === null) return -1;

    let cellDate = new Date(dateAsString);
    if (filterLocalDateAtMidnight.getTime) {
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    } else {
      return 0;
    }
  },
  filterOptions: [
    {
      displayKey: "iguales",
      displayName: getLocalizedString("equal"),
      test: function (filterValue, cellValue) {
        let cellValueDate = getPeriodFromString(cellValue);
        let filterValueDate = getPeriodFromString(filterValue);

        if (isValidDateObject(cellValueDate) && isValidDateObject(filterValueDate)) {
          return (
            getPeriodFromString(cellValue).getTime() ===
            getPeriodFromString(filterValue).getTime()
          );
        }
        return false;
      },
    },
    {
      displayKey: "desiguales",
      displayName: getLocalizedString("notEqual"),
      test: function (filterValue, cellValue) {
        let cellValueDate = getPeriodFromString(cellValue);
        let filterValueDate = getPeriodFromString(filterValue);

        if (isValidDateObject(cellValueDate) && isValidDateObject(filterValueDate)) {
          return (
            getPeriodFromString(cellValue).getTime() !==
            getPeriodFromString(filterValue).getTime()
          );
        }
        return false;
      },
    },
    {
      displayKey: "contiene",
      displayName: getLocalizedString("contains"),
      test: function (filterValue, cellValue) {
        return cellValue.includes(filterValue);
      },
    },
    {
      displayKey: "nocontiene",
      displayName: getLocalizedString("notContains"),
      test: function (filterValue, cellValue) {
        return !cellValue.includes(filterValue);
      },
    },
    {
      displayKey: "mayorQue",
      displayName: getLocalizedString("moreOrEqualThan"),
      test: function (filterValue, cellValue) {
        let cellValueDate = getPeriodFromString(cellValue);
        let filterValueDate = getPeriodFromString(filterValue);

        if (isValidDateObject(cellValueDate) && isValidDateObject(filterValueDate)) {
          return getPeriodFromString(cellValue) >= getPeriodFromString(filterValue);
        }
        return false;
      },
    },
    {
      displayKey: "menorQue",
      displayName: getLocalizedString("lessOrEqualThan"),
      test: function (filterValue, cellValue) {
        let cellValueDate = getPeriodFromString(cellValue);
        let filterValueDate = getPeriodFromString(filterValue);

        if (isValidDateObject(cellValueDate) && isValidDateObject(filterValueDate)) {
          return getPeriodFromString(cellValue) <= getPeriodFromString(filterValue);
        }
        return false;
      },
    },
    {
      displayKey: "fromYear",
      displayName: getLocalizedString("year"),
      test: function (filterValue, cellValue) {
        let cellValueDate = getPeriodFromString(cellValue);

        if (isValidDateObject(cellValueDate)) {
          return getPeriodFromString(cellValue).getFullYear() === Number(filterValue);
        }
        return false;
      },
    },
    {
      displayKey: "fromMonth",
      displayName: getLocalizedString("month"),
      test: function (filterValue, cellValue) {
        let cellValueDate = getPeriodFromString(cellValue);

        if (isValidDateObject(cellValueDate)) {
          return Number(getPeriodFromString(cellValue).getMonth()) === Number(filterValue) - 1;
        }
        return false;
      },
    },
  ],
}