import { useState } from "react";

const useResetStateByKey = () => {

    const [key, setKey] = useState(0);

    const resetState = () => setKey(prev => prev + 1);

    return {
        key,
        resetState,
    }
}

export default useResetStateByKey;
