import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { customFileTypes } from "./constants";

const utf8_to_b64 = (str) => {
    return window.btoa(unescape(encodeURIComponent(str)));
}

const b64_to_utf8 = (str) => {
    return decodeURIComponent(escape(window.atob(str)));
}

export const importIcariusFile = (dispatch, callback) => {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = `.${customFileTypes.icarius}`;
    input.onchange = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsText(file);

        reader.onload = (event) => {
            try {
                const utf8Object = b64_to_utf8(event.target.result);
                const parsedObject = JSON.parse(utf8Object);
                callback(parsedObject);
            } catch (e) {
                dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            }
        }
    }
    input.click();
}

export const exportIcariusFile = (dispatch, data, fileName) => {
    try {
        const encryptedObject = utf8_to_b64(JSON.stringify(data, undefined, 1));

        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encryptedObject);
        element.setAttribute('download', `${fileName}.${customFileTypes.icarius}`);

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    } catch (e) {
        dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
    }
}