export const isKeyPresent = key => key && !!sessionStorage.getItem(key);

export const setKeyValue = (key, value) => {
  if (key && value) sessionStorage.setItem(key, JSON.stringify(value));
};

export const getValueFromKey = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

export const getPropertyFromKey = (key, prop) => {
  try {
    return JSON.parse(sessionStorage.getItem(key))[prop];
  } catch (e) {
    return null;
  }
};

export const deleteKey = (key) => sessionStorage.removeItem(key);
