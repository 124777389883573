import React from "react";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { generalColors } from "@icarius-utils/colors";

const IndicatorRenderer = ({ value }) => {
    if (value === undefined || value === null) return null;
    const number = Number(value);
    if (number === 0) return <DragHandleIcon style={{ fill: generalColors.blue }} />
    if (number > 0) return <ArrowUpwardIcon style={{ fill: generalColors.green }} />
    return <ArrowDownwardIcon style={{ fill: generalColors.red }} />
}

export default IndicatorRenderer;