import React from 'react';
import { Typography } from '@material-ui/core';

const CommunicationChannelStatusRenderer = ({ value }) => {

    return (
        Boolean(value) && typeof value === "string" ?
            <Typography
                noWrap
                style={{
                    fontSize: 12,
                    marginTop: 5,
                    fontWeight: value === "Denuncia" ? 600 : 400,
                    color: value === "Denuncia" ? 'red' : 'var(--mainText)',
                }}
            >
                {value}
            </Typography>
            : <div />
    );
}

export default CommunicationChannelStatusRenderer;