import React from 'react';
import { Typography } from '@material-ui/core';

const PerformanceFormStatusRenderer = ({ value }) => {

    return (
        Boolean(value) ?
            <Typography
                noWrap
                style={{
                    fontSize: 12,
                    marginTop: 5,
                    fontWeight: value === "Pendiente" ? 600 : 400,
                    color: value === "Pendiente" ? 'red' : 'var(--mainText)',
                }}
            >
                {value}
            </Typography>
            : <div />
    );
}

export default PerformanceFormStatusRenderer;