import React from 'react';
import { absenceTypeColors } from "@icarius-utils/colors";

export default class AbsenceRenderer extends React.Component {

    getReactContainerClasses() {
        return ['div-outer-div'];
    }

    getColor = (type) => {
        if (type === "Vacaciones") return absenceTypeColors.vacations;
        if (type === "Licencias") return absenceTypeColors.licence;
        if (type === "Permisos") return absenceTypeColors.permission;
        if (type === "Ausencias") return absenceTypeColors.absence;
        return "";
    }

    render() {
        const backgroundColor = this.getColor(this.props.value);

        return (
            <div
                className="div-percent-bar"
                style={{
                    width: "100%",
                    backgroundColor: backgroundColor,
                    borderRadius: 5,
                }}
            >
                <div className="div-percent-value">
                    {this.props.value}
                </div>
            </div>
        );
    }
}
