import React from 'react';
import { Typography } from '@material-ui/core';
import { getNPSColor } from '@icarius-utils/colors';

const NPSRenderer = ({ value }) => {

    return (
        <Typography
            noWrap
            style={{
                fontSize: 12,
                marginTop: 5,
                fontWeight: 600,
                color: getNPSColor(value),
            }}
        >
            {value}
        </Typography>
    );
}

export default NPSRenderer;