import moment from "moment";
import "moment/locale/es";

export const getCurrentFullDate = timezone => {
  return new Date().toLocaleDateString("es-ES", {
    timeZone: timezone,
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const formatDate = (date) => moment(date).format("DD/MM/YYYY");
export const formatTime = (date) => moment(date).format("HH:mm:ss");
export const formatDateHHMM = (date) => moment(date).format("HH:mm");
export const formatDateYYYYMM = (date) => moment(date).format("YYYY/MM");
export const formatDateYYYY = (date) => moment(date).format("YYYY");
export const formatDateYYYYMMDD = (date) => moment(date).format("YYYY/MM/DD");
export const formatDateDDMM = (date) => moment(date).format("DD/MM");
export const formatDateYYYYMMDDHyphen = (date) => moment(date).format("YYYY-MM-DD");

export const createDateFromDDMMYYYY = (value) => {
  const day = value.substring(0, 2);
  const month = value.substring(3, 5);
  const year = value.substring(6);
  return new Date(year, month - 1, day);
};

export const createDateFromYYYYMMDD = (value) => {
  const day = value.substring(8);
  const month = value.substring(5, 7);
  const year = value.substring(0, 4);
  return new Date(year, month - 1, day);
};

export const createDateFromDDMM = (value) => {
  const day = value.substring(0, 2);
  const month = value.substring(3, 5);
  const year = 2000;
  return new Date(year, month - 1, day);
};

export const createDateFromYYYYMMDDHyphen = (value) => {
  const day = value.substring(8, 10);
  const month = value.substring(5, 7);
  const year = value.substring(0, 4);
  return new Date(year, month - 1, day);
};

export const createDateFromYYYYMM = (value) => {
  const year = value.substring(0, 4);
  const month = value.substring(5, 7);
  return new Date(year, month - 1, 1);
};

export const createDateFromMMYYYY = (value) => {
  const month = value.substring(0, 2);
  const year = value.substring(3);

  return new Date(year, month - 1, 1);
};

export const createDateTimeFromDDMMYYYY = (date, time) => {
  const day = date.substring(0, 2);
  const month = date.substring(3, 5);
  const year = date.substring(6);
  const hour = time.substring(0, 2);
  const minutes = time.substring(3, 5);

  return new Date(year, month - 1, day, hour, minutes, 0);
};

export const createDateFromHHMM = (time) => {
  const hour = time.substring(0, 2);
  const minutes = time.substring(3, 5);

  const auxDate = new Date();
  auxDate.setHours(hour);
  auxDate.setMinutes(minutes);
  auxDate.setSeconds(0);
  return auxDate;
};

export const getHoursMinutesBetweenDates = (dateA, dateB) => {
  // devuelve un objeto Date que tiene como horas y minutos la resta entre dateA y dateB, el dia, mes y año son de hoy
  // para tenerlo en formato HH:mm usar la funcion formatDateHHMM
  // los param deben ser fechas validas sino revienta

  const newDateAux = new Date();
  const newHours = Math.abs(dateA.getHours() - dateB.getHours());
  const newMinutes = Math.abs(dateA.getMinutes() - dateB.getMinutes());

  newDateAux.setHours(newHours);
  newDateAux.setMinutes(newMinutes);
  newDateAux.setSeconds(0);

  return newDateAux;
}

export const getDateIsValid = (value) => {
  return value instanceof Date && !isNaN(value);
}

export const getTomorrow = () => {
  return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
};

export const getNextDay = (date) => {
  return new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate() + 1);
};

export const dateIsAfterToday = (date) => {
  const hoy = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  return date > hoy;
};

export const dateIsBeforeToday = (date) => {
  const hoy = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  return date < hoy;
};

export const getFormattedDateTime = (data) => {
  const dateString = data.date + " " + data.time + ":00";
  const regex = /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/;
  const [, day, month, year, hours, minutes, seconds] = regex.exec(dateString)

  return new Date(year, month - 1, day, hours, minutes, seconds);
}

export const convertDateToUTC = (date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}

export const convertTimeStringToDate = (timeString) => {
  const hours = parseInt(timeString.substring(0, 2));
  const minutes = parseInt(timeString.substring(3));

  const auxDate = new Date();
  auxDate.setHours(hours);
  auxDate.setMinutes(minutes);
  auxDate.setSeconds(0);
  auxDate.setMilliseconds(0);

  return auxDate;
}

export const convertUTCDateToLocalDate = (date) => {
  let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  let offset = date.getTimezoneOffset() / 60;
  let hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

// espera date DD/MM/AAAA y time HH:MM
export const getLocalTimeFromUTCStringDateAndStringTime = (date, time) => {
  const formattedDate = createDateFromDDMMYYYY(date);
  const formattedTime = convertTimeStringToDate(time)

  const utcDate = new Date(
    formattedDate.getFullYear(), formattedDate.getMonth(), formattedDate.getDate(),
    formattedTime.getHours(), formattedTime.getMinutes()
  )

  return convertUTCDateToLocalDate(utcDate);
}

export const timeToDecimal = (t) => {
  if (t) {
    let arr = t.split(':');
    let dec = parseInt((arr[1] / 6) * 10, 10);

    return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
  }

  return 0;
}

// Formato HHMM
export const decimalToTime = (decimalTimeString) => {
  let n = new Date(0, 0);
  n.setMinutes(+decimalTimeString * 60);
  return n.toTimeString().slice(0, 5);
}

export const ARRAY_HOURS = [
  {
    key: '00:00',
    value: '00:00'
  },
  {
    key: '01:00',
    value: '01:00'
  },
  {
    key: '02:00',
    value: '02:00'
  },
  {
    key: '03:00',
    value: '03:00'
  },
  {
    key: '04:00',
    value: '04:00'
  },
  {
    key: '05:00',
    value: '05:00'
  },
  {
    key: '06:00',
    value: '06:00'
  },
  {
    key: '07:00',
    value: '07:00'
  },
  {
    key: '08:00',
    value: '08:00'
  },
  {
    key: '09:00',
    value: '09:00'
  },
  {
    key: '10:00',
    value: '10:00'
  },
  {
    key: '11:00',
    value: '11:00'
  },
  {
    key: '12:00',
    value: '12:00'
  },
  {
    key: '13:00',
    value: '13:00'
  },
  {
    key: '14:00',
    value: '14:00'
  },
  {
    key: '15:00',
    value: '15:00'
  },
  {
    key: '16:00',
    value: '16:00'
  },
  {
    key: '17:00',
    value: '17:00'
  },
  {
    key: '18:00',
    value: '18:00'
  },
  {
    key: '19:00',
    value: '19:00'
  },
  {
    key: '20:00',
    value: '20:00'
  },
  {
    key: '21:00',
    value: '21:00'
  },
  {
    key: '22:00',
    value: '22:00'
  },
  {
    key: '23:00',
    value: '23:00'
  },
];