
import { useEffect, useState } from "react";

const useGetScreenWidth = () => {

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    }
  }, [])

  return width;
}

export default useGetScreenWidth;