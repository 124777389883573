import { likeTypes } from "@icarius-utils/constants";

export const getOpinion = (employee) => {
    return ({
        Likes: employee["LIKES"] || 0,
        Unlikes: employee["UNLIKES"] || 0,
        Opinion: employee["OPINION"] === "-" ? likeTypes.unset : employee["OPINION"],
    });
}

export const likeEmployee = (employees, id, likeValue, fieldToMatch) => {
    // actualiza la OPINION del empleado y devuelve el array de empleados actualizado

    const auxEmployees = [...employees];
    let auxEmployee = auxEmployees.find((value) => value[fieldToMatch] === id);

    if (!auxEmployee) return;

    let opinion = auxEmployee.OPINION;
    const likes = parseInt(opinion.Likes, 10);
    const dislikes = parseInt(opinion.Unlikes, 10);

    switch (opinion.Opinion) {
        case likeTypes.like: {
            switch (likeValue) {
                case likeTypes.like:
                    opinion.Likes = likes - 1;
                    opinion.Opinion = likeTypes.unset;
                    break;
                case likeTypes.unlike:
                    opinion.Likes = likes - 1;
                    opinion.Unlikes = dislikes + 1;
                    opinion.Opinion = likeTypes.unlike;
                    break;
                default: break;
            }
            break;
        }

        case likeTypes.unlike: {
            switch (likeValue) {
                case likeTypes.like:
                    opinion.Likes = likes + 1;
                    opinion.Unlikes = dislikes - 1;
                    opinion.Opinion = likeTypes.like;
                    break;
                case likeTypes.unlike:
                    opinion.Unlikes = dislikes - 1;
                    opinion.Opinion = likeTypes.unset;
                    break;
                default: break;
            }
            break;
        }

        case likeTypes.unset: {
            switch (likeValue) {
                case likeTypes.like:
                    opinion.Likes = likes + 1;
                    opinion.Opinion = likeTypes.like;
                    break;
                case likeTypes.unlike:
                    opinion.Unlikes = dislikes + 1;
                    opinion.Opinion = likeTypes.unlike;
                    break;
                default: break;
            }
            break;
        }

        default: break;
    }

    return {
        opinion,
        updatedEmployees: [...auxEmployees],
    };
};