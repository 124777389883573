import React from 'react';
import { getTheme } from "@icarius-pages/login/selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { formatNumberExactDecimals } from '@icarius-utils/format';

class ProgressByRankingRendererDynamicColor extends React.Component {

    getReactContainerClasses() {
        return ['div-outer-div'];
    }

    render() {
        let backgroundColor = this.props.node?.data?.color || "";
        const percentage = (this.props.node?.data?.Ranking || this.props.node?.data?.ranking) * 100;

        if (!this.props.value) return <div />

        return (
            <div className="div-percent-bar" style={{ width: percentage + '%', backgroundColor: backgroundColor }}>
                <div className="div-percent-value">{formatNumberExactDecimals(this.props.value, 1)}</div>
            </div>
        );
    }
}

export default connect(
    createStructuredSelector({
        theme: getTheme,
    })
)(ProgressByRankingRendererDynamicColor);