export const previewFromBlob = (blobData, type) => {
  let blob = new Blob([blobData], { type: "application/" + type });
  let dlAnchorElem = document.getElementById("downloadAnchorElem");
  dlAnchorElem.setAttribute("href", URL.createObjectURL(blob));
  dlAnchorElem.setAttribute("target", "_blank");
  dlAnchorElem.click();
}

export const downloadBlob = (blobData, type, downloadName) => {
  let blob = new Blob([blobData], { type: "application/" + type });
  let dlAnchorElem = document.getElementById("downloadAnchorElem");
  dlAnchorElem.setAttribute("href", URL.createObjectURL(blob));
  dlAnchorElem.setAttribute("download", downloadName + "." + type);
  dlAnchorElem.setAttribute("target", "_blank");
  dlAnchorElem.click();
};

export const previewFromURL = (url) => {
  window.open(url);
};

export const downloadFromURL = (url, filename) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
}