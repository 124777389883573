import React from 'react';
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import { IconButton } from '@material-ui/core';
import { GetAppIcon } from '@icarius-icons/index';
import { downloadFromURL } from '@icarius-utils/download';

const FileRenderer = ({ value, column }) => {
    return (
        Boolean(value) ?
            <div
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => downloadFromURL(RESOURCES_ENDPOINT + value, column.colId)}
            >
                <IconButton style={{ height: 24 }}>
                    <GetAppIcon />
                </IconButton>
            </div>
            : <div />
    );
}

export default FileRenderer;