export const makeFile = (file, callback) => {
	try {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			callback(reader.result, reader.result.substr(reader.result.indexOf(",") + 1));
		};
	} catch (e) {

	}
};

export const getFileExtension = (fileName) => fileName.substr(fileName.lastIndexOf(".") + 1);
export const getFileName = (fileName) => fileName.substr(0, fileName.lastIndexOf("."));
export const getFileSizeString = (fileSize) => {
	if (!fileSize) return '';

	const sizeNames = ['Bytes', 'KB', 'MB', 'GB'];
	let auxSize = fileSize;
	let i = 0;

	while (auxSize > 900) {
		auxSize /= 1024;
		i++;
	}

	return `${(Math.round(auxSize * 100) / 100)} ${sizeNames[i]}`;
}
