import React from 'react';
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import { previewFromURL } from "@icarius-utils/download";

const ImageRenderer = ({ value, column }) => {

    return (
        Boolean(value) ?
            <div
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => previewFromURL(RESOURCES_ENDPOINT + value)}
            >
                <img
                    style={{ height: 24 }}
                    src={RESOURCES_ENDPOINT + value}
                    alt={column.colId}
                />
            </div>
            : <div />
    );
}

export default ImageRenderer;