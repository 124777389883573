import React from 'react';
import { meritoDemerito } from "@icarius-utils/colors";

export default class TypeRenderer extends React.Component {

    getReactContainerClasses() {
        return ['div-outer-div'];
    }

    render() {
        let backgroundColor;

        const type = this.props.value;

        if (type === "Mérito") {
            backgroundColor = meritoDemerito.fills[0];
        } else if (type === "Demérito") {
            backgroundColor = meritoDemerito.fills[1];
        } else {
            backgroundColor = "";
        }

        return (
            <div
                className="div-percent-bar"
                style={{
                    width: "100%",
                    backgroundColor: backgroundColor,
                    borderRadius: 5,
                }}
            >
                <div className="div-percent-value">{this.props.value}</div>
            </div>
        );
    }
}
