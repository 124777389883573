import React from 'react';
import { getAppColor } from "src/app/selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";
class HolidaysDateRenderer extends React.Component {

    getReactContainerClasses() {
        return ['div-outer-div'];
    }

    render() {
        let isGreaterThanToday = false;

        if (this.props.value && moment(this.props.value).utc().isAfter(moment().utc())) {
            isGreaterThanToday = true;
        }

        return (
            this.props.value ?
                <div style={isGreaterThanToday ? { color: `${this.props.color}` } : {}}>
                    <div style={isGreaterThanToday ? { color: `${this.props.color}` } : {}}>
                        {`${moment(this.props.value).format("DD/MM/YYYY")}`}
                    </div>
                </div>
                :
                <div></div>
        );
    }
}

export default connect(
    createStructuredSelector({
        color: getAppColor,
    })
)(HolidaysDateRenderer);