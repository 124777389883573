import { combineReducers } from "redux";
import appReducer, { appReducerName } from "../app/index";
import homeReducer, { homeReducerName } from "@icarius-pages/home";
import loginReducer, { loginReducerName } from "@icarius-pages/login";
import dialogReducer, { dialogReducerName } from "@icarius-common/dialog";
import pollReducer, { pollReducerName } from "@icarius-common/poll";
import directoryReducer, { directoryReducerName } from "@icarius-pages/directory";
import alertReducer, { alertsReducerName } from "@icarius-pages/alerts";
import certificatesReducer, { certificatesReducerName } from "@icarius-pages/certificates";
import myPeopleReducer, { myPeopleReducerName } from "@icarius-pages/myPeople";
import queriesReducer, { queriesReducerName } from "@icarius-pages/queries";
import pollReviewReducer, { pollReviewReducerName } from "@icarius-pages/pollReview";
import assistanceReducer, { assistanceReducerName } from "@icarius-pages/assistance";
import myAssistanceReducer, { myAssistanceReducerName } from "@icarius-pages/myAssistance";
import kpiIndicatorsReducer, { kpiIndicatorsReducerName } from "@icarius-pages/kpiIndicators";
import receiptsReducer, { receiptsReducerName } from "@icarius-pages/receipts";
import loansReducer, { loansReducerName } from "@icarius-pages/loans";
import myAnnotationsReducer, { myAnnotationsReducerName } from "@icarius-pages/myAnnotations";
import myRequestsReducer, { myRequestsReducerName } from "@icarius-pages/myRequests";
import myRequestsManagerReducer, { myRequestsManagerReducerName } from "@icarius-pages/myRequestsManager";
import myPendingReducer, { myPendingReducerName } from "@icarius-pages/myPending";
import myPendingEmployerReducer, { myPendingEmployerReducerName } from "@icarius-pages/myPendingEmployer";
import requestsReducer, { requestsReducerName } from "@icarius-pages/requests";
import delegateAttentionsReducer, { delegateAttentionsReducerName } from "@icarius-pages/delegations";
import snackbarReducer, { snackbarReducerName } from "@icarius-common/snackbar";
import circularProgressReducer, { circularProgressReducerName } from "@icarius-common/circularProgress";
import personalDataReducer, { personalDataReducerName } from "@icarius-pages/myPersonalData";
import totemManagementReducer, { totemManagementReducerName } from "@icarius-pages/totemManagement";
import assistanceTotemReducer, { assistanceTotemReducerName } from "@icarius-pages/assistanceTotem";
import fiscalizationReducer, { fiscalizationReducerName } from "@icarius-pages/fiscalization";
import nineBoxSettingsReducer, { nineBoxSettingsReducerName } from "@icarius-pages/nineBoxSettings";
import vacationsReducer, { vacationsReducerName } from "@icarius-pages/vacations";
import signersReducer, { signersReducerName } from "@icarius-pages/signers";
import requestedDocumentsReducer, { requestedDocumentsReducerName } from "@icarius-pages/requestedDocuments";
import classificationApplicantsTypesReducer, { classificationApplicantsTypesReducerName } from "@icarius-pages/classificationApplicantsTypes";
import typesOfPublicationChannelsReducer, { typesOfPublicationChannelsReducerName } from "@icarius-pages/typesOfPublicationChannels";
import typesOfPublicationFormatsReducer, { typesOfPublicationFormatsReducerName } from "@icarius-pages/typesOfPublicationFormats";
import typesOfOnboardingActionsReducer, { typesOfOnboardingActionsReducerName } from "@icarius-pages/typesOfOnboardingActions";
import signedDocumentsReducer, { signedDocumentsReducerName } from "@icarius-pages/signedDocuments";
import myGroupsReducer, { myGroupsReducerName } from "@icarius-pages/groups";
import actionsReducer, { actionsReducerName } from "@icarius-pages/actions";
import generalSettingsReducer, { generalSettingsReducerName } from "@icarius-pages/generalSettings";
import digitalFolderSettingsReducer, { digitalFolderSettingsReducerName } from "@icarius-pages/digitalFolderSettings";
import galleryDialogReducer, { galleryDialogReducerName } from "@icarius-common/galleryDialog";
import externalDocumentsReducer, { externalDocumentsReducerName } from "@icarius-pages/externalDocuments";
import myDocumentsReducer, { myDocumentsReducerName } from "@icarius-common/myCertificatesAndReceiptsDialog";
import myDigitalDocumentsReducer, { myDigitalDocumentsReducerName } from "@icarius-common/myDigitalDocumentsDialog";
import pollSettingsReducer, { pollSettingsReducerName } from "@icarius-pages/pollSettings";
import accessSettingsReducer, { accessSettingsReducerName } from "@icarius-pages/accessSettings";
import annotationsAnalysisReducer, { annotationsAnalysisReducerName } from "@icarius-pages/annotationsAnalysis";
import historicalProcessesReducer, { historicalProcessesReducerName } from "@icarius-pages/historicalProcesses";
import currentProcessesReducer, { currentProcessesReducerName } from "@icarius-pages/currentProcesses";
import digitalFolderAnalysisReducer, { digitalFolderAnalysisReducerName } from "@icarius-pages/digitalFolderAnalysis";
import employeesProcessesReducer, { employeesProcessesReducerName } from "@icarius-pages/employeesProcesses";
import geoanalysisReducer, { geoanalysisReducerName } from "@icarius-pages/geoanalysis";
import publicationSettingsReducer, { publicationSettingsReducerName } from "@icarius-pages/publicationSettings";
import filtersDialogReducer, { filtersDialogReducerName } from "@icarius-common/filtersDialog";
import positionEditorReducer, { positionEditorReducerName } from "@icarius-pages/positionEditor";
import centersSettingsReducer, { centersSettingsReducerName } from "@icarius-pages/centersSettings";
import permissionsSettingsReducer, { permissionsSettingsReducerName } from "@icarius-pages/permissionsSettings";
import familySettingsReducer, { familySettingsReducerName } from "@icarius-pages/familySettings";
import functionsSettingsReducer, { functionsSettingsReducerName } from "@icarius-pages/functionsSettings";
import advancesSettingsReducer, { advancesSettingsReducerName } from "@icarius-pages/advancesSettings";
import documentPublicationReducer, { documentPublicationReducerName } from "@icarius-pages/documentPublication";
import contractTypesSettingsReducer, { contractTypesSettingsReducerName } from "@icarius-pages/contractTypesSettings";
import audioPlayerReducer, { audioPlayerReducerName } from "@icarius-common/audioPlayer";
import conceptGroupsReducer, { conceptGroupsReducerName } from "@icarius-pages/conceptGroups";
import contractorSettingsReducer, { contractorSettingsReducerName } from "@icarius-pages/contractorSettings";
import functionaryTypesSettingsReducer, { functionaryTypesSettingsReducerName } from "@icarius-pages/functionaryTypesSettings";
import locationSettingsReducer, { locationSettingsReducerName } from "@icarius-pages/locationSettings";
import worksAndTasksSettingsReducer, { worksAndTasksSettingsReducerName } from "@icarius-pages/worksAndTasksSettings";
import divisionsSettingsReducer, { divisionsSettingsReducerName } from "@icarius-pages/divisionsSettings";
import conceptsFormulasFunctionsReducer, { conceptsFormulasFunctionsReducerName } from "@icarius-pages/conceptsFormulasFunctions";
import accumulatorSettingsReducer, { accumulatorSettingsReducerName } from "@icarius-pages/accumulatorSettings";
import categoriesAndSpecialtiesReducer, { categoriesAndSpecialtiesReducerName } from "@icarius-pages/organizationalDefinitions";
import payrollTypesSettingsReducer, { payrollTypesSettingsReducerName } from "@icarius-pages/payrollTypesSettings";
import allergiesExamsDiseasesReducer, { allergiesExamsDiseasesReducerName } from "@icarius-pages/allergiesExamsDiseases";
import calculationReferencesReducer, { calculationReferencesReducerName } from "@icarius-pages/calculationReferences";
import entryExitTypesReducer, { entryExitTypesReducerName } from "@icarius-pages/entryExitTypes";
import annotationsPageReducer, { annotationsPageReducerName } from "@icarius-pages/annotationsPage";
import annexesPageReducer, { annexesPageReducerName } from "@icarius-pages/annexesPage";
import organizationStructureReducer, { organizationStructureReducerName } from "@icarius-pages/organizationStructure";
import orgchartReducer, { orgChartReducerName } from "@icarius-pages/orgchart";
import geographicalStructureReducer, { geographicalStructureReducerName } from "@icarius-pages/geographicalStructure";
import issueTypesSettingsReducer, { issueTypesSettingsReducerName } from "@icarius-pages/issueTypesSettings";
import positionsClassificationSettingsReducer, { positionsClassificationSettingsReducerName } from "@icarius-pages/positionsClassificationSettings";
import queryDefinitionReducer, { queryDefinitionReducerName } from "@icarius-pages/queryDefinition";
import queryDefinitionParametersReducer, { queryDefinitionParametersReducerName } from "@icarius-pages/queryDefinitionParameters";
import geoQueryDefinitionReducer, { geoQueryDefinitionReducerName } from "@icarius-pages/geoQuery";
import geoQueryDefinitionParametersReducer, { geoQueryDefinitionParametersReducerName } from "@icarius-pages/geoQueryParameters";
import generalValuesReducer, { generalValuesReducerName } from "@icarius-pages/generalValues";
import holidayTableReducer, { holidayTableReducerName } from "@icarius-pages/holidayTable";
import festivitiesTableReducer, { festivitiesTableReducerName } from "@icarius-pages/festivitiesTable";
import kpiDefinitionReducer, { kpiDefinitionReducerName } from "@icarius-pages/kpiDefinition";
import typesOfBenefitsReducer, { typesOfBenefitsReducerName } from "@icarius-pages/typesOfBenefits";
import typesOfScoreReducer, { typesOfScoreReducerName } from "@icarius-pages/typesOfScore";
import benefitsForPlansReducer, { benefitsForPlansReducerName } from "@icarius-pages/benefitsForPlans";
import notesReducer, { notesReducerName } from "@icarius-pages/notes";
import alertsSettingsReducer, { alertsSettingsReducerName } from "@icarius-pages/alertsSettings";
import conceptStructureReducer, { conceptStructureReducerName } from "@icarius-pages/conceptStructure";
import conceptsSetSettingsReducer, { conceptsSetSettingsReducerName } from "@icarius-pages/conceptsSet";
import thirdPartyClassificationReducer, { thirdPartyClassificationReducerName } from "@icarius-pages/thirdPartyClassification";
import thirdPartyReducer, { thirdPartyReducerName } from "@icarius-pages/thirdParty";
import laborUnionsReducer, { laborUnionsReducerName } from "@icarius-pages/laborUnions";
import paymentTypesReducer, { paymentTypesReducerName } from "@icarius-pages/paymentTypes";
import documentTemplatesReducer, { documentTemplatesReducerName } from "@icarius-pages/documentTemplates";
import assetsDiscountsCreationReducer, { assetsDiscountsCreationReducerName } from "@icarius-pages/assetsAndDiscountsCreation";
import assetsDiscountsScreenSelectorReducer, { assetsDiscountsScreenSelectorReducerName } from "@icarius-pages/assetsAndDiscountsScreenSelector";
import digitalWarehouseFileTypesReducer, { digitalWarehouseFileTypesReducerName } from "@icarius-pages/digitalWarehouseFileTypes";
import schoolingReducer, { schoolingReducerName } from "@icarius-pages/schooling";
import studiesReducer, { studiesReducerName } from "@icarius-pages/studies";
import specializationsReducer, { specializationsReducerName } from "@icarius-pages/specializations";
import languagesReducer, { languagesReducerName } from "@icarius-pages/languages";
import retirementDefinitionReducer, { retirementDefinitionReducerName } from "@icarius-pages/retirementDefinition";
import healthDefinitionReducer, { healthDefinitionReducerName } from "@icarius-pages/healthDefinition";
import assetsDiscountsEntryPerConceptReducer, { assetsDiscountsEntryPerConceptReducerName } from "@icarius-pages/assetsDiscountsEntryPerConcept";
import assetsDiscountsMassiveEntryReducer, { assetsDiscountsMassiveEntryReducerName } from "@icarius-pages/assetsDiscountsMassiveEntry";
import assetsDiscountsEntryPerSocietyReducer, { assetsDiscountsEntryPerSocietyReducerName } from "@icarius-pages/assetsDiscountsEntryPerSociety";
import digitalWarehouseReducer, { digitalWarehouseReducerName } from "@icarius-pages/digitalWarehouse";
import familyAsignationsReducer, { familyAsignationsReducerName } from "@icarius-pages/familyAsignations";
import ratingsReducer, { ratingsReducerName } from "@icarius-pages/ratings";
import taxTableReducer, { taxTableReducerName } from "@icarius-pages/taxTable";
import assetsDiscountsSummaryReducer, { assetsDiscountsSummaryReducerName } from "@icarius-pages/assetsDiscountsSummary";
import attendanceReportReducer, { attendanceReportReducerName } from "@icarius-pages/attendanceReport";
import assetsDiscountsEntryPerConceptSetReducer, { assetsDiscountsEntryPerConceptSetReducerName } from "@icarius-pages/assetsDiscountsEntryPerConceptSet";
import automaticProcessesReducer, { automaticProcessesReducerName } from "@icarius-common/automaticProcesses";
import assetsDiscountsEntryByImportReducer, { assetsDiscountsEntryByImportReducerName } from "@icarius-pages/assetsDiscountsEntryByImport";
import specialProcessDefinitionReducer, { specialProcessDefinitionReducerName } from "@icarius-pages/specialProcessDefinition";
import specialProcessDefinitionParametersReducer, { specialProcessDefinitionParametersReducerName } from "@icarius-pages/specialProcessDefinitionParameters";
import accountingImputationsReducer, { accountingImputationsReducerName } from "@icarius-pages/accountingImputations";
import assetsDiscountsEntryByAPIReducer, { assetsDiscountsEntryByAPIReducerName } from "@icarius-pages/assetsDiscountsEntryByAPI";
import processSchedulingReducer, { processSchedulingReducerName } from "@icarius-pages/processScheduling";
import favouriteButtonReducer, { favouriteButtonReducerName } from "@icarius-common/favouriteButton";
import myDesktopReducer, { myDesktopReducerName } from "@icarius-pages/myDesktop";
import specialProcessExecutionReducer, { specialProcessExecutionReducerName } from "@icarius-pages/specialProcessExecution";
import myPeopleScheduleReducer, { myPeopleScheduleReducerName } from "@icarius-pages/myPeopleSchedule";
import myScheduleReducer, { myScheduleReducerName } from "@icarius-pages/mySchedule";
import rebuildAccumulatorsReducer, { rebuildAccumulatorsReducerName } from "@icarius-pages/rebuildAccumulators";
import turnDefinitionReducer, { turnDefinitionReducerName } from "@icarius-pages/turnDefinition";
import schedulesDefinitionReducer, { schedulesDefinitionReducerName } from "@icarius-pages/schedulesDefinition";
import schedulesProgrammingReducer, { schedulesProgrammingReducerName } from "@icarius-pages/schedulesProgramming";
import historicAccumulatorsReducer, { historicAccumulatorsReducerName } from "@icarius-pages/historicAccumulators";
import assetsDiscountsEntryByInstallmentsReducer, { assetsDiscountsEntryByInstallmentsReducerName } from "@icarius-pages/assetsDiscountsEntryByInstallments";
import alertsTemplatesReducer, { alertsTemplatesReducerName } from "@icarius-pages/alertsTemplates";
import calendarDefinitionReducer, { calendarDefinitionReducerName } from "@icarius-pages/calendarDefinition";
import extraHoursAuthorizationReducer, { extraHoursAuthorizationReducerName } from "@icarius-pages/extraHoursAuthorization";
import issueSummaryReducer, { issueSummaryReducerName } from "@icarius-pages/issueSummary";
import issueLicencesReducer, { issueLicencesReducerName } from "@icarius-pages/issueLicences";
import issuePermissionsReducer, { issuePermissionsReducerName } from "@icarius-pages/issuePermissions";
import issueAbsencesReducer, { issueAbsencesReducerName } from "@icarius-pages/issueAbsences";
import issueVacationsReducer, { issueVacationsReducerName } from "@icarius-pages/issueVacations";
import consentTypesReducer, { consentTypesReducerName } from "@icarius-pages/consentTypes";
import benefitsCenterReducer, { benefitsCenterReducerName } from "@icarius-pages/benefitsCenter";
import projectsReducer, { projectsReducerName } from "@icarius-pages/projects";
import consentPublicationReducer, { consentPublicationReducerName } from "@icarius-pages/consentPublication";
import familyGroupReducer, { familyGroupReducerName } from "@icarius-pages/familyGroup";
import distributionCostReducer, { distributionCostReducerName } from "@icarius-pages/distributionCost";
import historicalPeriodsReducer, { historicalPeriodsReducerName } from "@icarius-pages/historicalPeriods";
import myApprovedConsentsReducer, { myApprovedConsentsReducerName } from "@icarius-pages/myApprovedConsents";
import extraHoursTableReducer, { extraHoursTableReducerName } from "@icarius-pages/extraHoursTable";
import clockReducer, { clockReducerName } from "@icarius-common/clock";
import processesByPathReducer, { processesByPathReducerName } from "@icarius-common/processesByPath";
import gridProcessResultDialogReducer, { gridProcessResultDialogReducerName } from "@icarius-common/gridProcessResultDialog";
import catalogsReducer, { catalogsReducerName } from "@icarius-pages/catalogs";
import relatedTablesDefinitionReducer, { relatedTablesDefinitionReducerName } from "@icarius-pages/relatedTablesDefinition";
import systemAndUserTablesDefinitionReducer, { systemAndUserTablesDefinitionReducerName } from "@icarius-pages/systemAndUserTablesDefinition";
import systemAndUserTablesFieldsDefinitionReducer, { systemAndUserTablesFieldsDefinitionReducerName } from "@icarius-pages/systemAndUserTablesFieldsDefinition";
import systemAndUserTablesReducer, { systemAndUserTablesReducerName } from "@icarius-pages/systemAndUserTables";
import transactionLogReducer, { transactionLogReducerName } from "@icarius-pages/transactionLog";
import assistanceAnalysisReducer, { assistanceAnalysisReducerName } from "@icarius-pages/assistanceAnalysis";
import onboardingProfilesReducer, { onboardingProfilesReducerName } from "@icarius-pages/onboardingProfiles";
import recognitionPlansReducer, { recognitionPlansReducerName } from "@icarius-pages/recognitionPlans";
import feedbackPurposeReducer, { feedbackPurposeReducerName } from "@icarius-pages/feedbackPurpose";
import groupClassificationReducer, { groupClassificationReducerName } from "@icarius-pages/groupClassification";
import reasonForPointAwardingReducer, { reasonForPointAwardingReducerName } from "@icarius-pages/reasonForPointAwarding";
import organizationalBenefitsReducer, { organizationalBenefitsReducerName } from "@icarius-pages/organizationalBenefits";
import medalTableReducer, { medalTableReducerName } from "@icarius-common/medalTable";
import recognitionPlansAssignmentReducer, { recognitionPlansAssignmentReducerName } from "@icarius-pages/recognitionPlansAssignment";
import performanceFormsReducer, { performanceFormsReducerName } from "@icarius-pages/performanceForms";
import performanceEvaluationsReducer, { performanceEvaluationsReducerName } from "@icarius-pages/performanceEvaluations";
import performanceEvaluationsCalibrationReducer, { performanceEvaluationsCalibrationReducerName } from "@icarius-pages/performanceEvaluationsCalibration";
import performanceSummaryAndResultsReducer, { performanceSummaryAndResultsReducerName } from "@icarius-pages/performanceSummaryAndResults";
import myBenefitsReducer, { myBenefitsReducerName } from "@icarius-pages/myBenefits";
import recognitionAnalysisReducer, { recognitionAnalysisReducerName } from "@icarius-pages/recognitionAnalysis";
import nineBoxClassificationReducer, { nineBoxClassificationReducerName } from "@icarius-pages/nineBoxClassification";
import templatesReducer, { templatesReducerName } from "@icarius-common/templates";
import actionTypesReducer, { actionTypesReducerName } from "@icarius-pages/actionTypes";
import pollComparisonReducer, { pollComparisonReducerName } from "@icarius-pages/pollComparison";
import feedReducer, { feedReducerName } from "@icarius-pages/feed";
import feedbackReducer, { feedbackReducerName } from "@icarius-pages/feedback";
import userMenusReducer, { userMenusReducerName } from "@icarius-pages/userMenus";
import userMenuReducer, { userMenuReducerName } from "@icarius-pages/userMenu";
import socialBlacklistReducer, { socialBlacklistReducerName } from "@icarius-pages/socialBlacklist";
import typesOfObjectivesReducer, { typesOfObjectivesReducerName } from "@icarius-pages/typesOfObjectives";
import communicationChannelReducer, { communicationChannelReducerName } from "@icarius-pages/communicationChannel";
import ratingScalesReducer, { ratingScalesReducerName } from "@icarius-pages/ratingScales";
import periodsForObjectivesReducer, { periodsForObjectivesReducerName } from "@icarius-pages/periodsForObjectives";
import positionGroupsForEvaluationsReducer, { positionGroupsForEvaluationsReducerName } from "@icarius-pages/positionGroupsForEvaluations";
import typesOfSkillsSpecificReducer, { typesOfSkillsSpecificReducerName } from "@icarius-pages/typesOfSkillsSpecific";
import typesOfSkillsSpecificIndicatorsReducer, { typesOfSkillsSpecificIndicatorsReducerName } from "@icarius-pages/typesOfSkillsSpecificIndicators";
import typesOfSkillsTransversalReducer, { typesOfSkillsTransversalReducerName } from "@icarius-pages/typesOfSkillsTransversal";
import typesOfSkillsTransversalIndicatorsReducer, { typesOfSkillsTransversalIndicatorsReducerName } from "@icarius-pages/typesOfSkillsTransversalIndicators";
import goalsAndObjectivesReducer, { goalsAndObjectivesReducerName } from "@icarius-pages/goalsAndObjectives";
import evaluationProcessDefinitionReducer, { evaluationProcessDefinitionReducerName } from "@icarius-pages/evaluationProcessDefinition";
import evaluationProcessDefinitionAssignmentReducer, { evaluationProcessDefinitionAssignmentReducerName } from "@icarius-pages/evaluationProcessDefinitionAssignment";
import performanceAnalyticsEvaluationsReducer, { performanceAnalyticsEvaluationsReducerName } from "@icarius-pages/performanceAnalyticsEvaluations";
import performanceAnalyticsTalentReducer, { performanceAnalyticsTalentReducerName } from "@icarius-pages/performanceAnalyticsTalent";
import communicationClassificationReducer, { communicationClassificationReducerName } from "@icarius-pages/communicationClassification";
import onboardingMaterialsReducer, { onboardingMaterialsReducerName } from "@icarius-pages/onboardingMaterials";
import utilsReducer, { utilsReducerName } from "@icarius-pages/utils";
import onboardingProcessesReducer, { onboardingProcessesReducerName } from "@icarius-pages/onboardingProcesses";
import onboardingProcessesAssignmentReducer, { onboardingProcessesAssignmentReducerName } from "@icarius-pages/onboardingProcessesAssignment";
import onboardingTrackingReducer, { onboardingTrackingReducerName } from "@icarius-pages/onboardingTracking";
import myOnboardingTrackingReducer, { myOnboardingTrackingReducerName } from "@icarius-pages/myOnboardingTracking";
import absentCollaboratorsDialogReducer, { absentCollaboratorsDialogReducerName } from "@icarius-common/absentCollaboratorsDialog";
import performanceAnalyticsHistoricReducer, { performanceAnalyticsHistoricReducerName } from "@icarius-pages/performanceAnalyticsHistoric";

const appTopLevelReducer = combineReducers({
  [appReducerName]: appReducer,
  [homeReducerName]: homeReducer,
  [loginReducerName]: loginReducer,
  [myPeopleReducerName]: myPeopleReducer,
  [queriesReducerName]: queriesReducer,
  [dialogReducerName]: dialogReducer,
  [pollReducerName]: pollReducer,
  [directoryReducerName]: directoryReducer,
  [alertsReducerName]: alertReducer,
  [pollReviewReducerName]: pollReviewReducer,
  [myAssistanceReducerName]: myAssistanceReducer,
  [assistanceReducerName]: assistanceReducer,
  [kpiIndicatorsReducerName]: kpiIndicatorsReducer,
  [receiptsReducerName]: receiptsReducer,
  [loansReducerName]: loansReducer,
  [certificatesReducerName]: certificatesReducer,
  [myAnnotationsReducerName]: myAnnotationsReducer,
  [myRequestsReducerName]: myRequestsReducer,
  [myRequestsManagerReducerName]: myRequestsManagerReducer,
  [myPendingReducerName]: myPendingReducer,
  [myPendingEmployerReducerName]: myPendingEmployerReducer,
  [requestsReducerName]: requestsReducer,
  [delegateAttentionsReducerName]: delegateAttentionsReducer,
  [snackbarReducerName]: snackbarReducer,
  [actionsReducerName]: actionsReducer,
  [circularProgressReducerName]: circularProgressReducer,
  [personalDataReducerName]: personalDataReducer,
  [totemManagementReducerName]: totemManagementReducer,
  [assistanceTotemReducerName]: assistanceTotemReducer,
  [fiscalizationReducerName]: fiscalizationReducer,
  [nineBoxSettingsReducerName]: nineBoxSettingsReducer,
  [vacationsReducerName]: vacationsReducer,
  [signersReducerName]: signersReducer,
  [requestedDocumentsReducerName]: requestedDocumentsReducer,
  [signedDocumentsReducerName]: signedDocumentsReducer,
  [myGroupsReducerName]: myGroupsReducer,
  [generalSettingsReducerName]: generalSettingsReducer,
  [digitalFolderSettingsReducerName]: digitalFolderSettingsReducer,
  [galleryDialogReducerName]: galleryDialogReducer,
  [externalDocumentsReducerName]: externalDocumentsReducer,
  [myDocumentsReducerName]: myDocumentsReducer,
  [myDigitalDocumentsReducerName]: myDigitalDocumentsReducer,
  [pollSettingsReducerName]: pollSettingsReducer,
  [accessSettingsReducerName]: accessSettingsReducer,
  [annotationsAnalysisReducerName]: annotationsAnalysisReducer,
  [historicalProcessesReducerName]: historicalProcessesReducer,
  [currentProcessesReducerName]: currentProcessesReducer,
  [digitalFolderAnalysisReducerName]: digitalFolderAnalysisReducer,
  [employeesProcessesReducerName]: employeesProcessesReducer,
  [geoanalysisReducerName]: geoanalysisReducer,
  [publicationSettingsReducerName]: publicationSettingsReducer,
  [filtersDialogReducerName]: filtersDialogReducer,
  [positionEditorReducerName]: positionEditorReducer,
  [centersSettingsReducerName]: centersSettingsReducer,
  [permissionsSettingsReducerName]: permissionsSettingsReducer,
  [familySettingsReducerName]: familySettingsReducer,
  [functionsSettingsReducerName]: functionsSettingsReducer,
  [advancesSettingsReducerName]: advancesSettingsReducer,
  [documentPublicationReducerName]: documentPublicationReducer,
  [contractTypesSettingsReducerName]: contractTypesSettingsReducer,
  [audioPlayerReducerName]: audioPlayerReducer,
  [conceptGroupsReducerName]: conceptGroupsReducer,
  [contractorSettingsReducerName]: contractorSettingsReducer,
  [functionaryTypesSettingsReducerName]: functionaryTypesSettingsReducer,
  [locationSettingsReducerName]: locationSettingsReducer,
  [worksAndTasksSettingsReducerName]: worksAndTasksSettingsReducer,
  [divisionsSettingsReducerName]: divisionsSettingsReducer,
  [conceptsFormulasFunctionsReducerName]: conceptsFormulasFunctionsReducer,
  [accumulatorSettingsReducerName]: accumulatorSettingsReducer,
  [categoriesAndSpecialtiesReducerName]: categoriesAndSpecialtiesReducer,
  [payrollTypesSettingsReducerName]: payrollTypesSettingsReducer,
  [allergiesExamsDiseasesReducerName]: allergiesExamsDiseasesReducer,
  [calculationReferencesReducerName]: calculationReferencesReducer,
  [entryExitTypesReducerName]: entryExitTypesReducer,
  [annotationsPageReducerName]: annotationsPageReducer,
  [annexesPageReducerName]: annexesPageReducer,
  [organizationStructureReducerName]: organizationStructureReducer,
  [orgChartReducerName]: orgchartReducer,
  [ratingScalesReducerName]: ratingScalesReducer,
  [geographicalStructureReducerName]: geographicalStructureReducer,
  [issueTypesSettingsReducerName]: issueTypesSettingsReducer,
  [positionsClassificationSettingsReducerName]: positionsClassificationSettingsReducer,
  [queryDefinitionReducerName]: queryDefinitionReducer,
  [queryDefinitionParametersReducerName]: queryDefinitionParametersReducer,
  [geoQueryDefinitionReducerName]: geoQueryDefinitionReducer,
  [geoQueryDefinitionParametersReducerName]: geoQueryDefinitionParametersReducer,
  [generalValuesReducerName]: generalValuesReducer,
  [holidayTableReducerName]: holidayTableReducer,
  [extraHoursTableReducerName]: extraHoursTableReducer,
  [festivitiesTableReducerName]: festivitiesTableReducer,
  [kpiDefinitionReducerName]: kpiDefinitionReducer,
  [typesOfBenefitsReducerName]: typesOfBenefitsReducer,
  [benefitsForPlansReducerName]: benefitsForPlansReducer,
  [organizationalBenefitsReducerName]: organizationalBenefitsReducer,
  [notesReducerName]: notesReducer,
  [alertsSettingsReducerName]: alertsSettingsReducer,
  [conceptStructureReducerName]: conceptStructureReducer,
  [conceptsSetSettingsReducerName]: conceptsSetSettingsReducer,
  [thirdPartyClassificationReducerName]: thirdPartyClassificationReducer,
  [thirdPartyReducerName]: thirdPartyReducer,
  [laborUnionsReducerName]: laborUnionsReducer,
  [paymentTypesReducerName]: paymentTypesReducer,
  [documentTemplatesReducerName]: documentTemplatesReducer,
  [assetsDiscountsCreationReducerName]: assetsDiscountsCreationReducer,
  [assetsDiscountsScreenSelectorReducerName]: assetsDiscountsScreenSelectorReducer,
  [digitalWarehouseFileTypesReducerName]: digitalWarehouseFileTypesReducer,
  [schoolingReducerName]: schoolingReducer,
  [studiesReducerName]: studiesReducer,
  [specializationsReducerName]: specializationsReducer,
  [languagesReducerName]: languagesReducer,
  [retirementDefinitionReducerName]: retirementDefinitionReducer,
  [healthDefinitionReducerName]: healthDefinitionReducer,
  [assetsDiscountsEntryPerConceptReducerName]: assetsDiscountsEntryPerConceptReducer,
  [assetsDiscountsEntryPerSocietyReducerName]: assetsDiscountsEntryPerSocietyReducer,
  [digitalWarehouseReducerName]: digitalWarehouseReducer,
  [familyAsignationsReducerName]: familyAsignationsReducer,
  [ratingsReducerName]: ratingsReducer,
  [taxTableReducerName]: taxTableReducer,
  [assetsDiscountsSummaryReducerName]: assetsDiscountsSummaryReducer,
  [attendanceReportReducerName]: attendanceReportReducer,
  [assetsDiscountsEntryPerConceptSetReducerName]: assetsDiscountsEntryPerConceptSetReducer,
  [assetsDiscountsMassiveEntryReducerName]: assetsDiscountsMassiveEntryReducer,
  [nineBoxClassificationReducerName]: nineBoxClassificationReducer,
  [automaticProcessesReducerName]: automaticProcessesReducer,
  [assetsDiscountsEntryByImportReducerName]: assetsDiscountsEntryByImportReducer,
  [specialProcessDefinitionReducerName]: specialProcessDefinitionReducer,
  [accountingImputationsReducerName]: accountingImputationsReducer,
  [assetsDiscountsEntryByAPIReducerName]: assetsDiscountsEntryByAPIReducer,
  [specialProcessDefinitionParametersReducerName]: specialProcessDefinitionParametersReducer,
  [processSchedulingReducerName]: processSchedulingReducer,
  [favouriteButtonReducerName]: favouriteButtonReducer,
  [myDesktopReducerName]: myDesktopReducer,
  [specialProcessExecutionReducerName]: specialProcessExecutionReducer,
  [myPeopleScheduleReducerName]: myPeopleScheduleReducer,
  [myScheduleReducerName]: myScheduleReducer,
  [rebuildAccumulatorsReducerName]: rebuildAccumulatorsReducer,
  [turnDefinitionReducerName]: turnDefinitionReducer,
  [schedulesDefinitionReducerName]: schedulesDefinitionReducer,
  [schedulesProgrammingReducerName]: schedulesProgrammingReducer,
  [historicAccumulatorsReducerName]: historicAccumulatorsReducer,
  [assetsDiscountsEntryByInstallmentsReducerName]: assetsDiscountsEntryByInstallmentsReducer,
  [alertsTemplatesReducerName]: alertsTemplatesReducer,
  [calendarDefinitionReducerName]: calendarDefinitionReducer,
  [extraHoursAuthorizationReducerName]: extraHoursAuthorizationReducer,
  [issueSummaryReducerName]: issueSummaryReducer,
  [issueLicencesReducerName]: issueLicencesReducer,
  [issuePermissionsReducerName]: issuePermissionsReducer,
  [issueAbsencesReducerName]: issueAbsencesReducer,
  [issueVacationsReducerName]: issueVacationsReducer,
  [consentTypesReducerName]: consentTypesReducer,
  [benefitsCenterReducerName]: benefitsCenterReducer,
  [projectsReducerName]: projectsReducer,
  [consentPublicationReducerName]: consentPublicationReducer,
  [familyGroupReducerName]: familyGroupReducer,
  [distributionCostReducerName]: distributionCostReducer,
  [historicalPeriodsReducerName]: historicalPeriodsReducer,
  [myApprovedConsentsReducerName]: myApprovedConsentsReducer,
  [classificationApplicantsTypesReducerName]: classificationApplicantsTypesReducer,
  [typesOfPublicationChannelsReducerName]: typesOfPublicationChannelsReducer,
  [typesOfPublicationFormatsReducerName]: typesOfPublicationFormatsReducer,
  [typesOfOnboardingActionsReducerName]: typesOfOnboardingActionsReducer,
  [onboardingProfilesReducerName]: onboardingProfilesReducer,
  [clockReducerName]: clockReducer,
  [processesByPathReducerName]: processesByPathReducer,
  [gridProcessResultDialogReducerName]: gridProcessResultDialogReducer,
  [catalogsReducerName]: catalogsReducer,
  [relatedTablesDefinitionReducerName]: relatedTablesDefinitionReducer,
  [systemAndUserTablesDefinitionReducerName]: systemAndUserTablesDefinitionReducer,
  [systemAndUserTablesFieldsDefinitionReducerName]: systemAndUserTablesFieldsDefinitionReducer,
  [systemAndUserTablesReducerName]: systemAndUserTablesReducer,
  [transactionLogReducerName]: transactionLogReducer,
  [assistanceAnalysisReducerName]: assistanceAnalysisReducer,
  [recognitionPlansReducerName]: recognitionPlansReducer,
  [feedbackPurposeReducerName]: feedbackPurposeReducer,
  [reasonForPointAwardingReducerName]: reasonForPointAwardingReducer,
  [medalTableReducerName]: medalTableReducer,
  [recognitionPlansAssignmentReducerName]: recognitionPlansAssignmentReducer,
  [performanceFormsReducerName]: performanceFormsReducer,
  [performanceEvaluationsReducerName]: performanceEvaluationsReducer,
  [performanceEvaluationsCalibrationReducerName]: performanceEvaluationsCalibrationReducer,
  [performanceSummaryAndResultsReducerName]: performanceSummaryAndResultsReducer,
  [myBenefitsReducerName]: myBenefitsReducer,
  [recognitionAnalysisReducerName]: recognitionAnalysisReducer,
  [templatesReducerName]: templatesReducer,
  [actionTypesReducerName]: actionTypesReducer,
  [pollComparisonReducerName]: pollComparisonReducer,
  [feedReducerName]: feedReducer,
  [feedbackReducerName]: feedbackReducer,
  [groupClassificationReducerName]: groupClassificationReducer,
  [userMenusReducerName]: userMenusReducer,
  [userMenuReducerName]: userMenuReducer,
  [communicationChannelReducerName]: communicationChannelReducer,
  [socialBlacklistReducerName]: socialBlacklistReducer,
  [typesOfObjectivesReducerName]: typesOfObjectivesReducer,
  [periodsForObjectivesReducerName]: periodsForObjectivesReducer,
  [positionGroupsForEvaluationsReducerName]: positionGroupsForEvaluationsReducer,
  [typesOfSkillsSpecificReducerName]: typesOfSkillsSpecificReducer,
  [typesOfSkillsSpecificIndicatorsReducerName]: typesOfSkillsSpecificIndicatorsReducer,
  [typesOfSkillsTransversalReducerName]: typesOfSkillsTransversalReducer,
  [typesOfSkillsTransversalIndicatorsReducerName]: typesOfSkillsTransversalIndicatorsReducer,
  [typesOfScoreReducerName]: typesOfScoreReducer,
  [goalsAndObjectivesReducerName]: goalsAndObjectivesReducer,
  [evaluationProcessDefinitionReducerName]: evaluationProcessDefinitionReducer,
  [evaluationProcessDefinitionAssignmentReducerName]: evaluationProcessDefinitionAssignmentReducer,
  [performanceAnalyticsEvaluationsReducerName]: performanceAnalyticsEvaluationsReducer,
  [performanceAnalyticsTalentReducerName]: performanceAnalyticsTalentReducer,
  [communicationClassificationReducerName]: communicationClassificationReducer,
  [onboardingMaterialsReducerName]: onboardingMaterialsReducer,
  [utilsReducerName]: utilsReducer,
  [onboardingProcessesReducerName]: onboardingProcessesReducer,
  [onboardingProcessesAssignmentReducerName]: onboardingProcessesAssignmentReducer,
  [onboardingTrackingReducerName]: onboardingTrackingReducer,
  [myOnboardingTrackingReducerName]: myOnboardingTrackingReducer,
  [absentCollaboratorsDialogReducerName]: absentCollaboratorsDialogReducer,
  [performanceAnalyticsHistoricReducerName]: performanceAnalyticsHistoricReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_REDUCERS") {
    state = undefined;
  }

  return appTopLevelReducer(state, action);
};

export default rootReducer;
