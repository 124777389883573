import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import paths, { PATHS_TO_IGNORE_AS_VISITED } from "@icarius-localization/paths";
import { getPageNameFromPath } from "@icarius-routes";
import { setPageAsVisitedAction } from "src/app/actions";

const useVisitPage = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (path && Object.values(paths).includes(path) && !PATHS_TO_IGNORE_AS_VISITED.includes(path)) {
      dispatch(setPageAsVisitedAction({ path, name: getPageNameFromPath(path) }));
    }
  }, [dispatch, path])
}

export default useVisitPage;