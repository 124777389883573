import { getLocalizedString } from "@icarius-localization/strings";
import { downloadBlob } from "@icarius-utils/download";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";
import {
  dateFormatter,
  dateClassRule,
  dateFilterParams,
  dateValueGetter,
  dateComparator,
} from "./date";
import {
  numberFormatterMin0Max0,
  numberFormatterMin2,
  numberComparatorMax2,
  numberFilterParams,
} from "./number";
import { exportExcel, exportJSON, exportTXT, exportXML, exportIcariusSmart } from "./exportFile";

let taskControl = false;
let hiddenHeader = false;
let ctx;
let gridName = "";
let societyName = "";
let chartType = "";
let locale = "da";
let exportToExcelFunction;

export const setTaskControl = (value) => taskControl = value;
export const setHiddenHeader = (value) => hiddenHeader = value;
export const setCtx = (value) => ctx = value; // recibe la ref del aggrid en cuestión
export const setGridName = (value) => gridName = value || ''; // nombre con el que se exportan los archivos
export const setSocietyName = (value) => societyName = value || '';
export const setChartType = (value) => chartType = value;
export const setLocale = (value) => locale = value;
export const setExportToExcelFunction = (value) => exportToExcelFunction = value;

export const getLocale = () => {
  if (!locale) return "da";
  return locale;
};

const dateFormatterValue = (params) => {
  if (params && typeof params.split != "undefined") {
    let split = params.split("-");
    return split[2] + "/" + split[1] + "/" + split[0];
  }
};

const isDate = (date) => {
  return Boolean(
    new Date(date).toDateString().toLowerCase().lastIndexOf("invalid") === -1
    && !isNaN(new Date(date)) && date === new Date(date).toISOString().split("T")[0]
  );
};

export const getChartTheme = (theme) => {
  return theme === "dark" ?
    [
      'ag-default-dark',
      'ag-material-dark',
      'ag-pastel-dark',
      'ag-solar-dark',
      'ag-vivid-dark'
    ]
    :
    [
      'ag-default',
      'ag-material',
      'ag-pastel',
      'ag-solar',
      'ag-vivid'
    ];
}

export const applyGeographicalDivision = (columnDef, geographicalDivisions) => {
  let newColumnDef = [...columnDef];

  if (!geographicalDivisions || !Object.keys(geographicalDivisions).length) return newColumnDef;

  if (!geographicalDivisions.thirdLevel) {
    // si no tiene el campo, lo filtro de la grilla
    newColumnDef = newColumnDef.filter((item) => item.field !== "COMUNA");
  } else {
    const thirdLevelField = newColumnDef.find((item) => item.field === "COMUNA");
    if (thirdLevelField) thirdLevelField.headerName = geographicalDivisions.thirdLevel;
  }

  const secondLevelField = newColumnDef.find((item) => item.field === "PROVINCIA");
  if (secondLevelField) secondLevelField.headerName = geographicalDivisions.secondLevel;

  const firstLevelField = newColumnDef.find((item) => item.field === "REGION");
  if (firstLevelField) firstLevelField.headerName = geographicalDivisions.firstLevel;

  return newColumnDef;
}

export function tooltipOptions(parameters) {
  if (["stackedBar", "groupedBar", "normalizedBar", "stackedColumn", "groupedColumn", "normalizedColumn"].includes(chartType)) {
    let xValue = parameters.datum[parameters.xKey];
    let yValue = parameters.datum[parameters.yKey];

    let xString = typeof xValue === "number" ? formatNumberOrReturnUndefined(xValue, 2) : String(xValue);
    let yString = typeof yValue === "number" ? formatNumberOrReturnUndefined(yValue, 2) : String(yValue);

    if (isDate(yString)) {
      yString = dateFormatterValue(yString);
    }

    if (isDate(xString)) {
      xString = dateFormatterValue(xString);
    }

    let titleStyle = 'style="color: white; background-color: ' + parameters.color + '"';
    let titleString = parameters.yName
      ? '<div class="ag-chart-tooltip-title" ' + titleStyle + ">" + parameters.yName + "</div>"
      : "";

    return titleString + '<div class="ag-chart-tooltip-content">' + xString + ": " + yString + "</div>";
  }

  if (chartType === "line") {
    let xValue = parameters.datum[parameters.xKey];
    let yValue = parameters.datum[parameters.yKey];

    let xString = typeof xValue === "number" ? formatNumberOrReturnUndefined(xValue, 2) : String(xValue);
    let yString = typeof yValue === "number" ? formatNumberOrReturnUndefined(yValue, 2) : String(yValue);

    if (isDate(yString)) {
      yString = dateFormatterValue(yString);
    }
    if (isDate(xString)) {
      xString = dateFormatterValue(xString);
    }

    let titleStyle = 'style="color: white; background-color: ' + parameters.color + '"';
    let titleString = parameters.title
      ? '<div class="ag-chart-tooltip-title" ' + titleStyle + ">" + parameters.title + "</div>"
      : "";

    return titleString + '<div class="ag-chart-tooltip-content">' + xString + ": " + yString + "</div>";
  }

  if (["area", "stackedArea", "normalizedArea"].includes(chartType)) {
    let xValue = parameters.datum[parameters.xKey];
    let yValue = parameters.datum[parameters.yKey];

    let xString = typeof xValue === "number" ? formatNumberOrReturnUndefined(xValue, 2) : String(xValue);
    let yString = typeof yValue === "number" ? formatNumberOrReturnUndefined(yValue, 2) : String(yValue);

    if (isDate(yString)) {
      yString = dateFormatterValue(yString);
    }
    if (isDate(xString)) {
      xString = dateFormatterValue(xString);
    }

    let titleStyle = 'style="color: white; background-color: ' + parameters.color + '"';
    let titleString = parameters.yName
      ? '<div class="ag-chart-tooltip-title" ' + titleStyle + ">" + parameters.yName + "</div>"
      : "";

    return titleString + '<div class="ag-chart-tooltip-content">' + xString + ": " + yString + "</div>";
  }

  if (["scatter", "bubble"].includes(chartType)) {
    let xValue = parameters.datum[parameters.xKey];
    let yValue = parameters.datum[parameters.yKey];

    let contentHtml =
      "<b>" +
      parameters.xName +
      "</b>: " +
      xValue +
      "<br><b>" +
      parameters.yName +
      "</b>: " +
      yValue;

    if (parameters.sizeKey) {
      contentHtml += "<br/><b>" + parameters.sizeName + "</b>: " + parameters.datum[parameters.sizeKey];
    }

    if (parameters.labelKey) {
      contentHtml = "<b>" + parameters.labelName + "</b>: " + parameters.datum[parameters.labelKey] + "<br/>" + contentHtml;
    }

    let titleStyle = 'style="color: white; background-color: ' + parameters.color + '"';
    let titleHtml = parameters.title
      ? '<div class="ag-chart-tooltip-title" ' + titleStyle + ">" + parameters.title + "</div>"
      : "";

    return titleHtml + '<div class="ag-chart-tooltip-content">' + contentHtml + "</div>";
  }

  if (["pie", "doughnut"].includes(chartType)) {
    let value = parameters.datum[parameters.angleKey];
    let label = parameters.datum[parameters.labelKey];
    let formattedValue = typeof value === "number" ? formatNumberOrReturnUndefined(value, 2) : value.toString();

    if (isDate(label)) {
      label = dateFormatterValue(label);
    }

    if (isDate(formattedValue)) {
      formattedValue = dateFormatterValue(formattedValue);
    }

    let titleStyle = 'style="color: white; background-color: ' + parameters.color + '"';
    let titleString = parameters.title
      ? '<div class="ag-chart-tooltip-title" ' + titleStyle + ">" + parameters.title + "</div>"
      : "";

    label = label ? label + ": " : "";

    return titleString + '<div class="ag-chart-tooltip-content">' + label + formattedValue + "</div>";
  }

  if (chartType === "histogram") {
    let xValue = parameters.datum[parameters.xKey];

    let aggregatedValue = typeof xValue === "number" ? formatNumberOrReturnUndefined(xValue, 2) : String(xValue);
    let rangeMin = formatNumberOrReturnUndefined(parameters.datum.domain[0], 2);
    let rangeMax = formatNumberOrReturnUndefined(parameters.datum.domain[1], 2);

    if (isDate(aggregatedValue)) {
      aggregatedValue = dateFormatterValue(aggregatedValue);
    }

    let titleStyle = 'style="color: white; background-color: ' + parameters.color + '"';
    let titleString = '<div class="ag-chart-tooltip-title" ' + titleStyle + ">" + (parameters.xName || parameters.xKey) + " " + rangeMin + " - " + rangeMax + "</div>";


    let contentHtml = parameters.yKey ? "<b>" + (parameters.yName || parameters.yKey) + " (" + parameters.datum.aggregation + ")</b>: " + aggregatedValue + "<br>" : '';

    contentHtml += "<b>" + getLocalizedString("frequency") + "</b>: " + parameters.datum.frequency;

    return titleString + '<div class="ag-chart-tooltip-content">' + contentHtml + "</div>";
  }
}

export const processChartOptions = (params) => {
  let options = params.options;
  setChartType(params.type);
  options.seriesDefaults.tooltip.renderer = tooltipOptions;

  return options;
};

export const groupColumn = {
  headerName: getLocalizedString("group"),
  width: 200,
  field: "CODIGO DE EMPLEADO",
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  cellRenderer: "agGroupCellRenderer",
  cellRendererParams: {
    checkbox: true,
  },
};

export const decimalStatusBar = () => {
  try {
    //Obtengo una referencia al DOM
    const node = ctx.current.eGridDiv;

    // Si se detecto bien
    if (node instanceof HTMLElement) {
      //promedio
      let childAvg = node.querySelector("div.ag-status-panel:nth-child(3) > div:nth-child(1) > span:nth-child(2)");
      if (childAvg) childAvg.innerText = formatNumberOrReturnUndefined(childAvg.innerText.replace(/[^\d.-]/g, ""), 2);
      //recuento
      let childRecount = node.querySelector("div.ag-status-panel:nth-child(3) > div:nth-child(2) > span:nth-child(2)");
      if (childRecount) childRecount.innerText = formatNumberOrReturnUndefined(childRecount.innerText.replace(/[^\d.-]/g, ""), 0);
      //minimo
      let childMin = node.querySelector("div.ag-name-value:nth-child(3) > span:nth-child(2)");
      if (childMin) childMin.innerText = formatNumberOrReturnUndefined(childMin.innerText.replace(/[^\d.-]/g, ""), 2);
      //maximo
      let childMax = node.querySelector("div.ag-name-value:nth-child(4) > span:nth-child(2)");
      if (childMax) childMax.innerText = formatNumberOrReturnUndefined(childMax.innerText.replace(/[^\d.-]/g, ""), 2);
      //suma
      let childSum = node.querySelector("div.ag-name-value:nth-child(5) > span:nth-child(2)");
      if (childSum) childSum.innerText = formatNumberOrReturnUndefined(childSum.innerText.replace(/[^\d.-]/g, ""), 2);
    }
  } catch (e) { }
};

// Funcion para pasar un string a Date
export const tryGetDateFromString = (str) => {
  if (str === null) return false;

  // Obtengo la fecha y la intento separar en un array de tres partes, usando / como caracter separador
  let dateParts = str.split("/");

  // Si el array resultante tiene un solo elemento, quiere decir que la fecha no estaba separada por /
  if (dateParts.length === 1) {
    // Intento separar la fecha en un array de tres partes, usando - como caracter separador
    dateParts = str.split("-");

    // Si el array resultante tiene un solo elemento, quiere decir que la fecha no estaba separada ni por / ni por -
    if (dateParts.length === 1) {
      // Intento separar la fecha por espacios como ultimo recurso
      dateParts = str.split(" ");

      if (dateParts.length === 1) {
        // No se pudo obtener la fecha
        return false;
      }
    }
  }

  // En base al array resultante, intento instanciar un Date
  let day;
  let month;
  let year;
  // Compruebo si el primer elemento del array tiene exactamente 4 caracteres de largo
  if (dateParts[0].length === 4) {
    // Los tiene! Quiere decir que el primer elemento es el año
    day = Number(dateParts[2]);
    month = Number(dateParts[1]) - 1;
    year = Number(dateParts[0]);
  } else {
    // El primer elemento del array no tiene 4 caracteres. Asumo que el año es el ultimo elemento del array
    day = Number(dateParts[0]);
    month = Number(dateParts[1]) - 1;
    year = Number(dateParts[2]);
  }

  return new Date(year, month, day);
};

// Comprueba si una una fecha en formato Date de JS es valida
export const isValidDateObject = (date) => {
  return date instanceof Date && !isNaN(date);
};

export const getContextMenuItems = (params) => {

  return [
    "copy",
    "copyWithHeaders",
    "separator",
    {
      name: getLocalizedString("gridExport"),
      icon: '<span class="ag-icon ag-icon-save" unselectable="on"></span>',
      subMenu: [
        {
          name: 'a Excel',
          action: () => exportExcel(params, gridName, societyName, exportToExcelFunction, hiddenHeader, taskControl),
        },
        {
          name: 'a XML',
          action: () => exportXML(params, gridName, downloadBlob),
        },
        {
          name: 'a TXT',
          action: () => exportTXT(params, gridName, downloadBlob),
        },
        {
          name: 'a JSON',
          action: () => exportJSON(params, gridName, downloadBlob),
        },
        {
          name: 'a ICARIUS SMART',
          action: () => exportIcariusSmart(params, gridName, downloadBlob),
        },
      ],
    },
    "separator",
    "chartRange",
    "separator",
    "resetColumns",
  ];
};

export const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
  enableRowGroup: true,
  enablePivot: true,
  enableValue: true,
  floatingFilter: true,
  allowedAggFuncs: ["count"],
};

export const statusBar = {
  statusPanels: [
    {
      statusPanel: "agTotalAndFilteredRowCountComponent",
      align: "left",
    },
    {
      statusPanel: "agFilteredRowCountComponent",
    },
    {
      statusPanel: "agSelectedRowCountComponent",
    },
    {
      statusPanel: "agAggregationComponent",
    },
  ],
};

export const sideBar = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
    },
  ],
  defaultToolPanel: "",
};

export const agGridLanguage = {
  es: {
    // for filter panel
    page: "Página",
    more: "Más",
    to: "a",
    of: "de",
    next: "Siguiente",
    last: "Último",
    first: "Primero",
    previous: "Previo",
    loadingOoo: "Cargando...",
    // for set filter
    selectAll: "Seleccionar todo",
    searchOoo: "Buscar...",
    SearchOoo: "Buscar...",
    blanks: "Nada",
    // for number filter and text filter
    filterOoo: "Filtro...",
    applyFilter: "Aplicar filtro...",
    resetFilter: "Resetear filtro...",
    clearFilter: "Limpiar filtro...",

    equals: "Igual",
    notEquals: "No igual",
    notEqual: "No igual",
    // for number filter
    lessThan: "Menor",
    greaterThan: "Mayor",
    lessThanOrEqual: "Menor o igual",
    greaterThanOrEqual: "Mayor o igual",
    inRange: "Rango",
    inRangeStart: "Desde",
    inRangeEnd: "Hasta",
    // for text filter
    contains: "Contiene",
    notContains: "No contiene",
    startsWith: "Comienza por",
    endsWith: "Acaba en",
    // filter conditions
    or: "O",
    and: "Y",
    orCondition: "O",
    andCondition: "Y",
    // the header of the default group column
    group: "Agrupado",
    // tool panel
    columns: "Columnas",
    filters: "Filtros",
    rowGroupColumns: "Agrupar (Cols)",
    rowGroupColumnsEmptyMessage: "Arrastra aquí columnas para agruparlas",
    valueColumns: "Valores (Cols)",
    pivotMode: "Modo Pivote",
    groups: "Grupos",
    values: "Valores",
    pivots: "Pivotes",
    valueColumnsEmptyMessage: "Arrastra aquí columnas para cálculos",
    pivotColumnsEmptyMessage: "Arrastra aquí columnas para pivotes",
    toolPanelButton: "Panel de herramientas",
    // other
    noRowsToShow: "No hay datos",
    // enterprise menu
    pinColumn: "Fijar columna",
    valueAggregation: "Calcular valor",
    autosizeThiscolumn: "Ajustar esta columna",
    autosizeAllColumns: "Ajustar todas las columnas",
    groupBy: "Agrupar por",
    ungroupBy: "Desagrupar por",
    resetColumns: "Resetear estas columnas",
    expandAll: "Expandir todo",
    collapseAll: "Contraer todo",
    toolPanel: "Panel de herramientas",
    export: "Exportar",
    csvExport: "Exportar a CSV",
    excelExport: "Exportar a Excel",
    excelXmlExport: "Exportar a XML",
    // enterprise menu pinning
    pinLeft: "Izquierda",
    pinRight: "Derecha",
    noPin: "No fijar",
    // enterprise menu aggregation and status bar
    sum: "Suma",
    min: "Mínimo",
    max: "Máximo",
    none: "Nada",
    count: "Recuento",
    avg: "Promedio",
    average: "Promedio",
    filteredRows: "Registros filtrados",
    selectedRows: "Registros seleccionados",
    totalRows: "Total de registros",
    totalAndFilteredRows: "Total de registros",
    // standard menu
    copy: "Copiar",
    copyWithHeaders: "Copiar con cabeceras",
    ctrlC: "Control+C",
    paste: "Pegar",
    ctrlV: "Control+V",
    // añadidos de prueba en status Bar
    filteredRowCount: "Filtrados",
    rowCount: "Registros totales",
    selectedRowCount: "Seleccionado",
    // MENU
    menu: {
      Recibos: "Recibos",
      Polizas: "Polizas",
      Clientes: "Clientes",
      Siniestros: "Siniestros",
      Usuarios: "Usuarios",
      Registros: "Registros",
    },
    // charts
    pivotChartTitle: "Gráfico dinámico",
    rangeChartTitle: "Gráfico de rango",
    settings: "Ajustes",
    data: "Datos",
    format: "Formato",
    categories: "Categorias",
    defaultCategory: "Ninguna",
    series: "Series",
    xyValues: "Valores de X e Y",
    paired: "Modo emparejado",
    axis: "Ejes",
    color: "Color",
    thickness: "Grosor",
    xType: "Tipo Eje X",
    automatic: "Automático",
    category: "Categoría",
    number: "Número",
    time: "Hora",
    xRotation: "Rotación eje X",
    yRotation: "Rotación eje Y",
    ticks: "Ticks",
    width: "Ancho",
    length: "Longitud",
    padding: "Padding",
    chart: "Gráfico",
    title: "Título",
    background: "Fondo",
    font: "Fuente",
    top: "Arriba",
    right: "Derecha",
    bottom: "Abajo",
    left: "Izquierda",
    labels: "Etiquetas",
    size: "Tamaño",
    minSize: "Tamaño mínimo",
    maxSize: "Tamaño máximo",
    legend: "Leyenda",
    position: "Posición",
    markerSize: "Tamaño del marcador",
    markerStroke: "Trazo del marcador",
    markerPadding: "Padding del marcador",
    itemPaddingX: "Padding eje X del item",
    itemPaddingY: "Padding eje Y del item",
    strokeWidth: "Ancho del trazo",
    offset: "Desplazamiento",
    tooltips: "Ayuda contextual",
    offsets: "Desplazamientos",
    callout: "Callout",
    markers: "Marcadores",
    shadow: "Sombra",
    blur: "Difuminación",
    xOffset: "Desplazamiento eje X",
    yOffset: "Desplazamiento eje Y",
    lineWidth: "Ancho de línea",
    normal: "Normal",
    bold: "Negrita",
    italic: "Cursiva",
    boldItalic: "Negrita cursiva",
    predefined: "Predefinido",
    fillOpacity: "Llenar opacidad",
    strokeOpacity: "Opacidad de trazo",
    histogramBinCount: "Cuenta de intervalos",
    histogramGroup: "Grupo",
    histogramTooltip: "Ayuda contextual",
    columnGroup: "Columna",
    barGroup: "Barras",
    pieGroup: "Circular",
    lineGroup: "Lineal",
    scatterGroup: "Dispersión",
    areaGroup: "Área",
    groupedColumnTooltip: "Columnas agrupadas",
    stackedColumnTooltip: "Columnas apiladas",
    normalizedColumnTooltip: "100% apilada",
    groupedBarTooltip: "Barras agrupadas",
    stackedBarTooltip: "Barras apiladas",
    normalizedBarTooltip: "100% apilada",
    pieTooltip: "Circular",
    doughnutTooltip: "Dona",
    lineTooltip: "Línea",
    groupedAreaTooltip: "Areas agrupadas",
    stackedAreaTooltip: "Areas apiladas",
    normalizedAreaTooltip: "100% apilada",
    scatterTooltip: "Dispersión",
    bubbleTooltip: "Burbujas",
    noDataToChart: "No existe información para graficar.",
    pivotChartRequiresPivotMode: "El gráfico de pivote requiere el modo pivote habilitado.",
    // enterprise menu (charts)
    pivotChartAndPivotMode: "Modo y gráficos pivote",
    pivotChart: "Gráfico pivote",
    chartRange: "Rango de gráfico",
    columnChart: "Columnas",
    groupedColumn: "Columnas agrupadas",
    stackedColumn: "Columnas apiladas",
    normalizedColumn: "100% apilado",
    barChart: "Barras",
    groupedBar: "Barras agrupadas",
    stackedBar: "Barras apiladas",
    normalizedBar: "100% apilado",
    pieChart: "Gráfico circular",
    pie: "Circular",
    doughnut: "Dona",
    line: "Linea",
    xyChart: "X Y (Dispersión)",
    scatter: "Dispersión",
    bubble: "Burbujas",
    areaChart: "Área",
    area: "Área",
    stackedArea: "Apilado",
    normalizedArea: "100% Apilado",
    histogramChart: "Histograma",
    enabled: "Habilitado",
    // for the date filter
    dateFormatOoo: "dd/mmm/aaaa",
    titlePlaceholder: "Título del gráfico - doble click para editar",
    frequency: "Frecuencia",
  },
};

export const onGridReady = (params) => {
  let allColumnIds = [];
  params.columnApi.getAllColumns().forEach(function (column) {
    allColumnIds.push(column.colId);
  });
  params.columnApi.autoSizeColumns(allColumnIds);
};

const getAggridColumnFilter = (filter) => {
  switch (filter) {
    case "Lista":
      return "agSetColumnFilter";
    case "Progreso":
      return "ProgressFilter";
    case "Num":
    case "Fecha":
    case "Alfa":
    case "Mon":
    case "Estrellas":
    case "Indicadores":
    case "RichText":
    case "9Boxes":
    default:
      return "agTextColumnFilter";
  }
}

export const createAggridColumn = (name, filterType, headerName) => {
  let item = {
    headerName: headerName || name,
    field: name,
    filter: getAggridColumnFilter(filterType),
    cellClass: filterType === "Alfa" && "stringType"
  };

  switch (filterType) {
    case "Fecha": {
      item.comparator = dateComparator;
      item.filterParams = dateFilterParams;
      item.cellClassRules = dateClassRule;
      item.valueFormatter = dateFormatter;
      item.valueGetter = dateValueGetter;
      break;
    }
    case "Mon": {
      item.comparator = numberComparatorMax2;
      item.filterParams = numberFilterParams;
      item.cellClass = "currencyValue";
      item.valueFormatter = numberFormatterMin2;
      item.enableValue = true;
      item.allowedAggFuncs = ["sum", "min", "max", "avg"];
      break;
    }
    case "Num": {
      item.comparator = numberComparatorMax2;
      item.filterParams = numberFilterParams;
      item.cellClass = "currencyValueNoDecimal";
      item.valueFormatter = numberFormatterMin0Max0;
      item.enableValue = true;
      item.allowedAggFuncs = ["sum", "min", "max", "avg"];
      break;
    }
    case "Estrellas": {
      item.comparator = numberComparatorMax2;
      item.filterParams = numberFilterParams;
      item.chartDataType = "series";
      item.valueFormatter = numberFormatterMin0Max0;
      item.enableValue = true;
      item.allowedAggFuncs = ["sum", "min", "max", "avg"];
      item.cellRenderer = 'RatingRenderer';
      break;
    }
    case "Indicadores": {
      item.comparator = numberComparatorMax2;
      item.filterParams = numberFilterParams;
      item.chartDataType = "series";
      item.valueFormatter = numberFormatterMin0Max0;
      item.enableValue = true;
      item.allowedAggFuncs = ["sum", "min", "max", "avg"];
      item.cellRenderer = 'IndicatorRenderer';
      break;
    }
    case "RichText": {
      item.cellRenderer = 'RichTextRenderer';
      break;
    }
    case "9Boxes": {
      item.comparator = numberComparatorMax2;
      item.filterParams = numberFilterParams;
      item.chartDataType = "series";
      item.valueFormatter = numberFormatterMin0Max0;
      item.enableValue = true;
      item.allowedAggFuncs = ["sum", "min", "max", "avg"];
      item.cellRenderer = 'NineBoxImageRenderer';
      break;
    }
    case "Progreso": {
      item.cellRenderer = "ProgressRenderer";
      item.cellClass = "percentageValue";
      break;
    }
    case "AvatarRenderer": {
      item.cellRenderer = "AvatarRenderer";
      break;
    }
    case "AbsenceRenderer": {
      item.cellRenderer = "AbsenceRenderer";
      break;
    }
    default: break;
  }

  return item;
}

export const monthToComparableNumber = (date) => {
  // Convertir fechas a numeros (no funciona con horas, minutos, o segundos)
  if (date === undefined || date === null || date.length < 8) return null;

  // Intento obtener la fecha desde el string
  const dateObj = tryGetDateFromString(date);

  // Si no se pudo obtener la fecha, devuelvo null
  if (!isValidDateObject(dateObj)) return null;

  const yearNumber = Number(dateObj.getFullYear());
  const monthNumber = Number(dateObj.getMonth());
  const dayNumber = Number(dateObj.getDate());

  return yearNumber * 10000 + monthNumber * 100 + dayNumber;
};

export const currencyStringToNumber = (numStr, decimalQuantity = 2) => {
  if (typeof numStr !== "number" && typeof numStr !== "string") return null;
  if (typeof numStr === "number") return numStr;

  // Es type string
  numStr = numStr.trim();

  // Al ser un numero de formato moneda, asumo que tiene como maximo dos decimales
  // Compruebo si tiene uno o dos decimales, fijandome si el anteultimo o antepenultimo caracter del string es una , o .
  if (numStr.indexOf(".") === -1 && numStr.indexOf(",") === -1) return Number(numStr);

  if (numStr.substring(numStr.length - decimalQuantity - 1).charAt(0) === "," || numStr.substring(numStr.length - decimalQuantity - 1).charAt(0) === ".") {
    numStr = numStr.substr(0, numStr.length - decimalQuantity - 1) + "_" + numStr.substr(numStr.length - decimalQuantity);
  } else {
    if (numStr.substring(numStr.length - 4).charAt(0) === "," || numStr.substring(numStr.length - 4).charAt(0) === ".") {
      // El antepenultimo caracter del string es una , o .
      // Cambio dicho caracter por un _ temporalmente (para posteriormente limpiar el string de separadores de miles, si los tiene)
      numStr = numStr.substr(0, numStr.length - 4) + "_" + numStr.substr(numStr.length - 4 + 1);
    } else if (numStr.substring(numStr.length - 3).charAt(0) === "," || numStr.substring(numStr.length - 3).charAt(0) === ".") {
      // El antepenultimo caracter del string es una , o .
      // Cambio dicho caracter por un _ temporalmente (para posteriormente limpiar el string de separadores de miles, si los tiene)
      numStr = numStr.substr(0, numStr.length - 3) + "_" + numStr.substr(numStr.length - 3 + 1);
    } else if (numStr.substring(numStr.length - 2).charAt(0) === "," || numStr.substring(numStr.length - 2).charAt(0) === ".") {
      // El anteultimo caracter del string es una , o .
      // Cambio dicho caracter por un _ temporalmente (para posteriormente limpiar el string de separadores de miles, si los tiene)
      numStr = numStr.substr(0, numStr.length - 2) + "_" + numStr.substr(numStr.length - 2 + 1);
    } else if (numStr.substring(numStr.length - 1).charAt(0) === "," || numStr.substring(numStr.length - 1).charAt(0) === ".") {
      // El ultimo caracter del string es una , o .
      // Borro dicho caracter
      numStr = numStr.substr(0, numStr.length - 1);
    }
  }

  // Si el string tiene separadores de miles (ya sea en coma o punto) los elimino
  numStr = numStr.replace(/,/g, "");
  numStr = numStr.replace(/\./g, "");

  // Ahora que se eliminaron los separadores de miles, recupero el caracter separador de decimales
  numStr = numStr.replace("_", ".");

  // Paso string a numero
  numStr = Number(numStr);

  // Si es un numero valido, devuelvo respuesta
  if (!isNaN(numStr)) return numStr;
};