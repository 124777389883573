import React from 'react';
import { IconButton } from '@material-ui/core';
import { LinkIcon } from '@icarius-icons/index';
import { previewFromURL } from '@icarius-utils/download';

const ExternalFileRenderer = ({ value }) => {
    return (
        Boolean(value) ?
            <div
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => previewFromURL(value)}
            >
                <IconButton style={{ height: 24 }}>
                    <LinkIcon />
                </IconButton>
            </div>
            : <div />
    );
}

export default ExternalFileRenderer;