import { default as automaticProcesses } from "@icarius-common/automaticProcesses/columnDef";
import { default as accessSettings } from "@icarius-pages/accessSettings/columnDef";
import { default as accountingImputations } from "@icarius-pages/accountingImputations/columnDef";
import { default as alertsSettings } from "@icarius-pages/alertsSettings/columnDef";
import { default as annexesPage } from "@icarius-pages/annexesPage/columnDef";
import { default as annotationsAnalysis } from "@icarius-pages/annotationsAnalysis/columnDef";
import { default as assetsDiscountsSummary } from "@icarius-pages/assetsDiscountsSummary/columnDef";
import { default as assistance } from "@icarius-pages/assistance/columnDef";
import { default as assistanceAnalysis } from "@icarius-pages/assistanceAnalysis/columnDef";
import { default as attendanceReport } from "@icarius-pages/attendanceReport/columnDef";
import { default as benefitsForPlans } from "@icarius-pages/benefitsForPlans/columnDef";
import { default as calendarDefinition } from "@icarius-pages/calendarDefinition/columnDef";
import { default as catalogs } from "@icarius-pages/catalogs/columnDef";
import { default as certificates } from "@icarius-pages/certificates/columnDef";
import { default as conceptStructure } from "@icarius-pages/conceptStructure/columnDef";
import { default as conceptsFormulasFunctions } from "@icarius-pages/conceptsFormulasFunctions/columnDef";
import { default as consentPublication } from "@icarius-pages/consentPublication/columnDef";
import { default as currentProcesses } from "@icarius-pages/currentProcesses/columnDef";
import { default as digitalFolderAnalysis } from "@icarius-pages/digitalFolderAnalysis/columnDef";
import { default as digitalFolderSettings } from "@icarius-pages/digitalFolderSettings/columnDef";
import { default as digitalWarehouse } from "@icarius-pages/digitalWarehouse/columnDef";
import { default as directory } from "@icarius-pages/directory/columnDef";
import { default as distributionCost } from "@icarius-pages/distributionCost/columnDef";
import { default as employeesProcesses } from "@icarius-pages/employeesProcesses/columnDef";
import { default as externalDocuments } from "@icarius-pages/externalDocuments/columnDef";
import { default as extraHoursTable } from "@icarius-pages/extraHoursTable/columnDef";
import { default as performanceEvaluationsTable } from "@icarius-pages/performanceEvaluations/columnDef";
import { default as performanceEvaluationsCalibration } from "@icarius-pages/performanceEvaluationsCalibration/columnDef";
import { default as familyGroup } from "@icarius-pages/familyGroup/columnDef";
import { default as festivitiesTable } from "@icarius-pages/festivitiesTable/columnDef";
import { default as actions } from "@icarius-pages/actions/columnDef";
import { default as fiscalization } from "@icarius-pages/fiscalization/columnDef";
import { default as generalValues } from "@icarius-pages/generalValues/columnDef";
import { default as onboardingProfiles } from "@icarius-pages/onboardingProfiles/columnDef";
import { default as performanceSummaryAndResults } from "@icarius-pages/performanceSummaryAndResults/columnDef";
import { default as geoQuery } from "@icarius-pages/geoQuery/columnDef";
import { default as geoQueryParameters } from "@icarius-pages/geoQueryParameters/columnDef";
import { default as geographicalStructure } from "@icarius-pages/geographicalStructure/columnDef";
import { default as historicAccumulators } from "@icarius-pages/historicAccumulators/columnDef";
import { default as historicalProcesses } from "@icarius-pages/historicalProcesses/columnDef";
import { default as holidayTable } from "@icarius-pages/holidayTable/columnDef";
import { default as issueAbsences } from "@icarius-pages/issueAbsences/columnDef";
import { default as issueLicences } from "@icarius-pages/issueLicences/columnDef";
import { default as issuePermissions } from "@icarius-pages/issuePermissions/columnDef";
import { default as issueSummary } from "@icarius-pages/issueSummary/columnDef";
import { default as issueVacations } from "@icarius-pages/issueVacations/columnDef";
import { default as kpiDefinition } from "@icarius-pages/kpiDefinition/columnDef";
import { default as kpiIndicators } from "@icarius-pages/kpiIndicators/columnDef";
import { default as loans } from "@icarius-pages/loans/columnDef";
import { default as myAnnexes } from "@icarius-pages/myAnnexes/columnDef";
import { default as myApprovedConsents } from "@icarius-pages/myApprovedConsents/columnDef";
import { default as myAssistance } from "@icarius-pages/myAssistance/columnDef";
import { default as myPending } from "@icarius-pages/myPending/columnDef";
import { default as myPendingEmployer } from "@icarius-pages/myPendingEmployer/columnDef";
import { default as myPeople } from "@icarius-pages/myPeople/columnDef";
import { default as myRequests } from "@icarius-pages/myRequests/columnDef";
import { default as myRequestsManager } from "@icarius-pages/myRequestsManager/columnDef";
import { default as notes } from "@icarius-pages/notes/columnDef";
import { default as organizationalBenefits } from "@icarius-pages/organizationalBenefits/columnDef";
import { default as organizationStructure } from "@icarius-pages/organizationStructure/columnDef";
import { default as pollReview } from "@icarius-pages/pollReview/columnDef";
import { default as positionEditor } from "@icarius-pages/positionEditor/columnDef";
import { default as processScheduling } from "@icarius-pages/processScheduling/columnDef";
import { default as queryDefinition } from "@icarius-pages/queryDefinition/columnDef";
import { default as queryDefinitionParameters } from "@icarius-pages/queryDefinitionParameters/columnDef";
import { default as ratings } from "@icarius-pages/ratings/columnDef";
import { default as rebuildAccumulators } from "@icarius-pages/rebuildAccumulators/columnDef";
import { default as receipts } from "@icarius-pages/receipts/columnDef";
import { default as recognitionPlans } from "@icarius-pages/recognitionPlans/columnDef";
import { default as performanceForms } from "@icarius-pages/performanceForms/columnDef";
import { default as recognitionPlansAssignment } from "@icarius-pages/recognitionPlansAssignment/columnDef";
import { default as relatedTablesDefinition } from "@icarius-pages/relatedTablesDefinition/columnDef";
import { default as requestedDocuments } from "@icarius-pages/requestedDocuments/columnDef";
import { default as requests } from "@icarius-pages/requests/columnDef";
import { default as schedulesDefinition } from "@icarius-pages/schedulesDefinition/columnDef";
import { default as schedulesProgramming } from "@icarius-pages/schedulesProgramming/columnDef";
import { default as signedDocuments } from "@icarius-pages/signedDocuments/columnDef";
import { default as specialProcessDefinition } from "@icarius-pages/specialProcessDefinition/columnDef";
import { default as specialProcessExecution } from "@icarius-pages/specialProcessExecution/columnDef";
import { default as systemAndUserTablesFieldsDefinition } from "@icarius-pages/systemAndUserTablesFieldsDefinition/columnDef";
import { default as taxTable } from "@icarius-pages/taxTable/columnDef";
import { default as transactionLog } from "@icarius-pages/transactionLog/columnDef";
import { default as typesOfBenefits } from "@icarius-pages/typesOfBenefits/columnDef";
import { default as vacations } from "@icarius-pages/vacations/columnDef";
import { default as recognitionAnalysis } from "@icarius-pages/recognitionAnalysis/columnDef";
import { default as nineBoxClassification } from "@icarius-pages/nineBoxClassification/columnDef";
import { default as pollComparison } from "@icarius-pages/pollComparison/columnDef";
import { default as myBenefits } from "@icarius-pages/myBenefits/columnDef";
import { default as feedback } from "@icarius-pages/feedback/columnDef";
import { default as communicationChannel } from "@icarius-pages/communicationChannel/columnDef";
import { default as socialBlacklist } from "@icarius-pages/socialBlacklist/columnDef";
import { default as periodsForObjectives } from "@icarius-pages/periodsForObjectives/columnDef";
import { default as typesOfSkillsSpecific } from "@icarius-pages/typesOfSkillsSpecific/columnDef";
import { default as typesOfSkillsSpecificIndicators } from "@icarius-pages/typesOfSkillsSpecificIndicators/columnDef";
import { default as typesOfSkillsTransversal } from "@icarius-pages/typesOfSkillsTransversal/columnDef";
import { default as typesOfSkillsTransversalIndicators } from "@icarius-pages/typesOfSkillsTransversalIndicators/columnDef";
import { default as goalsAndObjectives } from "@icarius-pages/goalsAndObjectives/columnDef";
import { default as evaluationProcessDefinition } from "@icarius-pages/evaluationProcessDefinition/columnDef";
import { default as evaluationProcessDefinitionAssignment } from "@icarius-pages/evaluationProcessDefinitionAssignment/columnDef";
import { default as performanceAnalyticsEvaluations } from "@icarius-pages/performanceAnalyticsEvaluations/columnDef";
import { default as onboardingProcesses } from "@icarius-pages/onboardingProcesses/columnDef";
import { default as onboardingProcessesAssignment } from "@icarius-pages/onboardingProcessesAssignment/columnDef";
import { default as performanceAnalyticsHistoric } from "@icarius-pages/performanceAnalyticsHistoric/columnDef";

const columnDefs = [
    ...automaticProcesses,
    ...accessSettings,
    ...accountingImputations,
    ...alertsSettings,
    ...annexesPage,
    ...annotationsAnalysis,
    ...assetsDiscountsSummary,
    ...assistance,
    ...assistanceAnalysis,
    ...attendanceReport,
    ...benefitsForPlans,
    ...calendarDefinition,
    ...catalogs,
    ...certificates,
    ...conceptsFormulasFunctions,
    ...nineBoxClassification,
    ...conceptStructure,
    ...consentPublication,
    ...currentProcesses,
    ...digitalFolderAnalysis,
    ...digitalFolderSettings,
    ...digitalWarehouse,
    ...directory,
    ...distributionCost,
    ...employeesProcesses,
    ...externalDocuments,
    ...extraHoursTable,
    ...familyGroup,
    ...festivitiesTable,
    ...fiscalization,
    ...generalValues,
    ...geographicalStructure,
    ...geoQuery,
    ...geoQueryParameters,
    ...historicAccumulators,
    ...historicalProcesses,
    ...performanceEvaluationsTable,
    ...performanceEvaluationsCalibration,
    ...holidayTable,
    ...issueAbsences,
    ...issueLicences,
    ...issuePermissions,
    ...issueSummary,
    ...issueVacations,
    ...kpiDefinition,
    ...kpiIndicators,
    ...loans,
    ...myAnnexes,
    ...myApprovedConsents,
    ...myAssistance,
    ...myPending,
    ...myPendingEmployer,
    ...myPeople,
    ...myRequests,
    ...myRequestsManager,
    ...notes,
    ...organizationalBenefits,
    ...organizationStructure,
    ...pollReview,
    ...positionEditor,
    ...processScheduling,
    ...queryDefinition,
    ...queryDefinitionParameters,
    ...ratings,
    ...rebuildAccumulators,
    ...receipts,
    ...recognitionPlans,
    ...performanceForms,
    ...recognitionPlansAssignment,
    ...relatedTablesDefinition,
    ...requestedDocuments,
    ...requests,
    ...schedulesDefinition,
    ...schedulesProgramming,
    ...signedDocuments,
    ...specialProcessExecution,
    ...systemAndUserTablesFieldsDefinition,
    ...taxTable,
    ...specialProcessDefinition,
    ...transactionLog,
    ...typesOfBenefits,
    ...vacations,
    ...recognitionAnalysis,
    ...actions,
    ...pollComparison,
    ...myBenefits,
    ...feedback,
    ...socialBlacklist,
    ...periodsForObjectives,
    ...typesOfSkillsSpecific,
    ...typesOfSkillsSpecificIndicators,
    ...typesOfSkillsTransversal,
    ...typesOfSkillsTransversalIndicators,
    ...goalsAndObjectives,
    ...evaluationProcessDefinition,
    ...onboardingProfiles,
    ...communicationChannel,
    ...evaluationProcessDefinitionAssignment,
    ...performanceSummaryAndResults,
    ...performanceAnalyticsEvaluations,
    ...onboardingProcesses,
    ...onboardingProcessesAssignment,
    ...performanceAnalyticsHistoric,
]

export const getColumnDefByPage = (page) => {
    const columnDefinitions = columnDefs.filter((item) => item.pages.includes(page));
    return columnDefinitions.map((item) => item.config);
};