import React from 'react';

const RatingRenderer = ({ value }) => {

    if (!value) return null;

    return (
        <>
            {
                [...Array(Number(value)).keys()].map(() => {
                    return ('⭐')
                })
            }
        </>
    );
}

export default RatingRenderer;