import { useState } from "react";

const useZoom = (initialValue, min, max) => {

  const [zoom, setZoom] = useState(initialValue);

  const zoomOut = () => setZoom((prev) => prev <= min ? min : (prev - 1));
  const zoomIn = () => setZoom((prev) => prev >= max ? max : (prev + 1));

  return {
    zoom,
    zoomIn,
    zoomOut,
  }
};

export default useZoom;