import React from 'react';
const TimeBetweenMarksRenderer = ({ value, data }) => {

    const getBackgroundColor = () => {
        if (data?.hasYellowHighlight) {
            return '#f5a506';
        }

        if (data?.hasRedHighlight) {
            return '#ff4040';
        }

        return null;
    }

    return (
        Boolean(value) ?
            <div className="div-percent-bar" style={{ width: "100%", backgroundColor: getBackgroundColor() }}>
                {(value === "Sin marca de salida" || value === "Sin marca de entrada") && <div className="div-percent-value">{value}</div>}
                {(value !== "Sin marca de salida" && value !== "Sin marca de entrada") && <div style={{ paddingLeft: 4 }}>{value}</div>}
            </div>
            : <div />
    );
}

export default TimeBetweenMarksRenderer;