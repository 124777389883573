
import { getLocalizedString } from "@icarius-localization/strings";

export const getRequestSnackbarText = (status) => {
    switch(status){
        case "1": return getLocalizedString("requestCreatedOK");
        case "2": return getLocalizedString("requestDeletedOK");
        case "3": return getLocalizedString("requestChangeSentSnack");
        case "4": return getLocalizedString("requestChangeAcceptedSnack");
        case "5": return getLocalizedString("requestChangeRejectedSnack");
        case "6": return getLocalizedString("requestAccetedSnack");
        case "7": return getLocalizedString("requestRejectedSnack");
        default: return "";
    }   
}

export const requestsIds = {

    id_type : {
        "VAC" : getLocalizedString("holidays"),
        "PERM": getLocalizedString("permission"),
        "ANT": getLocalizedString("advance"),
        "RS": getLocalizedString("raise"),
        "FR": getLocalizedString("firing"),
    },
    
    id_subtype : {
        "UV" : getLocalizedString("legalVacation"),
        "VA" : getLocalizedString("additionalVacation"),
    },

    id_time_per_day : {
        "0.5" : getLocalizedString("halfDay"),
        "1.0" : getLocalizedString("fullDay"),
    },

    id_status : {
        "1" : getLocalizedString("requestRequested"),
        "2" : getLocalizedString("requestCancelled"),
        "3" : getLocalizedString("requestChangedProposed"),
        "4" : getLocalizedString("requestChangeAccepted"),
        "5" : getLocalizedString("requestChangeRejected"),
        "6" : getLocalizedString("requestApproved"),
        "7" : getLocalizedString("requestRejected"),
    }
}   


