import React from 'react';
import { IconButton } from '@material-ui/core';
import { RoomIcon } from '@icarius-icons';

const PositionImageRenderer = ({ value }) => {
    return (
        value ?
            <div
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <IconButton style={{ height: 24 }}>
                    <RoomIcon />
                </IconButton>
            </div>
            : <div />
    );
}

export default PositionImageRenderer;