import React from 'react';
import { getLocalizedString } from "@icarius-localization/strings";
import { getTheme } from "@icarius-pages/login/selectors";
import { useSelector } from 'react-redux';

const NoteItemStatusRenderer = ({ value }) => {

    const theme = useSelector(getTheme);

    const getBackgroundColor = () => {
        if (theme.theme === "light" && (value.includes(getLocalizedString("taskEndsToday")) || value.includes(getLocalizedString("taskDelay")))) {
            return '#ff6904';
        }

        if (value.includes(getLocalizedString("taskEndsToday")) || value.includes(getLocalizedString("taskDelay"))) {
            return '#c7463c';
        }
        if (value.includes(getLocalizedString("taskDaysRemaining"))) {
            return '#00a1db';
        }

        return '#67ad34';
    }

    return (
        Boolean(value) ?
            <div className="div-percent-bar" style={{ width: "100%", backgroundColor: getBackgroundColor() }}>
                <div className="div-percent-value">{value}</div>
            </div>
            : <div />
    );
}

export default NoteItemStatusRenderer;