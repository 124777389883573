import csv2json from "csvjson-csv2json";
import convert from "xml-js";
import { objectToXml, formatDraftToText } from "@icarius-utils/format";
import { customFileTypes } from "@icarius-utils/constants";
import { getLocale } from "./utils";

export const exportExcel = (params, gridName, societyName, exportFunction, hiddenHeader, taskControl) => {
  const columnsWithRenderer = params.api.columnController.columnDefs.filter((item) => {
    return [
      "ImageRenderer",
      "ExternalImageRenderer",
      "ExternalFileRenderer",
      "FileRenderer",
      "RichTextRenderer",
      "AvatarRenderer",
    ].includes(item.cellRenderer);
  }).map((item) => item.field);
  const visibleColumns = params.api.columnController.allDisplayedColumns.map((item) => item.colId);
  const visibleColumnsWithoutRenderer = visibleColumns.filter((item) => !columnsWithRenderer.includes(item));

  let taskControlCell = taskControl ?
    [{
      styleId: "bigHeader",
      data: {
        type: "String",
        value: 'CONTROL DE TAREAS',
      },
      mergeAcross: visibleColumnsWithoutRenderer.length - 1,
    }]
    : [];

  let settingsExcel = {};
  settingsExcel.columnGroups = true;
  settingsExcel.fileName = gridName;
  settingsExcel.sheetName = "Hoja 1";
  settingsExcel.columnKeys = visibleColumnsWithoutRenderer;

  if (hiddenHeader === true) {
    settingsExcel.customHeader = [];
  } else {
    settingsExcel.customHeader = [
      [],
      [
        {
          styleId: "bigHeader",
          data: {
            type: "String",
            value: societyName,
          },
          mergeAcross: visibleColumnsWithoutRenderer.length - 1,
        },
      ],
      taskControlCell,
    ];
  }

  if (taskControl === true) {
    settingsExcel.customHeader.push([]);
  }

  exportFunction(getLocale(), gridName, params, settingsExcel);
};

export const exportXML = (params, gridName, exportFunction) => {
  let rowList = [];
  params.api.forEachNodeAfterFilterAndSort((node) => rowList.push(node.data));

  const headerData = params.columnApi.getAllDisplayedColumns().map((node) => {
    return ({
      field: node.colDef.field,
      headerName: node.colDef.headerName,
      cellRenderer: node.colDef.cellRenderer,
    });
  });

  if (params && params.columnApi.isPivotMode()) {
    const options = { compact: true, ignoreComment: true, spaces: 4, textKey: "_" };
    const csvData = params.api.getDataAsCsv({ columnGroups: true });
    const jsonData = csv2json(csvData, { transpose: true });

    const regex = /-> /;
    const start = '<?xml version="1.0" encoding="UTF-8"?><root>';
    const end = "</root>";

    jsonData.map((item) => {
      Object.keys(item).forEach((col) => {
        if (col !== "") {
          item[col.replace(regex, "")] = item[col];
          delete item[col];
        }
        return col;
      });

      item["Pivote"] = item[""];
      delete item[""];
      return item;
    });

    let result = convert.js2xml(jsonData, options);

    jsonData.forEach((item, index) => {
      let regex = new RegExp(index + ">", "g");
      result = result.replace(regex, "element>");
    });

    exportFunction(start + result + end, "xml", gridName);
    return;
  }

  // no es modo pivote
  let fileContent = [];

  rowList.forEach((row) => {
    let fileContentRow = {};

    headerData.forEach((headerElement) => {
      let field = headerElement.field;

      if (row?.hasOwnProperty(field)) {
        if (headerElement.cellRenderer === "RichTextRenderer") {
          fileContentRow[field] = formatDraftToText(JSON.parse(row[field]));
        } else {
          fileContentRow[field] = row[field];
        }
      }
    });

    if (Object.keys(fileContentRow)?.length) {
      fileContent.push(fileContentRow);
    }
  });

  exportFunction(objectToXml(fileContent), "xml", gridName);
};

export const exportTXT = (params, gridName, exportFunction) => {
  let rowList = [];
  params.api.forEachNodeAfterFilterAndSort((node) => rowList.push(node.data));

  const headerData = params.columnApi.getAllDisplayedColumns().map((node) => {
    return ({
      field: node.colDef.field,
      headerName: node.colDef.headerName,
      cellRenderer: node.colDef.cellRenderer,
    });
  });

  if (params && params.columnApi.isPivotMode()) {
    const csvData = params.api.getDataAsCsv({ columnGroups: true });
    exportFunction(csvData, "txt", gridName);
    return;
  }

  // no es modo pivote
  let fileContent = headerData.map((item) => item.headerName).join(";") + "\r\n"; // armar encabezado con los nombres de los campos

  rowList.forEach((row) => {
    headerData.forEach((headerElement) => {
      let field = headerElement.field;

      if (row?.hasOwnProperty(field)) {
        if (row[field] === null) {
          fileContent += ";";
        } else {
          if (headerElement.cellRenderer === "RichTextRenderer") {
            fileContent += formatDraftToText(JSON.parse(row[field])) + ";";
          } else {
            fileContent += row[field] + ";";
          }
        }
      }
    });

    if (row) { // si se agregó contenido, poner el salto de línea
      fileContent = fileContent.substring(0, fileContent.length - 1); // sacarle el ultimo ;
      fileContent += "\r\n";
    }
  });

  exportFunction(fileContent, "txt", gridName);
};

export const exportJSON = (params, gridName, exportFunction) => {
  let rowList = [];
  params.api.forEachNodeAfterFilterAndSort((node) => rowList.push(node.data));

  const headerData = params.columnApi.getAllDisplayedColumns().map((node) => {
    return ({
      field: node.colDef.field,
      headerName: node.colDef.headerName,
      cellRenderer: node.colDef.cellRenderer,
    });
  });

  if (params && params.columnApi.isPivotMode()) {
    const csvData = params.api.getDataAsCsv({ columnGroups: true });
    exportFunction(JSON.stringify(csv2json(csvData, { transpose: true })), "json", gridName);
    return;
  }

  // no es modo pivote
  let fileContent = [];

  rowList.forEach((row) => {
    let fileContentRow = {};

    headerData.forEach((headerElement) => {
      let field = headerElement.field;

      if (row?.hasOwnProperty(field)) {
        if (headerElement.cellRenderer === "RichTextRenderer") {
          fileContentRow[field] = formatDraftToText(JSON.parse(row[field]));
        } else {
          fileContentRow[field] = row[field];
        }
      }
    });

    if (Object.keys(fileContentRow)?.length) {
      fileContent.push(fileContentRow);
    }
  });

  exportFunction(JSON.stringify(fileContent), "json", gridName);
};

export const exportIcariusSmart = (params, gridName, exportFunction) => {
  // es igual al de txt pero con otra extension para ser mas cool
  exportTXT(
    params,
    gridName,
    (smartFileData) => {
      exportFunction(smartFileData, customFileTypes.icariusSmart, gridName);
    }
  );
};