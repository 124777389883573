import { applyMiddleware, createStore } from "redux";
import reducers from "./combinedReducers";
import thunk from "redux-thunk";
import axios from "axios";
const store = createStore(reducers, applyMiddleware(thunk));

axios.interceptors.request.use(
  function (config) {
    if (config.url.includes("/login")) return config;

    // eslint-disable-next-line
    return { ...config, headers: { ...config.headers } };
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default store;
