import { formatDraftToText } from "@icarius-utils/format";

const RichTextRenderer = ({ value }) => {

    const getText = () => {
        try {
            const newString = typeof value === "object" ? value : JSON.parse(value);
            return formatDraftToText(newString);
        } catch (e) {
            return value || "";
        }
    }

    return getText();
}

export default RichTextRenderer;