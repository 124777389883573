import moment from "moment";

const emailReg = /[^\s@]+@[^\s@]+\.[^\s@]+/;
const textReg = /[A-Za-zÀ-ÖØ-öø-ÿ]/;
const numberReg = /^(\d)+$/;
const timeReg = /(\d{2})(:)(\d{2})/;
const boolReg = /^(true|false)$/;
const pinReg = /^[a-z0-9]+$/i;
export const valueTypes = {
  boolean: 0,
  text: 1,
  number: 2,
  email: 3,
  time: 4,
  date: 5,
  pin: 6,
};

export const valueIsOfType = (value, type) => {
  if (value === undefined || value === null) return false;

  switch (type) {
    case valueTypes.boolean: return boolReg.test(value);
    case valueTypes.text: return textReg.test(value);
    case valueTypes.number: return numberReg.test(value);
    case valueTypes.email: return emailReg.test(value);
    case valueTypes.time: return timeReg.test(value);
    case valueTypes.pin: return pinReg.test(value);
    case valueTypes.date: return moment(value).isValid();
    default: return true;
  }
};

export const getRutValidationDigit = (rut) => {
  let M = 0, S = 1;
  for (; rut; rut = Math.floor(rut / 10))
    S = (S + rut % 10 * (9 - M++ % 6)) % 11;
  return S ? S - 1 : 'k';
}

export const getRutIsValid = (rutToValidate) => {
  if (!/^\d+[-|‐]{1}[0-9kK]{1}$/.test(rutToValidate)) return false;
  let [rutPrefix, rutValidationDigit] = rutToValidate.split('-');
  if (rutValidationDigit === 'K') rutValidationDigit = 'k';
  return getRutValidationDigit(rutPrefix).toString() === rutValidationDigit;
}

export const checkIfEmail = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

export const checkIfUrl = (url) => {
  const webReg = /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;
  return webReg.test(String(url));
}

export const isValidUrl = (urlString) => {
  try {
      return Boolean(new URL(urlString));
  }
  catch (e) {
      return false;
  }
}