import React from 'react';
import { previewFromURL } from "@icarius-utils/download";

const ExternalImageRenderer = ({ value, column }) => {

    return (
        Boolean(value) ?
            <div
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => previewFromURL(value)}
            >
                <img
                    style={{ height: 24 }}
                    src={value}
                    alt={column.colId}
                />
            </div>
            : <div />
    );
}

export default ExternalImageRenderer;