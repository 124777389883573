import { createMuiTheme } from "@material-ui/core/styles";
import { themeColors, generalColors, hexToRgb } from "./colors";
import { HOVER_OPACITY, HOVER_OPACITY_LIGHT } from "@icarius-utils/constants";
import themes from 'devextreme/ui/themes';

export const setDevExtremeTheme = (theme) => {
  if (theme === "dark") {
    themes.current("generic.dark");
    return;
  }
  themes.current("generic.light");
};

export const createTheme = (theme = "dark", color) => {
  return createMuiTheme({
    palette: {
      primary: {
        main: color || "#000000",
      },
      secondary: {
        main: color || "#000000",
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: theme === "dark" ? "#adadad" : "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
      error: {
        main: "#D50000",
      }
    },
    props: {
      MuiSelect: {
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        },
      }
    },
    overrides: {
      MuiCard: {
        root: {
          border: 'none',
          backgroundColor: theme === "dark" ? themeColors.secondaryBackgroundColorDark : themeColors.secondaryBackgroundColorLight,
          color: "var(--mainText)",
        }
      },
      MuiDrawer: {
        paper: {
          background: "var(--drawer)",
        }
      },
      MuiDialog: {
        paper: {
          color: "var(--mainText)",
          background: "var(--modalBg)",
          borderRadius: 10,
        }
      },
      MuiDialogTitle: {
        root: {
          color: "var(--mainText)",
        },
      },
      MuiExpansionPanel: {
        root: {
          "&::before": {
            backgroundColor: theme === "dark" ? "rgba(0, 0, 0, 0.12)" : "transparent",
          },
        },
      },
      MuiPickersMonthSelection: {
        container: {
          color: "var(--mainText)",
        }
      },
      MuiPickersYearSelection: {
        container: {
          color: "var(--mainText)",
        }
      },
      MuiPickersCalendarHeader: {
        dayLabel: {
          color: theme === "dark" ? "#c9c9c9" : "#9e9e9e",
        },
        iconButton: {
          borderRadius: "50%",
          backgroundColor: theme === "dark" ? themeColors.mainBackgroundColorDark : themeColors.mainBackgroundColorLight,
          color: theme === "dark" ? "white !important" : "black !important",
        },
        transitionContainer: {
          color: theme === "dark" ? "white !important" : "black !important",
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          backgroundColor: theme === "dark" ? themeColors.mainBackgroundColorDark : themeColors.mainBackgroundColorLight,
        },
      },
      MuiPickersDay: {
        day: {
          color: theme === "dark" ? "white !important" : "black !important",
          fontFamily: '"Do Hyeon", sans-serif',
          backgroundColor: theme === "dark" ? themeColors.mainBackgroundColorDark : themeColors.mainBackgroundColorLight,
          borderRadius: "0px",
        },
        daySelected: {
          borderRadius: "50%",
          backgroundColor: color,
          color: "light-gray",
        },
        dayDisabled: {
          color: theme === "dark" ? "#5c5c5c !important" : "#b9b9b9 !important",
        },
        current: {
          color: "",
        },
      },
      MuiPickersClockNumber: {
        clockNumber: {
          color: theme === "dark" && "white",
        }
      },
      MuiTableRow: {
        head: {
          backgroundColor: theme === "dark" ? "#2b2b2b" : "#f1f1f1",
        },
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: theme === "dark" ? "#3b3b3b" : themeColors.mainBackgroundColorLight,
          },
          backgroundColor: theme === "dark" ? "#454545" : "#fafafa",
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: theme === "dark" ? "1px solid black" : "1px solid  rgba(224, 224, 224, 1)",
        },
        head: {
          color: "var(--mainText)",
        },
        body: {
          color: "var(--mainText)",
        },
        stickyHeader: {
          backgroundColor: theme === "dark" ? '#2b2b2b' : '#f1f1f1',
        }
      },
      MuiTooltip: {
        tooltip: {
          fontSize: "14px",
          fontWeight: "0px",
        }
      },
      MuiFormLabel: {
        root: {
          color: "var(--mainText)",
          '&$focused': {
            color: "var(--mainText)",
          },
          '&$disabled': {
            color: "var(--mainText)",
          },
        },
        asterisk: {
          color: generalColors.red,
        }
      },
      MuiCheckbox: {
        root: {
          color: "var(--icons)",
          '&$checked': {
            color: color,
          },
        },
      },
      MuiRadio: {
        root: {
          color: "#747574",
        },
        colorPrimary: {
          '&$disabled': {
            color: "#747574",
          },
        },
        colorSecondary: {
          color: "var(--mainText)",
          "&$checked": {
            color: color,
          },
          "&$disabled": {
            color: theme === "dark" && "#adadad",
          },
        },
      },
      MuiFormControlLabel: {
        root: {
          color: "var(--mainText)",
        },
        label: {
          minWidth: "100px",
          display: "flex",
          justifyContent: "flex-end",
        },
      },
      MuiFormHelperText: {
        root: {
          color: "var(--mainText)",
        }
      },
      MuiInputLabel: {
        root: {
          width: "max-content",
        }
      },
      MuiSelect: {
        select: {
          "&:before": {
            borderColor: color || (theme === "dark" ? "#6d6d6d" : "#b6b6b6"),
          },
          "&:after": {
            borderColor: color || (theme === "dark" ? "#6d6d6d" : "#b6b6b6"),
          },
        },
        root: {
          MuiInput: {
            "&:after": {
              borderBottomColor: color || (theme === "dark" ? "#6d6d6d" : "#b6b6b6"),
            },
          },
        },
        icon: {
          color: theme === "light" && "#7c7c78",
          fill: color || (theme === "dark" ? "#6d6d6d" : "#b6b6b6"),
        },
      },
      MuiMenu: {
        paper: {
          backgroundColor: theme === "dark" ? themeColors.secondaryBackgroundColorDark : themeColors.mainBackgroundColorLight,
        },
      },
      MuiMenuItem: {
        root: {
          "&:hover": {
            backgroundColor: color ? (hexToRgb(color, theme === "dark" ? HOVER_OPACITY : HOVER_OPACITY_LIGHT)) : (theme === "dark" ? "#6d6d6d" : "#b6b6b6"),
          },
          color: "var(--mainText)",
        },
      },
      MuiIconButton: {
        root: {
          color: color,
          "&$disabled": {
            color: "#adadad",
          },
        },
      },
      MuiButton: {
        root: {
          color: "var(--mainText)",
          borderRadius: "6px",
          "&:hover": {
            backgroundColor: hexToRgb(color, theme === "dark" ? HOVER_OPACITY : HOVER_OPACITY_LIGHT) + "!important",
          },
        },
        contained: {
          "&$disabled": {
            backgroundColor: "#730f0f",
            color: "var(--mainText)",
          },
          backgroundColor: theme === "dark" ? "#222" : "transparent",
          border: `1px solid ${theme === "dark" ? themeColors.borderTransparentDark : themeColors.borderTransparentLight}`,
          "&:hover": {
            backgroundColor: hexToRgb(color, theme === "dark" ? HOVER_OPACITY : HOVER_OPACITY_LIGHT) + "!important",
          },
        },
        outlined: {
          padding: "5px 15px",
          border: `1px solid ${theme === "dark" ? themeColors.borderTransparentDark : themeColors.borderTransparentLight}`,
          "&$disabled": {
            border: `1px solid black`,
          },
          "&:hover": {
            backgroundColor: hexToRgb(color, theme === "dark" ? HOVER_OPACITY : HOVER_OPACITY_LIGHT) + "!important",
          },
        },
      },
      MuiSwitch: {
        switchBase: {
          color: color,
          "&$checked": {
            color: color,
          },
          "&$checked + $track": {
            backgroundColor: color,
          },
        },
        checked: {},
        track: {
          backgroundColor: "#a0a0a0",
        },
      },
      MuiInput: {
        root: {
          color: "var(--mainText)",
        },
        underline: {
          "&&&&:hover:before": {
            borderBottom: "1px solid " + (color || "#747474"),
          },
          "&&&&:before": {
            borderBottom: "1px solid #747474",
          },
          "&&&&:after": {
            borderBottom: "1px solid " + (color || "#747474"),
          },
        },
      },
      MuiPopover: {
        paper: {
          background: "var(--modalBg)",
        }
      },
      MuiAlert: {
        filledInfo: {
          backgroundColor: generalColors.blue,
        },
        filledWarning: {
          backgroundColor: "#ed8f02",
        },
        filledSuccess: {
          backgroundColor: generalColors.green,
        },
        filledError: {
          backgroundColor: generalColors.red,
        }
      },
      MuiAutocomplete: {
        paper: {
          background: theme === "dark" ? themeColors.secondaryBackgroundColorDark : themeColors.mainBackgroundColorLight,
          color: "var(--mainText)"
        },
        option: {
          "&:hover": {
            backgroundColor: hexToRgb(color, theme === "dark" ? HOVER_OPACITY : HOVER_OPACITY_LIGHT),
          },
          '&[data-focus="true"]': {
            backgroundColor: hexToRgb(color, theme === "dark" ? HOVER_OPACITY : HOVER_OPACITY_LIGHT),
          }
        },
        input: {
          '&::placeholder': {
            opacity: theme === "dark" ? 0.7 : 0.9
          }
        }
      },
      MuiListItem: {
        root: {
          "&$selected": { backgroundColor: color },
        }
      },
      MuiStepper: {
        root: {
          background: 'transparent',
        }
      },
      MuiStepLabel: {
        label: {
          color: "var(--mainText)",
        },
        completed: {
          color: theme === "dark" ? "white !important" : "black !important",
        },
        active: {
          color: theme === "dark" ? "white !important" : "black !important",
        },
      }
    },
  });
};