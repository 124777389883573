
import { useEffect, useState } from "react";

const useGetScreenHeight = () => {

  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setHeight(window.innerHeight);
    }

    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    }
  }, [])

  return height;
}

export default useGetScreenHeight;