//https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#---update----version-31-universal---
/* eslint-disable no-mixed-operators */
export const shadeHexColor = (color, percent) => {
  let f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
};

export const hexToRgb = (hex, porcent) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return null;
  return "rgba(" + parseInt(result[1], 16) + ", " + parseInt(result[2], 16) + ", " + parseInt(result[3], 16) + ", " + porcent + ")";
};

export const getNineBoxColorByCoordinates = (coordinates) => {
  switch (coordinates) {
    case '1,1':
      return "var(--nineBoxWorst)";
    case '1,2':
    case '2,1':
      return "var(--nineBoxBad)";
    case '1,3':
    case '2,2':
    case '3,1':
      return "var(--nineBoxMid)";
    case '2,3':
    case '3,2':
      return "var(--nineBoxGood)";
    case '3,3':
      return "var(--nineBoxGreat)";
    default:
      return "transparent";
  }
}

export const hexToRgbArray = (array, porcent) => {
  let res = array.map((hex) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) return null;
    return "rgba(" + parseInt(result[1], 16) + ", " + parseInt(result[2], 16) + ", " + parseInt(result[3], 16) + ", " + porcent + ")";
  });
  return res;
};

export const getCategoryColor = (categoria, theme, isIcon) => {
  const colors = isIcon ? annotationCategoryIconColors : annotationCategoryTextColors;
  switch (categoria) {
    case "V": return colors[2];
    case "A": return colors[1];
    case "R": return colors[0];
    default: return theme === "dark" ? colors[3] : colors[4];
  }
};

export const getAnnotationTypeColor = (tipo, theme) => {
  switch (tipo) {
    case "ME": return annotationCategoryTextColors[2];
    case "DE": return annotationCategoryTextColors[0];
    default: return theme === "dark" ? annotationCategoryTextColors[3] : annotationCategoryTextColors[4];
  }
};

//COLORES
export const generalColors = {
  red: "#e52521",
  bordeaux: "#bd1a16",
  yellow: "#fba71b",
  green: "#57b757",
  blue: "#41a9c9",
  navyBlue: "#000080",
  black: "#000",
  white: "#FFF",
}

export const themeColors = {
  mainBackgroundColorDark: "#333",
  mainBackgroundColorLight: generalColors.white,
  secondaryBackgroundColorDark: "#484848",
  secondaryBackgroundColorLight: "#f5f5f5",
  borderColorDark: "#484848",
  borderColorLight: "#cecece",
  borderTransparentDark: "rgba(255, 255, 255, 0.23)",
  borderTransparentLight: "rgba(0, 0, 0, 0.23)",
};

export const borneo = {
  fills: [generalColors.red, generalColors.yellow, generalColors.green, generalColors.blue, "#4258c9", "#9a42c8", "#c84164", "#888888"],
  strokes: ["#aa4520", "#b07513", "#3d803d", "#2d768d", "#2e3e8d", "#6c2e8c", "#8c2d46", "#5f5f5f"],
};

export const randomColors = {
  fills: [
    "#5993c5",
    "#17bab6",
    "#1a4a6b",
    "#4dc7ca",
    "#00567d",
    "#64bbb8",
    "#10adff",
    "#278784",
    "#6fb8f6",
    "#0b9794",
    "#3994d3",
    "#62aba9",
    "#006190",
    "#12c7e4",
    "#26608a",
    "#5ac4d5",
    "#2c6685",
    "#46c5e2",
    "#00777c",
    "#5ebffc",
    "#02afb7",
    "#019ada",
    "#01bbd2",
    "#577fa4",
    "#01bcef",
    "#017898",
    "#76bdf3",
    "#008297",
    "#68bfee",
    "#3d839a",
    "#01aae8",
    "#68b1c2",
    "#017caa",
    "#7dbfda",
    "#0194bb",
    "#84acd4",
    "#02add8",
    "#4f97ab",
    "#4dc3ea",
    "#6da2c3",
  ],
  strokes: [
    "#5993c5",
    "#17bab6",
    "#1a4a6b",
    "#4dc7ca",
    "#00567d",
    "#64bbb8",
    "#10adff",
    "#278784",
    "#6fb8f6",
    "#0b9794",
    "#3994d3",
    "#62aba9",
    "#006190",
    "#12c7e4",
    "#26608a",
    "#5ac4d5",
    "#2c6685",
    "#46c5e2",
    "#00777c",
    "#5ebffc",
    "#02afb7",
    "#019ada",
    "#01bbd2",
    "#577fa4",
    "#01bcef",
    "#017898",
    "#76bdf3",
    "#008297",
    "#68bfee",
    "#3d839a",
    "#01aae8",
    "#68b1c2",
    "#017caa",
    "#7dbfda",
    "#0194bb",
    "#84acd4",
    "#02add8",
    "#4f97ab",
    "#4dc3ea",
    "#6da2c3",
  ],
}

export const material = {
  fills: [
    generalColors.red,
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
  ],
  strokes: [
    "#ab2f26",
    "#a31545",
    "#6d1b7b",
    "#482980",
    "#2c397f",
    "#1769aa",
    "#0276ab",
    "#008494",
    "#00695f",
    "#357a38",
    "#618834",
    "#909a28",
    "#b3a429",
    "#b38705",
    "#b36a00",
    "#b33d18",
  ],
};

export const annexGraph = {
  fills: [generalColors.green, generalColors.red, generalColors.yellow],
  strokes: ["#3d803d", "#ab2f26", "#ba7d16"],
};

export const speedometerColors = [generalColors.green, generalColors.yellow, generalColors.red];

export let categoria = {
  fills: [generalColors.red, generalColors.yellow, generalColors.green, generalColors.blue, "#ebebeb"],
  strokes: ["#ab2f26", "#b07513", "#3d803d", "#3366cc", "#9c9a9a"],
};

export const meritoDemerito = {
  fills: [generalColors.green, generalColors.red, "#ebebeb"],
  strokes: ["#3d803d", "#ab2f26", "#9c9a9a"],
};

export const annotationCategoryIconColors = [
  generalColors.red,
  generalColors.yellow,
  generalColors.green,
  "#cfcfcf", //blanco para Light
  "#ebebeb", //blanco para Dark
];

export const annotationCategoryTextColors = [
  generalColors.red,
  generalColors.yellow,
  generalColors.green,
  generalColors.white,
  generalColors.black,
];

export const googleChartColors = [
  "#3366CC",
  "#DC3912",
  "#FF9900",
  "#109618",
  "#990099",
  "#0099C6",
  "#DD4477",
  "#66AA00",
  "#B82E2E",
  "#316395",
  "#994499",
  "#22AA99",
  "#AAAA11",
  "#6633CC",
  "#E67300",
  "#8B0707",
  "#651067",
  "#329262",
  "#5574A6",
  "#3B3EAC",
  "#B77322",
  "#16D620",
  "#B91383",
  "#F4359E",
  "#9C5935",
  "#A9C413",
  "#2A778D",
  "#668D1C",
  "#BEA413",
  "#0C5922",
  "#743411",
];

export const dashboardChartColors = [
  "#009990",
  "#00996B",
  "#00993D",
  "#719900",
  "#F5A506",
  "#F57906",
  "#E94F35",
  "#FF4040",
  "#FF0000",
  "#FF005C",
  "#FF1A88",
  "#EE17B2",
  "#D91EDD",
  "#9E36DD",
  "#6B36DD",
  "#4336DD",
  "#1A40C5",
  "#0041A1",
]

export const pollReviewColors = [
  "#003740",
  "#00505E",
  "#00596B",
  "#006273",
  "#008399",
  "#009990",
  "#00996B",
  "#00993D",
  "#719900",
  "#F5A506",
  "#F57906",
  "#E94F35",
  "#FF4040",
  "#FF0000",
  "#FF005C",
  "#FF1A88",
  "#EE17B2",
  "#D91EDD",
  "#9E36DD",
  "#6B36DD",
  "#4336DD",
  "#1A40C5",
  "#0041A1",
  "#023E75",
  "#022975",
  "#040275",
  "#3C0275",
  "#570275",
  "#750263",
  "#750247",
]

export const pollReviewTop5Colors = [
  "#003740",
  "#00596B",
  "#FF0000",
  "#F5A506",
  "#E94F35",
]

export const absenceTypeColors = {
  vacations: generalColors.green,
  permission: generalColors.blue,
  absence: generalColors.red,
  licence: generalColors.yellow,
}

export const calendarTypeColors = {
  holiday: generalColors.red,
  free: generalColors.green,
  scheduled: generalColors.blue,
  absence: generalColors.yellow,
  exception: generalColors.navyBlue,
  extraUnauthorized: '#B695C0',
  extraAuthorized: '#7d4a8c',
  request: '#836060',
  requestAccepted: 'black',
  done: generalColors.green,
  pending: generalColors.blue,
}

export const calendarActionsTypeColors = {
  done: generalColors.green,
  pending: generalColors.blue,
}

export const getProgressColor = (percentage) => {
  if (percentage < 41) return 'var(--progressLow)';
  if (percentage < 71) return 'var(--progressMid)';
  return 'var(--progressHigh)';
}

export const getNPSColor = (value) => {
  if (!value) return 'var(--mainText)';
  if (value > 8) return generalColors.green;
  if (value > 6) return generalColors.yellow;
  return generalColors.red;
}