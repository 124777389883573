import { useEffect, useState } from "react";

const useForceRender = (dep) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        setCount(prev => prev + 1);
    }, [dep])

    return count;
}

export default useForceRender;