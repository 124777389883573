import { useState } from "react";

const useHandleStepper = (steps, callback) => {

  const [currentStep, setCurrentStep] = useState(0);

  const renderStep = () => steps[currentStep].render();

  const setNextStep = () => {
    if (currentStep === steps.length - 1) return;
    setCurrentStep((prev) => prev + 1);
    callback && callback();
  }

  const setPreviousStep = () => {
    if (currentStep === 0) return;
    setCurrentStep((prev) => prev - 1);
    callback && callback();
  }

  return {
    currentStep,
    setCurrentStep,
    setNextStep,
    setPreviousStep,
    renderStep,
  }
}

export default useHandleStepper;
