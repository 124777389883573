import React from 'react';
import { Typography } from '@material-ui/core';

const FeedbackStatus = ({ value, data }) => {

    return (
        Boolean(value) ?
            <Typography
                noWrap
                style={{
                    fontSize: 12,
                    marginTop: 5,
                    fontWeight: data.requiredAction ? 600 : 400,
                    color: data.requiredAction ? 'var(--main-bg-color)' : 'var(--mainText)',
                }}
            >
                {value}
            </Typography>
            : <div />
    );
}

export default FeedbackStatus;