import React from 'react';
import { getProgressColor } from '@icarius-utils/colors';

class ProgressRenderer extends React.Component {

    getReactContainerClasses() {
        return ['div-outer-div'];
    }

    render() {
        const { value } = this.props;
        const percentage = value * 100;
        const formatted = parseFloat(percentage).toFixed(0);

        if (!(value || parseInt(percentage) >= 0)) return <div />

        return (
            <div className="div-percent-bar" style={{ width: percentage + '%', backgroundColor: getProgressColor(percentage) }}>
                <div className="div-percent-value">{`${formatted}%`}</div>
            </div>
        );
    }
}

export default ProgressRenderer;