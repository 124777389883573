import { getLocalizedString } from "@icarius-localization/strings";
import {
  currencyStringToNumber,
  getLocale,
} from "./utils";

// comparators
export const numberComparatorCustom = ({ number1, number2 }, decimals) => {
  let numberString1 = currencyStringToNumber(number1, decimals);
  let numberString2 = currencyStringToNumber(number2, decimals);

  if (numberString1 === null && numberString2 === null) return 0;
  if (numberString1 === null) return -1;
  if (numberString2 === null) return 1;
  return numberString1 - numberString2;
};

export const numberComparatorMax2 = (number1, number2) => {
  let numberString1 = currencyStringToNumber(number1);
  let numberString2 = currencyStringToNumber(number2);

  if (numberString1 === null && numberString2 === null) return 0;
  if (numberString1 === null) return -1;
  if (numberString2 === null) return 1;
  return numberString1 - numberString2;
};

export const numberComparatorMax3 = (number1, number2) => {
  let numberString1 = currencyStringToNumber(number1, 3);
  let numberString2 = currencyStringToNumber(number2, 3);

  if (numberString1 === null && numberString2 === null) return 0;
  if (numberString1 === null) return -1;
  if (numberString2 === null) return 1;
  return numberString1 - numberString2;
};

export const numberComparatorMax4 = (number1, number2) => {
  let numberString1 = currencyStringToNumber(number1, 4);
  let numberString2 = currencyStringToNumber(number2, 4);

  if (numberString1 === null && numberString2 === null) return 0;
  if (numberString1 === null) return -1;
  if (numberString2 === null) return 1;
  return numberString1 - numberString2;
};

export const numberComparatorMax5 = (number1, number2) => {
  let numberString1 = currencyStringToNumber(number1, 5);
  let numberString2 = currencyStringToNumber(number2, 5);

  if (numberString1 === null && numberString2 === null) return 0;
  if (numberString1 === null) return -1;
  if (numberString2 === null) return 1;
  return numberString1 - numberString2;
};

// formatters
export const formatNumberWithAggridLocale = (value, minDecimal, maxDecimal) => {
  return new Intl.NumberFormat(getLocale(), {
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: maxDecimal,
  }).format(value);
}

const formatNumberOrReturnUndefined = (value, minDecimal, maxDecimal) => {
  if (typeof value === "undefined") return;
  if (value === "-") return;
  if (value === null) return;

  return formatNumberWithAggridLocale(value, minDecimal, maxDecimal);
}

export const numberFormatterDecimalsCustom = ({ value }, decimals) => formatNumberOrReturnUndefined(value, decimals);
export const numberFormatterMin2 = ({ value }) => formatNumberOrReturnUndefined(value, 2);
export const numberFormatterMin5 = ({ value }) => formatNumberOrReturnUndefined(value, 5);
export const numberFormatterMin0Max0 = ({ value }) => formatNumberOrReturnUndefined(value, 0, 0);
export const numberFormatterMin0Max1 = ({ value }) => formatNumberOrReturnUndefined(value, 0, 1);
export const numberFormatterMin0Max2 = ({ value }) => formatNumberOrReturnUndefined(value, 0, 2);
export const numberFormatterMin0Max3 = ({ value }) => formatNumberOrReturnUndefined(value, 0, 3);
export const numberFormatterMin1Max1 = ({ value }) => formatNumberOrReturnUndefined(value, 1, 1);
export const numberFormatterMin2Max2 = ({ value }) => formatNumberOrReturnUndefined(value, 2, 2);
export const numberFormatterMin3Max3 = ({ value }) => formatNumberOrReturnUndefined(value, 3, 3);
export const numberFormatterMin4Max4 = ({ value }) => formatNumberOrReturnUndefined(value, 4, 4);
export const numberFormatterMin5Max5 = ({ value }) => formatNumberOrReturnUndefined(value, 5, 5);
export const numberFormatterMin0Max3AllowsEmpty = ({ value }) => {
  // devuelve string vacio o numero con maximo 3 decimales
  // si llega string vacio, no lo formatea a 0
  if (typeof value === "undefined") return "";
  if (value === "-") return "";
  if (value === null) return "";
  if (value === "") return "";

  return formatNumberWithAggridLocale(value, 0, 3);
};

// filterParams
export const numberFilterParams = {
  filterOptions: [
    {
      displayKey: "iguales",
      displayName: getLocalizedString("equal"),
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue);
        let filterValueNumber = currencyStringToNumber(filterValue);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber === filterValueNumber;
        }
        return false;
      },
    },
    {
      displayKey: "desiguales",
      displayName: getLocalizedString("notEqual"),
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue);
        let filterValueNumber = currencyStringToNumber(filterValue);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber !== filterValueNumber;
        }
        return false;
      },
    },
    {
      displayKey: "mayorQ",
      displayName: "Mayor",
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue);
        let filterValueNumber = currencyStringToNumber(filterValue);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber > filterValueNumber;
        }
        return false;
      },
    },
    {
      displayKey: "menorQ",
      displayName: "Menor",
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue);
        let filterValueNumber = currencyStringToNumber(filterValue);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber < filterValueNumber;
        }
        return false;
      },
    },
    {
      displayKey: "mayorQue",
      displayName: getLocalizedString("moreOrEqualThan"),
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue);
        let filterValueNumber = currencyStringToNumber(filterValue);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber >= filterValueNumber;
        }
        return false;
      },
    },
    {
      displayKey: "menorQue",
      displayName: getLocalizedString("lessOrEqualThan"),
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue);
        let filterValueNumber = currencyStringToNumber(filterValue);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber <= filterValueNumber;
        }
        return false;
      },
    },
  ],
};

export const numberFilterParamsCustom = (decimals) => {
  return {
    filterOptions: [
      {
        displayKey: "iguales",
        displayName: getLocalizedString("equal"),
        test: function (filterValue, cellValue) {
          let cellValueNumber = currencyStringToNumber(cellValue, decimals);
          let filterValueNumber = currencyStringToNumber(filterValue, decimals);

          if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
            return cellValueNumber === filterValueNumber;
          }
          return false;
        },
      },
      {
        displayKey: "desiguales",
        displayName: getLocalizedString("notEqual"),
        test: function (filterValue, cellValue) {
          let cellValueNumber = currencyStringToNumber(cellValue, decimals);
          let filterValueNumber = currencyStringToNumber(filterValue, decimals);

          if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
            return cellValueNumber !== filterValueNumber;
          }
          return false;
        },
      },
      {
        displayKey: "mayorQ",
        displayName: "Mayor",
        test: function (filterValue, cellValue) {
          let cellValueNumber = currencyStringToNumber(cellValue, decimals);
          let filterValueNumber = currencyStringToNumber(filterValue, decimals);

          if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
            return cellValueNumber > filterValueNumber;
          }
          return false;
        },
      },
      {
        displayKey: "menorQ",
        displayName: "Menor",
        test: function (filterValue, cellValue) {
          let cellValueNumber = currencyStringToNumber(cellValue, decimals);
          let filterValueNumber = currencyStringToNumber(filterValue, decimals);

          if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
            return cellValueNumber < filterValueNumber;
          }
          return false;
        },
      },
      {
        displayKey: "mayorQue",
        displayName: getLocalizedString("moreOrEqualThan"),
        test: function (filterValue, cellValue) {
          let cellValueNumber = currencyStringToNumber(cellValue, decimals);
          let filterValueNumber = currencyStringToNumber(filterValue, decimals);

          if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
            return cellValueNumber >= filterValueNumber;
          }
          return false;
        },
      },
      {
        displayKey: "menorQue",
        displayName: getLocalizedString("lessOrEqualThan"),
        test: function (filterValue, cellValue) {
          let cellValueNumber = currencyStringToNumber(cellValue, decimals);
          let filterValueNumber = currencyStringToNumber(filterValue, decimals);

          if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
            return cellValueNumber <= filterValueNumber;
          }
          return false;
        },
      },
    ],
  }
};

export const numberFilterParamsWithThreeDigits = {
  filterOptions: [
    {
      displayKey: "iguales",
      displayName: getLocalizedString("equal"),
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue, 3);
        let filterValueNumber = currencyStringToNumber(filterValue, 3);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber === filterValueNumber;
        }
        return false;
      },
    },
    {
      displayKey: "desiguales",
      displayName: getLocalizedString("notEqual"),
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue, 3);
        let filterValueNumber = currencyStringToNumber(filterValue, 3);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber !== filterValueNumber;
        }
        return false;
      },
    },
    {
      displayKey: "mayorQ",
      displayName: "Mayor",
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue, 3);
        let filterValueNumber = currencyStringToNumber(filterValue, 3);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber > filterValueNumber;
        }
        return false;
      },
    },
    {
      displayKey: "menorQ",
      displayName: "Menor",
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue, 3);
        let filterValueNumber = currencyStringToNumber(filterValue, 3);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber < filterValueNumber;
        }
        return false;
      },
    },
    {
      displayKey: "mayorQue",
      displayName: getLocalizedString("moreOrEqualThan"),
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue, 3);
        let filterValueNumber = currencyStringToNumber(filterValue, 3);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber >= filterValueNumber;
        }
        return false;
      },
    },
    {
      displayKey: "menorQue",
      displayName: getLocalizedString("lessOrEqualThan"),
      test: function (filterValue, cellValue) {
        let cellValueNumber = currencyStringToNumber(cellValue, 3);
        let filterValueNumber = currencyStringToNumber(filterValue, 3);

        if (cellValueNumber !== null && cellValueNumber !== undefined && filterValueNumber !== null && filterValueNumber !== undefined) {
          return cellValueNumber <= filterValueNumber;
        }
        return false;
      },
    },
  ],
};