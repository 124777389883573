import React from 'react';
import { categoria } from "@icarius-utils/colors";

export default class CategoryRenderer extends React.Component {

    getReactContainerClasses() {
        return ['div-outer-div'];
    }

    render() {
        let backgroundColor;

        const category = this.props.value;

        if (category === "Verde") {
            backgroundColor = categoria.fills[2];
        } else if (category === "Roja") {
            backgroundColor = categoria.fills[0];
        } else if (category === "Amarilla") {
            backgroundColor = categoria.fills[1];
        } else {
            backgroundColor = "";
        }

        return (
            <div
                className="div-percent-bar"
                style={{
                    width: "100%",
                    backgroundColor: backgroundColor,
                    borderRadius: 5,
                }}
            >
                <div className="div-percent-value">
                    {this.props.value}
                </div>
            </div>
        );
    }
}
