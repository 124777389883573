import React from 'react';
import { getLocalizedString } from "@icarius-localization/strings";

const T_SETS = [
    "taskNoFilter",
    "taskFinished",
    "taskEndsToday",
    "taskInTime",
    "taskDelayed"
];

export default class NoteItemStatusFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: T_SETS[0]
        };

    }

    // called by agGrid
    doesFilterPass(params) {
        const value = this.props.valueGetter(params);

        switch (this.state.selected) {
            case T_SETS[1]:
                return value.includes(getLocalizedString(T_SETS[1]));
            case T_SETS[2]:
                return value.includes(getLocalizedString(T_SETS[2]));
            case T_SETS[3]:
                return value.includes(getLocalizedString("taskDaysRemaining"));
            case T_SETS[4]:
                return value.includes(getLocalizedString("taskDelay"));
            default:
                return true;
        }
    }

    // called by agGrid
    isFilterActive() {
        return this.state.selected !== T_SETS[0];
    }

    onButtonPressed(name) {
        const newState = { selected: name };
        this.setState(newState, this.props.filterChangedCallback);
    }

    render() {
        return (
            <div>
            {
                T_SETS.map((name) => (
                    <div key={name} style={{ marginTop: 3 }}>
                        <label style={{ paddingLeft: 4, display: "flex", alignItems: "center" }}>
                            <input
                                type="radio"
                                checked={this.state.selected === name}
                                name={Math.random()}
                                onChange={this.onButtonPressed.bind(this, name)}
                            />
                            <span style={{ paddingLeft: 4 }}>
                                {getLocalizedString(name)}
                            </span>
                        </label>
                    </div>
                ))
            }
            </div>
        );
    }
}
