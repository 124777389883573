const objectElementToXml = (obj) => {
  let xml = "";

  for (let prop in obj) {
    if (!obj.hasOwnProperty(prop)) {
      continue;
    }

    if (obj[prop] === undefined) continue;

    xml += "<" + prop.replaceAll(' ', "_") + ">";
    if (typeof obj[prop] === "object") xml += objectToXml({ ...obj[prop] });
    else xml += obj[prop];

    xml += "</" + prop.replaceAll(' ', "_") + ">";
  }
  return xml;
}

export const objectToXml = (obj) => {
  let pre = '<?xml version="1.0" encoding="UTF-8"?><root>';
  let fin = "</root>";
  let xml = "";

  for (let prop in obj) {
    if (!obj.hasOwnProperty(prop)) {
      continue;
    }

    if (obj[prop] === undefined) continue;

    xml += "<element>";
    if (typeof obj[prop] === "object") xml += objectElementToXml({ ...obj[prop] });
    else xml += obj[prop];

    xml += "</element>";
  }
  return pre + xml + fin;
};

export const splitIntoLines = (input, len) => {
  let i;
  let output = [];
  let lineSoFar = "";
  let temp;
  let words = input.split(" ");
  for (i = 0; i < words.length;) {
    // check if adding this word would exceed the len
    temp = addWordOntoLine(lineSoFar, words[i]);
    if (temp.length > len) {
      if (lineSoFar.length === 0) {
        lineSoFar = temp; // force to put at least one word in each line
        i++; // skip past this word now
      }
      output.push(lineSoFar); // put line into output
      lineSoFar = ""; // init back to empty
    } else {
      lineSoFar = temp; // take the new word
      i++; // skip past this word now
    }
  }
  if (lineSoFar.length > 0) {
    output.push(lineSoFar);
  }
  return output;
};

const addWordOntoLine = (line, word) => {
  if (line.length !== 0) {
    line += " ";
  }
  return (line + word);
};

export const zeroPad = (num, places) => {
  let zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
};

export const formatNumberExactDecimals = (number, decimals) => {
  // si no recibe decimals, formatea el punto y la coma pero mantiene los decimales originales
  if (typeof number !== 'number') return number;
  return new Intl.NumberFormat("da", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export const formatNumberOrReturnUndefined = (value, minDecimal, maxDecimal) => {
  // si no recibe decimals, formatea el punto y la coma pero mantiene los decimales originales
  if (typeof value === "undefined") return;
  return new Intl.NumberFormat("da", {
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: maxDecimal,
  }).format(value);
}

export const formatDraftToText = (draft) => {
  return draft?.blocks?.map((item) => item.text).join('\n') || '';
}

export const formatTextToDraft = (text) => {
  return ({
    blocks: text.split('\n').map((item, index) => ({ key: index, entityRanges: [], text: item })),
    entityMap: [],
  })
}

export const formatFirstLetterUppercase = (str) => str.charAt(0).toUpperCase() + str.slice(1);