
import { useState, useEffect } from "react";

const useGetRefWidthAndHeight = (ref) => {

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleWindowResize = () => {
      if (ref?.current) {
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetHeight);
      }
    }

    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [ref]);

  return {
    width,
    height,
  };
}

export default useGetRefWidthAndHeight;