import React from 'react';
import { previewFromURL } from '@icarius-utils/download';
import { Typography } from '@material-ui/core';

const CoordinatesRenderer = ({ value }) => {

    return (
        Boolean(value) ?
            <Typography
                className='whiteText'
                noWrap
                style={{ textDecoration: 'underline', fontSize: 12, marginTop: 5, cursor: 'pointer'}}
                onClick={() => previewFromURL(`https://maps.google.com/?q=${value}`)}
            >
                {value}
            </Typography>
            : <div />
    );
}

export default CoordinatesRenderer;