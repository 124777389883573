import React from 'react';
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { previewFromURL } from "@icarius-utils/download";
import ProfileImg from '@icarius-common/profileImg';

const AvatarRenderer = ({ value, column }) => {

    const handleOnClick = () => {
        if (!value) return;
        previewFromURL(IMAGES_ENDPOINT + value);
    }

    return (
        <div
            style={{ display: 'flex', justifyContent: 'center', cursor: value ? 'pointer' : '' }}
            onClick={handleOnClick}
        >
            <ProfileImg
                image={value}
                size={24}
            />
        </div>
    );
}

export default AvatarRenderer;