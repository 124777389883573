export const excelStyles = (customColor, dateFormat = "dd/mm/yyyy") => [
  {
    id: "assetsDiscountsSummaryStyle1",
    interior: {
      color: "#CCCCCC",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle2",
    interior: {
      color: "#FFFF00",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle3",
    interior: {
      color: "#FFC000",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle4",
    interior: {
      color: "#FFE699",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle5",
    interior: {
      color: "#DDEBF7",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle6",
    interior: {
      color: "#9BC2E6",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle7",
    interior: {
      color: "#C6E0B4",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle8",
    interior: {
      color: "#A9D08E",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle9",
    interior: {
      color: "#BFBFBF",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle10",
    interior: {
      color: "#5B9BD5",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle11",
    interior: {
      color: "#AEAAAA",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle12",
    interior: {
      color: "#B864DE",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle13",
    interior: {
      color: "#1BE55E",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "assetsDiscountsSummaryStyle14",
    interior: {
      color: "#FFFF00",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "isFecha",
    dataType: "dateTime",
    numberFormat: {
      format: dateFormat + ";@",
    },
  },
  {
    id: "stringType",
    dataType: "string",
  },
  {
    id: "currencyValueNoDecimal",
    dataType: "number",
    numberFormat: { format: "0" },
  },
  {
    id: "currencyValue",
    dataType: "number",
    numberFormat: { format: "#,##0.00;[Red]-#,##0.00" },
  },
  {
    id: "percentageValue",
    dataType: "number",
    numberFormat: { format: "0%" },
  },
  {
    id: "greenBackground",
    interior: {
      color: "#b5e6b5",
      pattern: "Solid",
    },
  },
  {
    id: "redFont",
    font: {
      fontName: "Calibri Light",
      underline: "Single",
      italic: true,
      color: "#ff0000",
    },
  },
  {
    id: 'timeType',
    dataType: 'dateTime',
    numberFormat: {
      format: '[hh]:mm:ss'
    }
  },
  {
    id: "darkGreyBackground",
    interior: {
      color: "#888888",
      pattern: "Solid",
    },
    font: {
      fontName: "Calibri Light",
      color: "#ffffff",
    },
  },
  {
    id: "boldBorders",
    borders: {
      borderBottom: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 3,
      },
      borderLeft: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 3,
      },
      borderRight: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 3,
      },
      borderTop: {
        color: "#000000",
        lineStyle: "Continuous",
        weight: 3,
      },
    },
  },
  {
    id: "header",
    interior: {
      color: "#CCCCCC",
      pattern: "Solid",
    },
    borders: {
      borderBottom: {
        color: "#5687f5",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderLeft: {
        color: "#5687f5",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: "#5687f5",
        lineStyle: "Continuous",
        weight: 1,
      },
      borderTop: {
        color: "#5687f5",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
  {
    id: "bigHeader",
    font: { size: 25, color: customColor },
    alignment: {
      horizontal: "Center",
      vertical: "Center",
    },
    interior: {
      color: "#333333",
      pattern: "Solid",
    },
  },
];